
// import { FETCH_EntityUsers, RECEIVE_EntityUsers ,SETFIREWORKS} from '../actions/dashboardAction';
// import { action as actions } from 'aws-cognito-redux-saga';
import _ from 'lodash';
const initialState = {
    "isSignedIn": JSON.parse(localStorage.getItem('LOGIN_SUCCESS'))||"AUTH_UNKNOWN",
    "info":JSON.parse(localStorage.getItem('user_info'))||{ },
    "error": {},
    "isConfirmed": "AUTH_UNKNOWN",
    "hasSignedUp": "AUTH_UNKNOWN",
    "hasSentCode": "AUTH_UNKNOWN",
    "hasChangedPassword": "AUTH_UNKNOWN",
    "passwordResetRequired": "AUTH_UNKNOWN"
    };
export default function(state = initialState, action) {
    // console.log("auth action.type======="+action.payload);
    
      switch (action.type) {
         
        case 'UPDATE_USER':
          return {
            ...state,
            info: action.payload,
            isSignedIn: "AUTH_SUCCESS",
          };
          case 'UPDATE_ERROR':
          return {
            ...state,
            error: {
                message:action.payload
            }
          };
          case 'SING_OUT_USER':
            return {
              ...state,
              info:{},
              isSignedIn: "AUTH_UNKNOWN",
              hasChangedPassword:"AUTH_UNKNOWN"
            }
            case 'FORGETPASSWORREQUEST':
            return {
              ...state,
              info:{},
              hasChangedPassword:true,
            }
        default:
          return state;
      }
    }