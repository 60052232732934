import { action as actions } from 'aws-cognito-redux-saga';
import {RECEIVE_PICKLIST} from '../actions/getPickListAction';

const initialState ={
    getPickListDetailsObj : {}
}

const getPickListReducer=(state = initialState, action)=> {
    switch (action.type) {
    case RECEIVE_PICKLIST:
        return {
        ...state,
        getPickListDetailsObj: action.payLoad
        };

    default:
        return state;
    }
}

export default getPickListReducer;