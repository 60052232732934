import { action as actions } from 'aws-cognito-redux-saga';
import {RECEIVE_MEETING_LIST,RESPONSE_UPDATE_FEEDBACK_ACTION,RESPONSE_GET_FEEDBACK_ACTION} from '../actions/getMeetingListAction'
const initialState ={
    getMeetingListDetailsObj : {},
    responseUpdateFeedbackObj:{},
    responseGetFeedbackObj:{}
}

const getMeetingListReducer=(state = initialState, action)=> {
    switch (action.type) {
    case RECEIVE_MEETING_LIST:
        return {
        ...state,
        getMeetingListDetailsObj: action.payLoad
        };
        break;
    case RESPONSE_UPDATE_FEEDBACK_ACTION:
        return {
            ...state,
            responseUpdateFeedbackObj: action.payLoad
            };
        break;
    case RESPONSE_GET_FEEDBACK_ACTION:
    //console.log("inside reducer RESPONSE_GET_FEEDBACK_ACTION");
    return {
        ...state,
        responseGetFeedbackObj: action.payLoad
        };
        break;
    default:
        return state;
    }
}

export default getMeetingListReducer;