import axios from "axios";


export const getUpdateGrowthPlanApi = async (obj) => {
    //console.log("inside getCategoryApi api call");
    try {
        // Get the authentication token
     
    // headers: {"Authorization" : `Bearer ${tokenStr}`}
        const response =  await axios({
                    method: 'POST',
                    url: process.env.REACT_APP_API_END_POINT+'/updateGrowthplanSummary',
                    data:obj.inputParams
                    })
                    .then(Response => Response)
                    .then(res => {
                     // console.log("inside getCategoryAPI");
                    //  console.log(res.data);
                       return res.data;
                    })
        return response;           
    } catch (e) {
        console.log(e);
    }
  };