import React, { Component } from "react";
import PropTypes from "prop-types";
import { Field, reduxForm } from "redux-form";

// import { TextField } from 'redux-form-material-ui'

// import { RaisedButton, Paper } from 'material-ui'
import { TextField, Button } from "@mui/material";

import { state } from "aws-cognito-redux-saga";

import validator from "validator";
import axios from "axios";

const required = (value) => (value ? undefined : "Required");
const passwordMatch = (value, values) =>
  values.password !== values.passwordMatch && "Passwords must match";
const minLength = (value) => (value.length >= 8 ? undefined : "Min Length 8");
const email = (value) =>
  validator.isEmail(value) ? undefined : "Not Valid Email";

const style = {
  paper: { padding: "16px" },
  layout: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "calc(100vh - 56px)",
  },
  title: {
    fontSize: "32px",
    textAlign: "center",
  },
  form: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  button: {
    margin: "16px",
    width: "80%",
  },
  field: {
    margin: "8px 32px",
  },
  error: {
    width: "80%",
    color: "rgb(200,0,0)",
    margin: "8px",
    height: "32px",
  },
};

class ForceChangePassword extends Component {
  static propTypes = {
    history: PropTypes.object,
    handleSubmit: PropTypes.func.isRequired,
    completeNewPassword: PropTypes.func.isRequired,
    auth: PropTypes.object,
    init: PropTypes.func,
  };

  componentWillMount() {
    this.props.init();
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.auth.hasChangedPassword == state.AUTH_SUCCESS) {
      this.updateCognitoStatusInDB();
      this.updateEntityActivity();
    }
  }
  updateEntityActivity = async () => {
    var email = sessionStorage.getItem("ResetPasswordUserName");
    var inputobj = {
      auditActivityType: "UA",
      logType: "I",
      activity: "CHANGE PASSWORD",
      entityId: null,
      growthPlanId: null,
      goalTagId: null,
      actionTagId: null,
      meetingId: null,
      page: "",
      auditText: "",
      auditValue: null,
      auditMessage: "User has changed password via temporary password ",
      auditLink: "",
      email: email,
    };
    await axios({
      method: "POST",
      url: process.env.REACT_APP_API_END_POINT + "/updateEntityActivity",
      data: inputobj,
    })
      .then((Response) => Response)
      .then((res) => {
        return res;
      })
      .catch(function (error) {});
  };
  updateCognitoStatusInDB = async () => {
    var email = sessionStorage.getItem("ResetPasswordUserName");
    await axios({
      method: "post",
      url: process.env.REACT_APP_API_END_POINT + "/updateCognitoStatuByEmail",
      data: {
        email: email,
      },
    })
      .then((Response) => Response)
      .then((res) => {})
      .catch(function (error) {});
  };

  changePassword = (values) => {
    var email = sessionStorage.getItem("ResetPasswordUserName");
    this.props.completeNewPassword(email.toLowerCase(), values.password);
  };

  signIn = () => {
    this.props.history.push("/login");
  };

  renderChangePassword() {
    const { handleSubmit, auth } = this.props;
    return (
      <div className="d-flex flex-column align-items-center justify-content-center login-section">
        <div
          style={style.layout}
          class="login-container login-form-fields change-password"
        >
          {/* <Paper style={style.paper} zDepth={5}> */}
          <form style={style.form}>
            <div style={style.title}>Change Password</div>
            <p className="m-0">
              <small>
                Email : {sessionStorage.getItem("ResetPasswordUserName")}
              </small>
            </p>
            {/* <Field
                style={style.button}
                name="email"
                validate={[required, email]}
                component={TextField}
                type="email"
                floatingLabelText="Email"
              /> */}

            <Field
              style={style.field}
              name="password"
              validate={[required, minLength]}
              component={TextField}
              type="password"
              floatingLabelText="Password"
              className="form-control"
            />

            <Field
              style={style.field}
              name="passwordMatch"
              validate={[required, passwordMatch, minLength]}
              component={TextField}
              type="password"
              floatingLabelText="Confirm Password"
              className="form-control"
            />

            <div style={style.error}>{auth.error.message}</div>

            <Button
              variant="contained"
              //style={style.button}
              onClick={handleSubmit(this.changePassword)}
              primary
            >
              Change Password
            </Button>
          </form>
          {/* </Paper> */}
        </div>
      </div>
    );
  }

  renderSignIn() {
    const { handleSubmit } = this.props;
    return (
      <div style={style.layout} className="login-section">
        {/* <Paper style={style.paper} zDepth={5}> */}
        <form style={style.form}>
          <div style={style.title}>Password Changed</div>

          <Button
            variant="contained"
            style={style.button}
            onClick={handleSubmit(this.signIn)}
            primary
          >
            Sign In
          </Button>
        </form>
        {/* </Paper> */}
      </div>
    );
  }

  render() {
    const { auth } = this.props;
    return auth.hasChangedPassword === state.AUTH_SUCCESS
      ? this.renderSignIn()
      : this.renderChangePassword();
  }
}

// Decorate the form component
export default reduxForm({
  form: "forceChangePassword",
})(ForceChangePassword);
