
import {  RECEIVE_Category_Lst ,RECEIVE_GrowthPlan,RECEIVE_UPDATE_GrowthPlan,RECEIVE_Goal_Tag_Lst,
  RECEIVE_Action_Tag_Lst,RESPONSE_UPDATE_GOAL,RESPONSE_GROWTH_PLAN_DETAILS,RESPONSE_UPDATE_ACTION,
  RESPONSE_UPDATE_GOALQUESTION_ACTION,RESPONSE_UPDATE_WeekTemplate_ACTION,RESPONSE_UPDATE_CUSTOMISEDTAG_ACTION} from '../actions/growthPlanAction';
import { action as actions } from 'aws-cognito-redux-saga';
import _ from 'lodash';
const initialState = {
      categoryobj: {},
      goalLst:{},
      actionLst:{},
      growthPlanObject:{},
      updategrowthPlanObject:{},
      updateGoalObject:{},
      updateActionObject:{},
      updateGoalQuestionObject:{},
      updateGrowthPlanWeekTemplate:{},
      updateCustomizedTagObject:{}
    };
export default function(state = initialState, action) {
    //console.log("growthPlan action.type======="+action.type);
      switch (action.type) {
        case RECEIVE_Category_Lst:
          return {
            ...state,
            categoryobj: action.payload
          };
          break;
        case RECEIVE_Action_Tag_Lst:
          //console.log("action tagList",action.payload);
          return {
            ...state,
            actionLst: action.payload
          };
          break;
        case RECEIVE_Goal_Tag_Lst:
          return {
            ...state,
            goalLst: action.payload
          };
          break;
        case RECEIVE_GrowthPlan:
        return {
            ...state,
            growthPlanObject: action.payload
            };
            break;
        case RECEIVE_UPDATE_GrowthPlan:
        return {
          ...state,
          updategrowthPlanObject: action.payload
          };
          break;
        case RESPONSE_UPDATE_GOAL:
        return {
          ...state,
          updateGoalObject: action.payload
          };
          break;
        case RESPONSE_GROWTH_PLAN_DETAILS:
        return {
          ...state,
          growthPlanObject: action.payload
          };
          break;
        case RESPONSE_UPDATE_ACTION:
        return {
          ...state,
          updateActionObject: action.payload
        };
        break;
        case RESPONSE_UPDATE_GOALQUESTION_ACTION:
        return {
          ...state,
          updateGoalQuestionObject: action.payload
        };
        break;
        case RESPONSE_UPDATE_WeekTemplate_ACTION:
        return {
          ...state,
          updateGrowthPlanWeekTemplate: action.payload
        };
        break;
        case RESPONSE_UPDATE_CUSTOMISEDTAG_ACTION:
        return {
          ...state,
          updateCustomizedTagObject: action.payload
        };
        break;
        default:
          return state;
      }
    }

















// const initialState = {
//    profileobj: {}
//   };
  
//   export default function(state = initialState, action) {
//     switch (action.type) {
//       case "GetProfileDetails":
//         return {
//           ...state,
//           profileobj: action.payload
//         };
//       default:
//         return state;
//     }
//   }