import { connect } from 'react-redux'

// import { reducer } from 'aws-cognito-redux-saga';
import { getMyProfile, signIn } from "../../../sagas/users";



import LoginComponent from './LoginComponent'
import { SignOutUser, UpdateLoginDetails, UpdateLoginerrors } from '../../../actions/auth';
import GlobalSession from '../../Common/GlobalSession';

const mapStatetoProps = state => {
  return {
    auth: state.auth
  }
}

const mapDispatchToProps = dispatch => {
  return {
    // init: () => {
    //   dispatch(reducer.init())
    // },
    signIn: (username, password, code) => {
      dispatch(signIn(username, password, code))
    },
    getMyProfile: (token) => {
      dispatch(getMyProfile(token))
    },
    UpdateLoginDetails: (data) => {
      dispatch(UpdateLoginDetails(data))
    },
    UpdateLoginerrors: (data) => {
      dispatch(UpdateLoginerrors(data))
    },
    saveProfileData: (data) => {
      dispatch({ type: 'RECEIVE_MY_PROFILE', payload: data })
    },
    signOut: () => {
      console.log('Ramesh')
      localStorage.clear();
      localStorage.removeItem('appVersion');
      sessionStorage.clear();
      window.location.href = '#'
      dispatch(SignOutUser());
      GlobalSession.entityId = null;
    }
  }
}

export default connect(mapStatetoProps, mapDispatchToProps)(LoginComponent)
