import React, { Component} from 'react';
import userImg from '../../assets/img/avatars/default-user.png'
import {getEntityPersonalAction} from '../../actions/sideBarAction';
import { bindActionCreators } from "redux";
import { connect } from 'react-redux';
import GlobalSession from "../Common/GlobalSession";
import _ from 'lodash';
import axios from 'axios';
// import LoadingDots from '../Common/LoadingDots';
class UserImage extends Component{
  state={
    imagePreviewUrl:userImg,
    loading: true,
  }

  componentWillMount=()=>{
   this.loadEntityPersonal();
  }
  loadEntityPersonal=()=>{
    var inputobj={
      "entityId":GlobalSession.entityId
  }
  this.props.getEntityPersonalAction(inputobj);
  }

  componentWillReceiveProps(nextProps) {
    var nextentityPersonalObj=nextProps.enityPersonalObj;
    var thisentityPersonalObj=this.props.enityPersonalObj;
    if (nextentityPersonalObj !== thisentityPersonalObj ) {
      if(nextentityPersonalObj!==undefined && nextentityPersonalObj.length>0 && nextentityPersonalObj[0].imageUri!==null &&nextentityPersonalObj[0].imageUri!=="" ){
        var randomnumber=Math.floor(Math.random() * 100);
            this.setState({ imagePreviewUrl: nextentityPersonalObj[0].imageUri+"?v="+randomnumber , loading: false });
      }else{
            this.setState({loading: false });
      }
    }
 }

    // userHandler = async() =>{

    //   if(localStorage.getItem('tokenId') !== JSON.parse(localStorage.getItem('user_info')).tokenId){
    //     var randomnumber = Math.floor(Math.random() * 100);
    //   var self = this;
    //   await axios({
    //     method: 'GET',
    //     //url: process.env.REACT_APP_API_END_POINT + '/getEntityByEmail/' + localStorage.getItem("loginUserEmail")+"?v="+randomnumber
    //     url: process.env.REACT_APP_API_END_POINT + '/getEntityByToken/' +JSON.parse(localStorage.getItem('user_info')).tokenId + "?v=" + randomnumber
    //   })
    //     .then(Response => Response)
    //     .then(res => {
    //       // console.log("inside");
    //       console.log(res.data);
    //       if (sessionStorage.getItem("USER_ENTITY_ID") != null) {
    //         sessionStorage.removeItem("USER_ENTITY_ID");
    //       }
    //       if (localStorage.getItem("USER_ENTITY_ID") != null) {
    //         localStorage.removeItem("USER_ENTITY_ID");
    //       }
    //       //alert("==>"+response.data.EntityUser.entityId);
    //       if (res.data.header.errorCode > 0) {
    //         // this.setState({
    //         //   isInDBErrorMsg: res.data.header.errorMsg
    //         // }, () => {
    //         //   self.props.init();
    //         // })
    //         alert(res.data.header.errorMsg)
  
    //       } else {
    //         localStorage.setItem("USER_ENTITY_ID", res.data.EntityUser && res.data.EntityUser.entityId);
    //         localStorage.setItem("USER_COMPANY_ID", res.data.EntityUser && res.data.EntityUser.companyId);
    //         localStorage.setItem("USER_COMPANY_NAME", res.data.EntityUser && res.data.EntityUser.companyName);
    //         // localStorage.setItem(" CognitoIdentityServiceProvider.3i3b7edrebuq8cp1juee8msin1.LastAuthUser",cellData.tokenId)
    //         // localStorage.setItem("CognitoIdentityServiceProvider.3i3b7edrebuq8cp1juee8msin1.74f10ed0-07fc-4bbd-a147-a6b4f1a07d1f.idToken",'eyJraWQiOiJqNGxuV1RUQ2RqczdmY2dIYTZ3QW1MdW11RVdJdU1STkpkc08za1dLWXA4PSIsImFsZyI6IlJTMjU2In0.eyJzdWIiOiI3NGYxMGVkMC0wN2ZjLTRiYmQtYTE0Ny1hNmI0ZjFhMDdkMWYiLCJlbWFpbF92ZXJpZmllZCI6dHJ1ZSwiYmlydGhkYXRlIjoiMTk4Ni0xMC0yMyIsImlzcyI6Imh0dHBzOlwvXC9jb2duaXRvLWlkcC51cy1lYXN0LTEuYW1hem9uYXdzLmNvbVwvdXMtZWFzdC0xX3U0TVB4ZjZQdiIsInBob25lX251bWJlcl92ZXJpZmllZCI6ZmFsc2UsImN1c3RvbTpjb21wYW55SWQiOiIyIiwiY29nbml0bzp1c2VybmFtZSI6Ijc0ZjEwZWQwLTA3ZmMtNGJiZC1hMTQ3LWE2YjRmMWEwN2QxZiIsImdpdmVuX25hbWUiOiJOYXZlZWQiLCJtaWRkbGVfbmFtZSI6InY0IiwiYXVkIjoiM2kzYjdlZHJlYnVxOGNwMWp1ZWU4bXNpbjEiLCJldmVudF9pZCI6ImI5Y2IyNDQzLWI4ZmItNDAyMy05NDlhLWU1OWU4MjY5MmI1NCIsInRva2VuX3VzZSI6ImlkIiwiYXV0aF90aW1lIjoxNjIxNDIwMzgyLCJuYW1lIjoiTmF2ZWVkIHY0IiwicGhvbmVfbnVtYmVyIjoiKzk4NzY1NDMyMTAiLCJleHAiOjE2MjE0MjM5ODIsImlhdCI6MTYyMTQyMDM4MiwiZW1haWwiOiJ2NC5uYXZlZWRAZXhjZWxibGF6ZS5jb20ifQ.eKM6iKJOKF4PDlvuwV8N7K0_L31Y5rimCtk0LDTm6Qui9x5vkKurlypIDHUoAp7aKUIGot8xPC3kzMvmtEOOXyHnsD4jNObDk0OhwfDDcNDXe7mYBUSMOYM7fd-H_FrUiPz_fm9PxdnCZAtxcv078mPX9KDs0PxNXB6g5lz2XV-JqSYo5k06iZ7DulFz03V5CQjvfKX0l15ah9xf6IMwEKVwx6GE5jlOhytDeXwpBvlJ9Puz08YPrGS-KkZQVXZmMF4-_ldrxuaxQrhSwtiKV9ptuZG9qEsjzoMSTyFIl_RMkJTHCzPHp0-WZd9gQHVyX6orgSi_uFW-7rNDF-lUKQ')
    //         // localStorage.setItem("CognitoIdentityServiceProvider.3i3b7edrebuq8cp1juee8msin1.74f10ed0-07fc-4bbd-a147-a6b4f1a07d1f.accessToken",'eyJraWQiOiJqNGxuV1RUQ2RqczdmY2dIYTZ3QW1MdW11RVdJdU1STkpkc08za1dLWXA4PSIsImFsZyI6IlJTMjU2In0.eyJzdWIiOiI3NGYxMGVkMC0wN2ZjLTRiYmQtYTE0Ny1hNmI0ZjFhMDdkMWYiLCJlbWFpbF92ZXJpZmllZCI6dHJ1ZSwiYmlydGhkYXRlIjoiMTk4Ni0xMC0yMyIsImlzcyI6Imh0dHBzOlwvXC9jb2duaXRvLWlkcC51cy1lYXN0LTEuYW1hem9uYXdzLmNvbVwvdXMtZWFzdC0xX3U0TVB4ZjZQdiIsInBob25lX251bWJlcl92ZXJpZmllZCI6ZmFsc2UsImN1c3RvbTpjb21wYW55SWQiOiIyIiwiY29nbml0bzp1c2VybmFtZSI6Ijc0ZjEwZWQwLTA3ZmMtNGJiZC1hMTQ3LWE2YjRmMWEwN2QxZiIsImdpdmVuX25hbWUiOiJOYXZlZWQiLCJtaWRkbGVfbmFtZSI6InY0IiwiYXVkIjoiM2kzYjdlZHJlYnVxOGNwMWp1ZWU4bXNpbjEiLCJldmVudF9pZCI6ImI5Y2IyNDQzLWI4ZmItNDAyMy05NDlhLWU1OWU4MjY5MmI1NCIsInRva2VuX3VzZSI6ImlkIiwiYXV0aF90aW1lIjoxNjIxNDIwMzgyLCJuYW1lIjoiTmF2ZWVkIHY0IiwicGhvbmVfbnVtYmVyIjoiKzk4NzY1NDMyMTAiLCJleHAiOjE2MjE0MjM5ODIsImlhdCI6MTYyMTQyMDM4MiwiZW1haWwiOiJ2NC5uYXZlZWRAZXhjZWxibGF6ZS5jb20ifQ.eKM6iKJOKF4PDlvuwV8N7K0_L31Y5rimCtk0LDTm6Qui9x5vkKurlypIDHUoAp7aKUIGot8xPC3kzMvmtEOOXyHnsD4jNObDk0OhwfDDcNDXe7mYBUSMOYM7fd-H_FrUiPz_fm9PxdnCZAtxcv078mPX9KDs0PxNXB6g5lz2XV-JqSYo5k06iZ7DulFz03V5CQjvfKX0l15ah9xf6IMwEKVwx6GE5jlOhytDeXwpBvlJ9Puz08YPrGS-KkZQVXZmMF4-_ldrxuaxQrhSwtiKV9ptuZG9qEsjzoMSTyFIl_RMkJTHCzPHp0-WZd9gQHVyX6orgSi_uFW-7rNDF-lUKQ')
    //         // localStorage.setItem("CognitoIdentityServiceProvider.3i3b7edrebuq8cp1juee8msin1.74f10ed0-07fc-4bbd-a147-a6b4f1a07d1f.refreshToken",'eyJraWQiOiJqNGxuV1RUQ2RqczdmY2dIYTZ3QW1MdW11RVdJdU1STkpkc08za1dLWXA4PSIsImFsZyI6IlJTMjU2In0.eyJzdWIiOiI3NGYxMGVkMC0wN2ZjLTRiYmQtYTE0Ny1hNmI0ZjFhMDdkMWYiLCJlbWFpbF92ZXJpZmllZCI6dHJ1ZSwiYmlydGhkYXRlIjoiMTk4Ni0xMC0yMyIsImlzcyI6Imh0dHBzOlwvXC9jb2duaXRvLWlkcC51cy1lYXN0LTEuYW1hem9uYXdzLmNvbVwvdXMtZWFzdC0xX3U0TVB4ZjZQdiIsInBob25lX251bWJlcl92ZXJpZmllZCI6ZmFsc2UsImN1c3RvbTpjb21wYW55SWQiOiIyIiwiY29nbml0bzp1c2VybmFtZSI6Ijc0ZjEwZWQwLTA3ZmMtNGJiZC1hMTQ3LWE2YjRmMWEwN2QxZiIsImdpdmVuX25hbWUiOiJOYXZlZWQiLCJtaWRkbGVfbmFtZSI6InY0IiwiYXVkIjoiM2kzYjdlZHJlYnVxOGNwMWp1ZWU4bXNpbjEiLCJldmVudF9pZCI6ImI5Y2IyNDQzLWI4ZmItNDAyMy05NDlhLWU1OWU4MjY5MmI1NCIsInRva2VuX3VzZSI6ImlkIiwiYXV0aF90aW1lIjoxNjIxNDIwMzgyLCJuYW1lIjoiTmF2ZWVkIHY0IiwicGhvbmVfbnVtYmVyIjoiKzk4NzY1NDMyMTAiLCJleHAiOjE2MjE0MjM5ODIsImlhdCI6MTYyMTQyMDM4MiwiZW1haWwiOiJ2NC5uYXZlZWRAZXhjZWxibGF6ZS5jb20ifQ.eKM6iKJOKF4PDlvuwV8N7K0_L31Y5rimCtk0LDTm6Qui9x5vkKurlypIDHUoAp7aKUIGot8xPC3kzMvmtEOOXyHnsD4jNObDk0OhwfDDcNDXe7mYBUSMOYM7fd-H_FrUiPz_fm9PxdnCZAtxcv078mPX9KDs0PxNXB6g5lz2XV-JqSYo5k06iZ7DulFz03V5CQjvfKX0l15ah9xf6IMwEKVwx6GE5jlOhytDeXwpBvlJ9Puz08YPrGS-KkZQVXZmMF4-_ldrxuaxQrhSwtiKV9ptuZG9qEsjzoMSTyFIl_RMkJTHCzPHp0-WZd9gQHVyX6orgSi_uFW-7rNDF-lUKQ')
  
    //         localStorage.setItem('tokenId', JSON.parse(localStorage.getItem('user_info')).tokenId)
    //         sessionStorage.setItem("USER_ENTITY_ID", res.data.EntityUser && res.data.EntityUser.entityId);
  
    //         sessionStorage.setItem("IsPhotoUploadComplete", (res.data.EntityUser && res.data.EntityUser.isPhotoUploadComplete ? res.data.EntityUser.isPhotoUploadComplete : ""));
    //         sessionStorage.setItem("IsInterestsComplete", (res.data.EntityUser && res.data.EntityUser.isInterestsComplete ? res.data.EntityUser.isInterestsComplete : ""));
    //         sessionStorage.setItem("IsAddExpereinceComplete", (res.data.EntityUser && res.data.EntityUser.isAddExpereinceComplete ? res.data.EntityUser.isAddExpereinceComplete : ""));
    //         sessionStorage.setItem("IsCreateGrowthPlanComplete", (res.data.EntityUser && res.data.EntityUser.isCreateGrowthPlanComplete ? res.data.EntityUser.isCreateGrowthPlanComplete : ""));
  
    //         localStorage.setItem("IsPhotoUploadComplete", (res.data.EntityUser && res.data.EntityUser.isPhotoUploadComplete ? res.data.EntityUser.isPhotoUploadComplete : ""));
    //         localStorage.setItem("IsInterestsComplete", (res.data.EntityUser && res.data.EntityUser.isInterestsComplete ? res.data.EntityUser.isInterestsComplete : ""));
    //         localStorage.setItem("IsAddExpereinceComplete", (res.data.EntityUser && res.data.EntityUser.isAddExpereinceComplete ? res.data.EntityUser.isAddExpereinceComplete : ""));
    //         localStorage.setItem("IsCreateGrowthPlanComplete", (res.data.EntityUser && res.data.EntityUser.isCreateGrowthPlanComplete ? res.data.EntityUser.isCreateGrowthPlanComplete : ""));
  
    //         window.IsPhotoUploadComplete = (res.data.EntityUser && res.data.EntityUser.isPhotoUploadComplete ? res.data.EntityUser.isPhotoUploadComplete : "0");
    //         window.IsInterestsComplete = (res.data.EntityUser && res.data.EntityUser.isInterestsComplete ? res.data.EntityUser.isInterestsComplete : "0");
    //         window.IsAddExpereinceComplete = (res.data.EntityUser && res.data.EntityUser.isAddExpereinceComplete ? res.data.EntityUser.isAddExpereinceComplete : "0");
    //         window.IsCreateGrowthPlanComplete = (res.data.EntityUser && res.data.EntityUser.isCreateGrowthPlanComplete ? res.data.EntityUser.isCreateGrowthPlanComplete : "0");
  
    //         localStorage.setItem('logingByPass', true)
  
    //         sessionStorage.setItem("SHOW_USERPROFILE", true);
    //         GlobalSession.entityId = res.data.EntityUser && res.data.EntityUser.entityId;
    //         GlobalSession.companyId = res.data.EntityUser && res.data.EntityUser.companyId;
    //         GlobalSession.companyName = res.data.EntityUser && res.data.EntityUser.companyName;
    //         // this.updateCognitoSessionObj();
    //         // this.updateEntityActivity();
    //         var redirectPage = _.get(self.props, 'location.state.path', '/dashboard');
    //         if (res.data.EntityUser.isAccountSetupComplete === 1) {
    //           localStorage.setItem("UserType", "ExistingUser");
    //           sessionStorage.setItem("UserType", "ExistingUser");
    //           window.UserType = "ExistingUser";
    //           // this.props.history.push(redirectPage);
    //           window.location.href = '#/dashboard'
    //           window.location.reload()
    //         } else if (res.data.EntityUser.isAccountSetupComplete === 0) {
    //           localStorage.setItem("UserType", "NewUser");
    //           sessionStorage.setItem("UserType", "NewUser");
    //           window.UserType = "NewUser";
    //           // this.setState({ isAccountSetUpComplete: false, success: true,isInDBErrorMsg:"" });
    //         }
    //       }
    //     })
    //   }
      
    // }
    render(){
      // let currentTokenId = localStorage.getItem('tokenId')
      // let userTokenId = JSON.parse(localStorage.getItem('user_info')).tokenId
      var UserLogo=this.state.imagePreviewUrl!=null&&this.state.imagePreviewUrl!=""?this.state.imagePreviewUrl:userImg
        return(     
          // style={{cursor:`${currentTokenId !== userTokenId ? 'pointer' : 'auto'}` }}
          <div style={this.props.style?this.props.style:{}}>   
            {this.state.loading? '':<img src={UserLogo} alt='' className="img-avatar mw-100 mh-100 rounded-circle headerprofile-image"  />}
         </div>
        );
    }
}


// export default UserImage;
const mapStateToProps = state => {
  return { enityPersonalObj: state.sidebar.enityPersonalObj
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators({ getEntityPersonalAction }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(UserImage);