import { all, fork } from "redux-saga/effects";
// import {sagas as awsCognitoSagas} from 'aws-cognito-redux-saga'
import users from './users'
import dashboardSagas from './dashboardSagas'
import growthPlanSagas from "./growthPlanSagas";
import sideBarSagas from "./sideBarSagas";
import growthPlanDetailsSagas from "./growthPlanDetailSagas";
import getPickListSagas from './pickListSagas';
import getMeetingSagas from './getMeetingSagas';
export default function* root() {
    yield all([
        // fork(awsCognitoSagas),
        users(),
        growthPlanSagas(),
        dashboardSagas(),
        sideBarSagas(),
        growthPlanDetailsSagas(),
        getPickListSagas(),
        getMeetingSagas()
    ]);
}
