
import { FETCH_MY_PROFILE, UPDATE_PROFILE, RECEIVE_MY_PROFILE, RECEIVE_MY_PROFILE_ERROR } from '../sagas/users';
import { action as actions } from 'aws-cognito-redux-saga';
import _ from 'lodash';
const initialState = {
  data: {},
  loading: true,
  error: false,
};

// https://redux.js.org/troubleshooting#never-mutate-reducer-arguments
// Make sure not to modify state
export default function profile (state = initialState, action) {
  switch (action.type) {
    case actions.AUTH_SIGN_OUT:
      return initialState;
    case FETCH_MY_PROFILE:
    case UPDATE_PROFILE:
      return Object.assign({}, 
        state, {loading: true, error: false});
    case RECEIVE_MY_PROFILE:
      return {
        data: action.payload,
        loading: false,
        error: false
      };
    case RECEIVE_MY_PROFILE_ERROR:
      return Object.assign({}, 
        state, {loading: false, error: true});
    default:
      return state;
  }
};