import React, { Component } from 'react';
import { Alert, Button, Col, Container, Form, Input, InputGroup, Row } from 'reactstrap';
import { state } from 'aws-cognito-redux-saga';
import { Link, useNavigate } from 'react-router-dom'
import _ from 'lodash';
import axios from 'axios';
import LoginLogo from '../../../assets/img/brand/login-logo.png';
import ProfileContainer from '../../Profile/ProfileContainer';
import { Modal, ModalBody } from 'reactstrap';
import GlobalSession from "../../Common/GlobalSession";
import './Login.css';

const LoginComponentNavigator = (props) => {
  const navigate = useNavigate();

  return <LoginComponent {...props} navigate={navigate} />
}

class LoginComponent extends Component {
  constructor(props) {
    super(props)
    this.signIn = this.signIn.bind(this);
    this.state = {
      'email': '',
      'password': '',
      'code': '',
      'success': false,
      'isAccountSetUpComplete': false,
      'isInDBErrorMsg': "",
      company_logo: localStorage.getItem('company_logo'),
      loading: false,
      isMoblie: false,
      // showMessage: true,
      // progress:10
    };
    // this.passwordReset = this.passwordReset.bind(this)
  }
  componentDidUpdate() {
    this.props.signOut && this.props.signOut();
  }
  async componentWillMount() {
    // this.redirectToNewPage()
    if (sessionStorage.getItem("UserBrowserSession") == null || sessionStorage.getItem("UserBrowserSession") == undefined || sessionStorage.getItem("UserBrowserSession") == "undefined") {
      localStorage.clear();
      localStorage.removeItem('appVersion');
      sessionStorage.clear();
    }
    this.initialize();
    if (this.state.company_logo === null || this.state.company_logo === '' || this.state.company_logo === undefined) {
      await this.getCompanyLogo();
    }
    if (this.props && this.props.match && this.props.match.params && Object.keys(this.props.match.params).length !== 0) {
      let { userId, teamId } = this.props.match.params;
      localStorage.setItem('URLUSERID', userId)
      localStorage.setItem('URLGPTEAMID', teamId)
      localStorage.setItem('URLGPID', teamId && teamId.search('-') ? teamId.split('-')[0] : teamId)
    } else {
      localStorage.removeItem('URLUSERID');
      localStorage.removeItem('URLTEAMID');
      localStorage.removeItem('URLGPID');
    }
    let userAgent = navigator.userAgent
    this.setState({isMoblie:/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent)})
  }
  
  getCompanyLogo = async () => {
    this.setState({ company_logo: LoginLogo })
    let url = window.location.href;
    if (!url.includes('dev.get') && !url.includes('localhost')) {
      try {
        await axios({
          url: process.env.REACT_APP_API_END_POINT + '/getcompanylogo',
          method: 'POST',
          data: {
            url: window.location.href.split('#')[0] || ''
          }
        }).then(res => {
          if (res && res.data && res.data.result && Object.keys(res.data.result).length > 0 && res.data.result.result) {
            localStorage.setItem('company_logo', res.data.result.result.logoUrl)
            this.setState({ company_logo: res.data.result.result.logoUrl })
          } else {
            this.setState({ company_logo: LoginLogo })
          }
        })
      } catch (e) {
        console.log(e)
      }

    } else {
      this.setState({ company_logo: LoginLogo })
    }

  }
  initialize = async () => {
    // await this.props.init();
    // if (this.props.auth.isSignedIn === state.AUTH_SUCCESS) {
    if (this.props.auth.isSignedIn === 'AUTH_SUCCESS') {
      await this.updateSessionObj(_.get(this.props.auth, 'info.accessToken.payload.username', _.get(this.props.auth, 'info.tokenId', '')));
      //await this.redirecttoAccountSetUp(this.props.auth.info.accessToken.payload.username);
    }
  }
  componentWillReceiveProps(nextprops) {
    if (this.props != nextprops) {
      // if (this.props.auth.isSignedIn != nextprops.auth.isSignedIn) {
      // if (nextprops.auth.isSignedIn === state.AUTH_SUCCESS) {
      if (nextprops.auth.isSignedIn === 'AUTH_SUCCESS') {
        this.callsessionAndAccountSetup(nextprops);
      }
      // }
    }
  }
  callsessionAndAccountSetup = async (nextprops) => {
    let token = _.get(nextprops.auth, 'info.accessToken.payload.username', _.get(nextprops.auth, 'info.tokenId', ''))
    await this.updateSessionObj(token);
    // await this.updateSessionObj(nextprops.auth.info.accessToken.payload.username);
    //await this.redirecttoAccountSetUp(nextprops.auth.info.accessToken.payload.username);
  }
  componentDidUpdate(prevProps, prevState) {
    if (this.props.auth.isSignedIn != prevProps.auth.isSignedIn) {
      if (this.props.auth.isSignedIn === "AUTH_SUCCESS") {
        var userBrowserSession = sessionStorage.getItem("UserBrowserSession");
        if (userBrowserSession != null && userBrowserSession != "null" && userBrowserSession != undefined && userBrowserSession != "undefined") {
          //this.updateSessionObj();
        } else {
          localStorage.clear();
          localStorage.removeItem('appVersion');
          sessionStorage.clear();
          window.location.reload();
        }
      }
    }

    // if(this.props.auth.isSignedIn!=prevProps.auth.isSignedIn){
    //   if (this.props.auth.isSignedIn ==="AUTH_SUCCESS") {
    //    // alert("hello"+sessionStorage.getItem("USER_ENTITY_ID"));

    //   }
    // }

  }
  updateEntityActivity = async () => {
    var inputobj = {
      "auditActivityType": "UA",
      "logType": "I",
      "activity": "LOGIN",
      "entityId": GlobalSession.entityId,
      "growthPlanId": null,
      "goalTagId": null,
      "actionTagId": null,
      "meetingId": null,
      "page": "",
      "auditText": "",
      "auditValue": null,
      "auditMessage": "User has Logged In",
      "auditLink": "",
      "email": null
    };
    await axios({
      method: 'POST',
      url: process.env.REACT_APP_API_END_POINT + '/updateEntityActivity',
      data: inputobj
    }).then(Response => Response)
      .then(res => { return res; })
      .catch(function (error) {
      });

  }

  updateCognitoSessionObj = async () => {
    let Local_token = () => Math.random().toString(36).substr(2) + Math.random().toString(36).substr(2);
    var accessToken = this.props.auth.info.accessToken ? this.props.auth.info.accessToken.jwtToken : Local_token();
    var refreshToken = this.props.auth.info.refreshToken ? this.props.auth.info.refreshToken.token : Local_token();
    var idToken = this.props.auth.info.idToken ? this.props.auth.info.idToken.jwtToken : Local_token();
    var expiresIn = 3600;
    var TokenType = "Bearer";

    var inputobj = {
      "entityId": GlobalSession.entityId,
      "AuthenticationResult": {
        "AccessToken": accessToken,
        "ExpiresIn": 3600,
        "IdToken": idToken,
        "RefreshToken": refreshToken,
        "TokenType": "Bearer"
      },
      "ChallengeParameters": {}
    };
    var isupdatedtoken = await axios({
      method: 'POST',
      url: process.env.REACT_APP_API_END_POINT + '/updateEntityToken',
      data: inputobj
    }).then(Response => Response)
      .then(res => { return res; })
      .catch(function (error) {
      });


  }

  redirecttoAccountSetUp = async (uuid) => {
    if (localStorage.getItem('loginUserEmail') != null && localStorage.getItem('loginUserEmail') != undefined) {
      //var uuid = this.props.auth.info.accessToken.payload.username;
      var randomnumber = Math.floor(Math.random() * 100);
      var isAccountSetUpComplete = 0;
      await axios({
        method: 'get',
        // url: process.env.REACT_APP_API_END_POINT + '/getEntityByEmail/' + localStorage.getItem('loginUserEmail')+"?v="+randomnumber,
        url: process.env.REACT_APP_API_END_POINT + '/getEntityByToken/' + uuid + "?v=" + randomnumber
      }).then(Response => Response)
        .then(res => {
          var entitypropsobj = res.data.EntityUser;
          isAccountSetUpComplete = entitypropsobj.isAccountSetupComplete;

        }).catch(function (error) {
        });

      var redirectPage = _.get(this.props, 'location.state.path', '/dashboard');
      if (isAccountSetUpComplete === 1) {
        // this.props.history.push(redirectPage);
        this.props.navigate(redirectPage)
      } else if (isAccountSetUpComplete === 0) {
        this.setState({ isAccountSetUpComplete: false, success: true });
      }
    }
  }
  toggleSuccess = () => {
    //window.UserType="NewUser";
    var redirectPage = _.get(this.props, 'location.state.path', '/dashboard');
    // this.props.history.push(redirectPage);
    this.props.navigate(redirectPage);
  }
  handleChange = async (event) => {
    this.props.auth.error && this.props.auth.error.message && this.props.UpdateLoginerrors("");
    const { target } = event;
    var value = target.type === 'checkbox' ? target.checked : target.value;
    const { name } = target;
    if (name == "email" && value != null && value != undefined) {
      value = value.toLowerCase();
    }

    await this.setState({
      [name]: value,
      loading: false,
      isInDBErrorMsg: ''
    });
  }

  signIn = async (event) => {
    this.setState({ loading: true });
    event.preventDefault();
    localStorage.setItem('loginUserEmail', this.state.email)
    //var obj = await this.updateSessionObj();
    sessionStorage.setItem("UserBrowserSession", true);
    await axios({
      url: process.env.REACT_APP_API_END_POINT + '/login-new',
      method: 'POST',
      data: {
        email: this.state.email.trim(),
        password: this.state.password.trim()
      }
    }).then(res => {
      if (res && res.data && res.data.result.result) {
        if (res.data.result.result.status === 0) {
          this.props.UpdateLoginerrors(_.get(res, 'data.result.result.errorMsg', ''))
        } else {
          let data = _.get(res, 'data.result.result[0]', {})
          if (res.data.result.result.length === 1) {
            // console.log('res', data)
            localStorage.setItem('user_info', JSON.stringify(data))
            localStorage.setItem('LOGIN_SUCCESS', JSON.stringify('AUTH_SUCCESS'))
            localStorage.setItem('loginAt', new Date())
            let self = this;
            this.props.auth.error && this.props.auth.error.message && this.props.UpdateLoginerrors("");
            setTimeout(async () => {
              self.props.UpdateLoginDetails(data);
              await self.fetchMyProfile(data.tokenId)
            }, 1000);
          }
        }
      }
      this.setState({ loading: false })
    }).catch(err => console.log(err))
    // sessionStorage.setItem("UserBrowserSession", true);
    // if (this.props.auth.isConfirmed === state.AUTH_SUCCESS) {
    //   this.props.signIn(this.state.email, this.state.password)
    // } else {
    //   this.props.signIn(this.state.email, this.state.password, this.state.code)
    // }
  }

  fetchMyProfile = async (token) => {
    await axios({
      url: `${process.env.REACT_APP_API_END_POINT}/getEntityByToken/${token}?v="+${Math.floor(Math.random() * 100)}`,
      method: 'GET',
    }).then(res => {
      if (res.data.EntityUser) {
        this.props.saveProfileData(res.data.EntityUser)
      }
    })
  }
  updateSessionObj = async (uuid) => {
    var email = localStorage.getItem("loginUserEmail");
    if (email != undefined && email != "undefined" && email != null && email != "null" && email != "") {
      //var uuid = this.props.auth.info.accessToken.payload.username;

      var randomnumber = Math.floor(Math.random() * 100);
      var self = this;
      await axios({
        method: 'GET',
        //url: process.env.REACT_APP_API_END_POINT + '/getEntityByEmail/' + localStorage.getItem("loginUserEmail")+"?v="+randomnumber
        url: process.env.REACT_APP_API_END_POINT + '/getEntityByToken/' + uuid + "?v=" + randomnumber
      })
        .then(Response => Response)
        .then(res => {
          // console.log("inside");
          // console.log(res.data);
          if (sessionStorage.getItem("USER_ENTITY_ID") != null) {
            sessionStorage.removeItem("USER_ENTITY_ID");
          }
          if (localStorage.getItem("USER_ENTITY_ID") != null) {
            localStorage.removeItem("USER_ENTITY_ID");
          }
          //alert("==>"+response.data.EntityUser.entityId);
          if (res.data.header.errorCode > 0) {
            this.setState({
              isInDBErrorMsg: res.data.header.errorMsg
            }, () => {
              // self.props.init();
              // self.initialize();
              localStorage.clear();
            })

          } else {
            localStorage.setItem("USER_ENTITY_ID", res.data.EntityUser && res.data.EntityUser.entityId);
            localStorage.setItem("USER_COMPANY_ID", res.data.EntityUser && res.data.EntityUser.companyId);
            localStorage.setItem("USER_COMPANY_NAME", res.data.EntityUser && res.data.EntityUser.companyName);
            sessionStorage.setItem("USER_ENTITY_ID", res.data.EntityUser && res.data.EntityUser.entityId);

            sessionStorage.setItem("IsPhotoUploadComplete", (res.data.EntityUser && res.data.EntityUser.isPhotoUploadComplete ? res.data.EntityUser.isPhotoUploadComplete : ""));
            sessionStorage.setItem("IsInterestsComplete", (res.data.EntityUser && res.data.EntityUser.isInterestsComplete ? res.data.EntityUser.isInterestsComplete : ""));
            sessionStorage.setItem("IsAddExpereinceComplete", (res.data.EntityUser && res.data.EntityUser.isAddExpereinceComplete ? res.data.EntityUser.isAddExpereinceComplete : ""));
            sessionStorage.setItem("IsCreateGrowthPlanComplete", (res.data.EntityUser && res.data.EntityUser.isCreateGrowthPlanComplete ? res.data.EntityUser.isCreateGrowthPlanComplete : ""));

            localStorage.setItem("IsPhotoUploadComplete", (res.data.EntityUser && res.data.EntityUser.isPhotoUploadComplete ? res.data.EntityUser.isPhotoUploadComplete : ""));
            localStorage.setItem("IsInterestsComplete", (res.data.EntityUser && res.data.EntityUser.isInterestsComplete ? res.data.EntityUser.isInterestsComplete : ""));
            localStorage.setItem("IsAddExpereinceComplete", (res.data.EntityUser && res.data.EntityUser.isAddExpereinceComplete ? res.data.EntityUser.isAddExpereinceComplete : ""));
            localStorage.setItem("IsCreateGrowthPlanComplete", (res.data.EntityUser && res.data.EntityUser.isCreateGrowthPlanComplete ? res.data.EntityUser.isCreateGrowthPlanComplete : ""));

            window.IsPhotoUploadComplete = (res.data.EntityUser && res.data.EntityUser.isPhotoUploadComplete ? res.data.EntityUser.isPhotoUploadComplete : "0");
            window.IsInterestsComplete = (res.data.EntityUser && res.data.EntityUser.isInterestsComplete ? res.data.EntityUser.isInterestsComplete : "0");
            window.IsAddExpereinceComplete = (res.data.EntityUser && res.data.EntityUser.isAddExpereinceComplete ? res.data.EntityUser.isAddExpereinceComplete : "0");
            window.IsCreateGrowthPlanComplete = (res.data.EntityUser && res.data.EntityUser.isCreateGrowthPlanComplete ? res.data.EntityUser.isCreateGrowthPlanComplete : "0");



            sessionStorage.setItem("SHOW_USERPROFILE", true);
            GlobalSession.entityId = res.data.EntityUser && res.data.EntityUser.entityId;
            GlobalSession.companyId = res.data.EntityUser && res.data.EntityUser.companyId;
            GlobalSession.companyName = res.data.EntityUser && res.data.EntityUser.companyName;
            // this.updateCognitoSessionObj();
            this.updateEntityActivity();
            let growthPlanId = localStorage.getItem('URLGPID');
            let userId = localStorage.getItem('URLUSERID');
            let teamId = localStorage.getItem('URLGPTEAMID');
            if (growthPlanId !== null && userId !== null && teamId !== null) {
              localStorage.setItem('communityGrowthPlanId', growthPlanId)
              localStorage.setItem('OwnerEntityId', userId)
              localStorage.setItem("childPlanId", teamId);
              localStorage.removeItem('URLUSERID');
              localStorage.removeItem('URLTEAMID');
              localStorage.removeItem('URLGPID');
              // this.props.history.push('/work-plan');
              this.props.navigate('/work-plan');
            } else {
              let path = this.state.isMoblie?'/mobile/dashboard':'/dashboard'
              var redirectPage = _.get(self.props, 'location.state.path', path);
              if (res&&res.data&&res.data.EntityUser&&res.data.EntityUser.isAccountSetupComplete === 1) {
                localStorage.setItem("UserType", "ExistingUser");
                sessionStorage.setItem("UserType", "ExistingUser");
                window.UserType = "ExistingUser";
                // this.props.history.push(redirectPage);
                this.props.navigate(redirectPage);
              } else if (res&&res.data&&res.data.EntityUser&&res.data.EntityUser.isAccountSetupComplete === 0) {
                localStorage.setItem("UserType", "NewUser");
                sessionStorage.setItem("UserType", "NewUser");
                window.UserType = "NewUser";
                this.setState({ isAccountSetUpComplete: false, success: true, isInDBErrorMsg: "" });
              }
            }

          }
        })
    }

  }

  // signIn = event => {
  //   event.preventDefault();
  //      //alert("test"+this.props.auth.isConfirmed);
  //   if (this.props.auth.isConfirmed === state.AUTH_SUCCESS) {
  //     if(this.state.email=="user@FHfo.com"&& this.state.password=="Y32paefe9U!"){
  //         //alert("success");
  //         this.props.history.push('/dashboard');
  //       }else{
  //         this.props.signIn(this.state.email, this.state.password)
  //       }
  //   } else {
  //     if(this.state.email=="user@FHfo.com"&& this.state.password=="Y32paefe9U!"){
  //         //alert("success");
  //         this.props.history.push('/dashboard');
  //       }else{
  //         //alert("failure");
  //         this.props.signIn(this.state.email, this.state.password, this.state.code)
  //       }
  //    // this.props.signIn(this.state.email, this.state.password, this.state.code)
  //   }
  // }

  forgotPassword = values => {
    this.props.forgotPassword(this.state.email.toLowerCase())
  }

  // redirectToNewPage = () => {
    
  //    setInterval(() => {
  //     this.setState((prevState) => ({progress: prevState.progress <= 0 ? 10 : prevState.progress - 1}))
  //   }, 1000);
  //   this.setState({showMessage: true})
  // }

  render() {
    const { auth } = this.props;
    let { company_logo} = this.state;
    // console.log(this.props)
    if (auth.passwordResetRequired === state.AUTH_SUCCESS) {
      if (auth.error.newPasswordRequired != null && auth.error.newPasswordRequired == true) {
        sessionStorage.setItem("ResetPasswordUserName", this.state.email.toLowerCase());
        sessionStorage.setItem("", "");
        return this.renderNewPasswordReset();
      } else {
        return this.renderPasswordReset();
      }
    } else {
      return this.renderSignIn(company_logo)
    }
    
  }

  renderNewPasswordReset() {
    return (
      <div className="app flex-row align-items-center login-section">
        <Container>
          <Row className=" justify-content-center  align-items-center d-flex">
            <Col md="4" className="text-center">
              {/* <CardGroup>
                <Card className="p-4">
                  <CardBody> */}
              <h1>Change Password</h1>
              <p className="text-muted">Your password needs to be changed</p>
              <Button
                color="primary"
                className="px-4"
                tag={Link} to="/forcechangepassword"
              // tag={Link} to="/resetpassword"
              >
                Change Password
              </Button>
              {/* <Row>
                      <Col xs="6">
                        
                      </Col>
                    </Row> */}
              {/* </CardBody>
                </Card>
              </CardGroup> */}
            </Col>
          </Row>
        </Container>
      </div>
    )
  }

  renderPasswordReset() {
    return (
      <div className="app flex-row align-items-center login-section">
        <Container>
          <Row className="justify-content-center  align-items-center d-flex">
            <Col md="4" className="text-center">
              {/* <CardGroup>
                <Card className="p-4">
                  <CardBody> */}
              <h1>Change Password</h1>
              <p className="text-muted">Your password needs to be changed</p>
              <Button
                color="primary"
                className="px-4"
                //tag={Link} to="/forcechangepassword"
                tag={Link} to="/resetpassword"
              >
                Change Password
              </Button>
              {/* <Row>
                      <Col xs="6">
                       
                      </Col>
                    </Row> */}
              {/* </CardBody>
                </Card>
              </CardGroup> */}
            </Col>
          </Row>
        </Container>
      </div>
    )
  }
  UserMultileClickSubmit(e) {
    e.preventDefault()
  }
  renderSignIn(company_logo) {
    const { auth } = this.props;
    //console.log(auth.error.message);
    //auth.error.message="Missing OnUp User for this email";
    // if(this.state.progress === 0){
    //   window.location.href = "https://www.missionboss.com"
    // }
    return (
      <div>
        <div className="d-flex flex-column align-items-center justify-content-center login-section position-relative">
          <div className="login-container login-form-fields">
            <Form onSubmit={(e) => !this.state.loading ? this.signIn(e) : this.UserMultileClickSubmit(e)}>
              <div className="login-logo">
                {company_logo && <img src={company_logo ? company_logo : ''} className="img-fluid" />}
              </div>
              <InputGroup className="mb-2">
                <Input name="email" type="text" placeholder="Email Address" autoComplete="email"
                  onChange={(e) => {
                    this.handleChange(e)
                  }} value={this.state.email} />
              </InputGroup>
              <InputGroup className="mb-2">
                <Input type="password" name="password" placeholder="Password" autoComplete="current-password"
                  onChange={(e) => {
                    this.handleChange(e)
                  }} />
              </InputGroup>
              {auth.error && auth.error.message ?
                (<Alert className='alertMessage' color="warning">{auth.error.message}</Alert>) :
                this.state.isInDBErrorMsg != "" && (<Alert className='alertMessage' color="warning">{this.state.isInDBErrorMsg}</Alert>)
              }
              {/* {this.state.isInDBErrorMsg!=""&&(<Alert className='alertMessage' color="warning">{this.state.isInDBErrorMsg}</Alert>)} */}
              <Button color="primary"  className="btn-block w-100">Sign In</Button>
              <Button color="link" className="btn-block w-100 mt-2" tag={Link} to="/resetpassword">Forgotten your password?</Button>
            </Form>
          </div>
          {/* {this.state.showMessage && <div className='position-absolute' style={{top:'15%',backgroundColor:"#fffc9a", color:"#000000",borderRadius:"8px"}}>
            <p className='m-0' style={{padding:"4px 16px", fontSize:"14px"}}>
              This site is moved to new domain, Please bookmark https://www.missionboss.com. you'll be redirected in {this.state.progress >= 0?this.state.progress:10} sec
              </p>
              </div>
              } */}
        </div>
        <div>
          <Modal centered={false} className="w-100 profile-popup" backdrop={"static"} isOpen={this.state.success} toggle={this.toggleSuccess}>
            {/* <ModalHeader toggle={this.toggleSuccess}>Message</ModalHeader> */}
            <ModalBody className="pt-2 px-4 pb-4 mb-3">
              <ProfileContainer isAccountSetupComplete={this.state.isAccountSetUpComplete} toggleSuccess={this.toggleSuccess} />
            </ModalBody>
          </Modal>
        </div>
      </div>
    );
  }
}

export default LoginComponentNavigator;
