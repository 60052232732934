
import {RECEIVE_ThoughtPad,RECEIVE_CreateThoughtPad,RECEIVE_ENTITY_PERSONAL } from '../actions/sideBarAction';
import { action as actions } from 'aws-cognito-redux-saga';
import _ from 'lodash';

const initialState = {
    thoughtpadobj: {},
    responseObject:{},
    enityPersonalObj:{}
   };
export default function(state = initialState, action) {
    switch (action.type) {
    case RECEIVE_ThoughtPad:
        return {
        ...state,
        thoughtpadobj: action.payload
        };
    case RECEIVE_CreateThoughtPad:
    return {
        ...state,
        responseObject: action.payload
        };
    case RECEIVE_ENTITY_PERSONAL:
        return {
            ...state,
            enityPersonalObj: action.payload
            };
    default:
        return state;
    }
}