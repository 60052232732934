import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Field, reduxForm } from 'redux-form'

// import { TextField } from 'redux-form-material-ui'


// import { RaisedButton, Paper } from 'material-ui'
import {  TextField } from '@mui/material'
import { Button } from 'reactstrap'
import LoginLogo from '../../../assets/img/brand/login-logo.png';
import { Navigate, Link } from 'react-router-dom';
import axios from 'axios';
const required = value => (value ? undefined : 'Required')

const style = {
  paper: { padding: '16px' },
  // layout: {
  //   display: 'flex',
  //   flexDirection: 'column',
  //   justifyContent: 'center',
  //   alignItems: 'center',
  //   height: 'calc(100vh - 56px)'
  // },
  // title: {
  //   fontSize: '32px',
  //   textAlign: 'center'
  // },
  // form: {
  //   width: '320px',
  //   display: 'flex',
  //   flexDirection: 'column',
  //   justifyContent: 'center',
  //   alignItems: 'center'
  // },
  button: {
    width: '80%',
    margin: '24px 0'
  },
  Inputtext: {
    fontSize: '16px',
    background: '#6e7f8d',
    color: '#fff !important',
    bordercolor: 'transparent',
  },
  error: {
    margin: '8px',
    color: 'rgb(200,0,0)',
    // minHeight: '48px',
    width: '80%',
    fontSize: '15px'
  }
}

class ResetPassword extends Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    forgotPassword: PropTypes.func.isRequired,
    auth: PropTypes.object,
    init: PropTypes.func,
    history: PropTypes.object
  }
  state = {
    company_logo: localStorage.getItem('company_logo'),
    loading: false
  }
  async componentWillMount() {
    if (this.state.company_logo === null || this.state.company_logo === '' || this.state.company_logo === undefined) {
      await this.getCompanyLogo();
    }
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.auth.hasSentCode == "AUTH_SUCCESS") {
      this.updateCognitoStatusInDB();
      this.updateEntityActivity();
    }
  }
  updateEntityActivity = async () => {
    var inputobj = {
      "auditActivityType": "UA",
      "logType": "I",
      "activity": "FORGOT PASSWORD",
      "entityId": null,
      "growthPlanId": null,
      "goalTagId": null,
      "actionTagId": null,
      "meetingId": null,
      "page": "",
      "auditText": "",
      "auditValue": null,
      "auditMessage": "User has clicked forgot password",
      "auditLink": "",
      "email": sessionStorage.getItem("ForgotPasswordEmailId")
    };
    await axios({
      method: 'POST',
      url: process.env.REACT_APP_API_END_POINT + '/updateEntityActivity',
      data: inputobj
    }).then(Response => Response)
      .then(res => { return res; })
      .catch(function (error) {
      });

  }
  updateCognitoStatusInDB = async () => {
    var email = sessionStorage.getItem("ForgotPasswordEmailId");
    await axios({
      method: 'post',
      url: process.env.REACT_APP_API_END_POINT + '/updateCognitoStatuByEmail',
      data: {
        "email": email
      }
    }).then(Response => Response)
      .then(res => {

      }).catch(function (error) {
      });

  }
  forgotPassword = async e => {
    // this.props.forgotPassword(values.email.toLowerCase())
    // sessionStorage.setItem("ForgotPasswordEmailId", values.email.toLowerCase());
    if(e.target[0].value.toLowerCase() === ""){
      this.setState({ error: "Enter valid EmailId" })
      return false
    }
    try {
      await axios({
        url: process.env.REACT_APP_API_END_POINT + '/forgetPassword',
        method: 'POST',
        data: {
          email: e.target[0].value.toLowerCase(),
        }
      }).then(async res => {
        if (res.data && res.data.header && res.data.header.errorCode === 0) {
          if (res.data.result.status === 0) {
            this.setState({ error: res.data.result.errorMsg })
          } else {
            sessionStorage.setItem("ForgotPasswordEmailId", e.target[0].value.toLowerCase());
            // await this.props.forgetPasswordRequest()
            await this.updateEntityActivity();
            this.setState({ error: false})
            window.location.href='#/login'
          }

        }
      }).catch(err => console.log('error', err))
    } catch (e) {
      console.log('error', e)
    }
  }

  // changePassword = () => {
  //   this.props.history.push('/changePassword')
  // }
  getCompanyLogo = async () => {
    let url = window.location.href;
    if (!url.includes('dev.get') && !url.includes('localhost')) {
      try {
        await axios({
          url: process.env.REACT_APP_API_END_POINT + '/getcompanylogo',
          method: 'POST',
          data: {
            url: window.location.href.split('#')[0] || ''
          }
        }).then(res => {
          if (res && res.data && res.data.result && Object.keys(res.data.result).length > 0 && res.data.result.result) {
            localStorage.setItem('company_logo', res.data.result.result.logoUrl)
            this.setState({ company_logo: res.data.result.result.logoUrl })
          } else {
            this.setState({ company_logo: LoginLogo })
          }
        })
      } catch (e) {
        console.log(e)
      }

    } else {
      this.setState({ company_logo: LoginLogo })
    }

  }
  render() {
    const { handleSubmit, auth } = this.props;
    let { company_logo, error, loading } = this.state;
    return (
      <div className="d-flex flex-column align-items-center justify-content-center login-section">
        <div className="login-container login-form-fields">

          <form onSubmit={this.forgotPassword}>
            <div className="login-logo">
              <img src={company_logo ? company_logo : ''} className="img-fluid" />
            </div>
            <p className="text-white text-center mt-3 mb-4 reset-text">
            Please reset your password. <br /> Enter your email below and we will send you a reminder.
              {/* Reset your Password. <br /> Enter your email address below and we will send you a link to securely reset your password. */}
            </p>
            {/* <div style={style.title}>Reset Password</div> */}

            <Field
              style={style.Inputtext}
              name="email"
              validate={[required]}
              component={TextField}
              placeholder='Email'
              type="string"
              labeltext="Email"
              className="form-control restfrpwd"
              autoComplete="new-password"
            // onKeyPress={this._handleKeyPress}
            />
            <div className="reseterror" style={style.error}>{error && error}</div>

            {!loading ? (
              <Button
                variant="contained"
                className='btn-block btn btn-primary resetsubmit w-100 mt-2'
                type="submit"
              // onClick={handleSubmit(this.forgotPassword)}
              >
                {/* Send Code */}
                Send Reminder
              </Button>
            ) : (
              <Navigate to="/changePassword" />
              // <Redirect from="/resetpassword" to="/changePassword" />
              // <RaisedButton
              //   className='btn-block btn btn-primary resetsubmit'
              //   onClick={handleSubmit(this.changePassword)}
              // >
              //   Change Password
              // </RaisedButton>
            )}

            {/* <div className="reseterror" style={style.error}>{auth.error && auth.error.message}</div> */}
            <Button color="link" className="btn-block w-100 mt-2" tag={Link} to="/login">Back to Login</Button>
          </form>

        </div>
      </div>
    )
  }
}

// Decorate the form component
export default reduxForm({
  form: 'resetPassword'
})(ResetPassword)
