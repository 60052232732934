import axios from "axios";


export const getPickListApi = async (obj) => {
    //console.log("obj.picklistType",obj.picklistType);
    try {
        // Get the authentication token
     
    // headers: {"Authorization" : `Bearer ${tokenStr}`}
        const response =  await axios({
                    method: 'POST',
                    url: process.env.REACT_APP_API_END_POINT+'/getPicklist',
                    data:{picklistType :obj.picklistType} 
                    //config: { headers: {'Content-Type': 'multipart/form-data' }}
                    })
                    .then(Response => Response)
                    .then(res => {
                     // console.log(res.data);
                       return res.data;
                    })
        return response;           
    } catch (e) {
        console.log(e);
    }
  };