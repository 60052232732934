import {all, fork, take, takeLatest, call, put, select} from "redux-saga/effects";
import { FETCH_EntityUsers,RECEIVE_EntityUsers,receiveProfileDetails } from "../actions/dashboardAction";
import { getEntityUsersApi } from "../api/dashboardapi";
import { checkAuth } from '../actions/auth'
// worker Saga: will be fired on FETCH_EntityUsers actions
function* fetchEntityUsers(action) {
    
  try {
    //const tokenStr = yield* checkAuth();
    // do api call
    const response = yield call(getEntityUsersApi,{entityId:action.payload});
    yield put(receiveProfileDetails(response));
  } catch (e) {
    console.log(e);
  }
}

/*
  Alternatively you may use takeLatest.

  Does not allow concurrent fetches of user. If "USER_FETCH_REQUESTED" gets
  dispatched while a fetch is already pending, that pending fetch is cancelled
  and only the latest one will be run.
*/
export default function* dashboardSagas() {
    yield all([
      takeLatest(FETCH_EntityUsers, fetchEntityUsers),
        
    ]);
  }
