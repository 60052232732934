import axios from "axios";

import _ from 'lodash';

export const FETCH_PICKLIST = "FETCH_PICKLIST";
export const RECEIVE_PICKLIST = "RECEIVE_PICKLIST";
export const getPickListAction= (picklistType) => {
    //console.log("inside getPickListAction" ,picklistType);
    return {
      type: FETCH_PICKLIST,
      payLoad:picklistType
    };
}

export const receievePickListActions = (PickListResponse) =>{
    //console.log("inside receivePickListAction");
    return{
        type:RECEIVE_PICKLIST,
        payLoad:PickListResponse
    }
}