import axios from "axios";

import _ from 'lodash';
import { checkAuth } from './auth';
import { reducer, state } from 'aws-cognito-redux-saga';
export const FETCH_ThoughtPad = 'FETCH_ThoughtPad';
export const RECEIVE_ThoughtPad = 'RECEIVE_ThoughtPad';

export const getThoughtPadAction = (entityId) => {
    return {
      type: FETCH_ThoughtPad,
      payload:entityId
    };
  }

  export const receiveThoughtPadAction = data => {
    return {
      type: RECEIVE_ThoughtPad,
      payload:data
    };
  }

  export const CREATE_ThoughtPad = 'CREATE_ThoughtPad';
  export const RECEIVE_CreateThoughtPad = 'RECEIVE_CreateThoughtPad';

  export const createThoughtPadAction = (obj) => {
    return {
      type: CREATE_ThoughtPad,
      payload:obj
    };
  }

  export const receiveCreateThoughtPadAction = data => {
    return {
      type: RECEIVE_CreateThoughtPad,
      payload:data
    };
  }

export const FETCH_ENTITY_PERSONAL = 'FETCH_ENTITY_PERSONAL';
export const RECEIVE_ENTITY_PERSONAL = 'RECEIVE_ENTITY_PERSONAL';
export const getEntityPersonalAction = (entityId) => {
  return {
    type: FETCH_ENTITY_PERSONAL,
    payload:entityId
  };
}

export const receiveEntityPersonalAction = data => {
  return {
    type: RECEIVE_ENTITY_PERSONAL,
    payload:data
  };
}