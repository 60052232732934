import _ from 'lodash';


export const GETROUTES = 'GETROUTES';
export const SAVEROUTES = 'SAVEROUTES';
export const REMOVEROUTES = 'REMOVEROUTES';
export const REMOVEROUTESBYINDEX = 'REMOVEROUTESBYINDEX';
export const UPDATEBREADCRUMbNAME = 'UPDATEBREADCRUMbNAME';

export const GetBreadcrumbs = data => {
    return {
      type: GETROUTES,
      payload:data
    };
  }
  export const SaveBreadcrumbs = data => {
    return {
      type: SAVEROUTES,
      payload:data
    };
  }
  export const RemoveBreadcrumbsbyIndex = data => {
    return {
      type:  REMOVEROUTESBYINDEX,
      payload:data
    };
  }
  export const RemoveBreadcrumbs = data => {
    return {
      type:REMOVEROUTES,
      payload:data
    };
  }

  export const UpdateBreadcrumbsName = data => {
    console.log('data',data)
    return {
      type:  UPDATEBREADCRUMbNAME,
      payload:data
    };
  }