import React, { Component } from 'react';
import { connect } from "react-redux";
import { reducer } from 'aws-cognito-redux-saga';

import ForgotPassword from './ForgotPasswordComponent'

class ForgotPasswordContainer extends Component {

    componentWillMount() {
        this.props.init();
    }

    render() {
        return <ForgotPassword {...this.props}/>;
    }
}

const mapStateToProps = (store, ownProps) => {
    return {
        auth: store.auth
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        init: () => {
            dispatch(reducer.init())
        },
        changePassword: (email, code, password) => {
            dispatch(reducer.changePassword(email, code, password))
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPasswordContainer);