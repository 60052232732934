import axios from "axios";


export const getEntityUsersApi = async (obj) => {
    
    // try {
    //     // Get the authentication token
     
    // // headers: {"Authorization" : `Bearer ${tokenStr}`}
    //     const response =  await axios({
    //                 method: 'get',
    //                 url: process.env.REACT_APP_API_END_POINT+'/getEntitySetup/'+obj.entityId,
    //                 //data: input,  
    //                 //config: { headers: {'Content-Type': 'multipart/form-data' }}
    //                 })
    //                 .then(Response => Response)
    //                 .then(res => {
    //                   console.log(res.data);
    //                    return res.data;
    //                 })
    //     return response;           
    // } catch (e) {
    //     console.log(e);
    // }

    const response=await fetch(process.env.REACT_APP_API_END_POINT+'/getEntitySetup/'+obj.entityId)
      .then(res => res.json())
      .then(
        (result) => {
            //console.log("entityResult"+result)
            //console.log(result)
            return result;
        },
        (error) => {
            console.log(error);
        }
      )
      return response; 
  };
  export const getReportApi = async (obj) => {
    try {
        const response =  await axios({
                    method: 'POST',
                    url: process.env.REACT_APP_API_END_POINT+'/reports',
                    data:obj,
                    })
                    .then(Response => Response)
                    .then(res => {
                       return res;
                    })
        return response;           
    } catch (e) {
        console.log(e);
    }
  };
  
  