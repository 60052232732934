import axios from "axios";
import {GlobalSession,ShowErrorAlert} from "../views/Common/GlobalSession";

export const getMeetingsApi = async (obj) => {
    try {
        
        if(obj.entityId&&localStorage.getItem('logingByPass')){
            obj.entityId=-Math.abs(Number(obj.entityId))
        }
        // Get the authentication token
        //{"action" : "PENDING", "entityId":GlobalSession.entityId}
    // headers: {"Authorization" : `Bearer ${tokenStr}`}
        const response =  await axios({
                    method: 'POST',
                    url: process.env.REACT_APP_API_END_POINT+'/getMeetingsDetail',
                    data: obj
                    //config: { headers: {'Content-Type': 'multipart/form-data' }}
                    })
                    .then(Response => Response)
                    .then(res => {
                        if(res.data.header.errorCode>0){
                            ShowErrorAlert(res.data.header.errorCode,res.data.header.errorMsg);
                        }
                      //console.log(res);
                      //console.log(res.data);
                       return res.data;
                    })
        return response;           
    } catch (e) {
        console.log(e);
    }
  };

  export const updateFeedbackApi = async (obj) => {
    
    try {
        //console.log("updateGrowthplanWeekTemplate input obj");
        //console.log(obj);
        // Get the authentication token
     
    // headers: {"Authorization" : `Bearer ${tokenStr}`}
        const response =  await axios({
                    method: 'POST',
                    url: process.env.REACT_APP_API_END_POINT+'/updateFeedback',
                    data:obj,  
                    //config: { headers: {'Content-Type': 'multipart/form-data' }}
                    })
                    .then(Response => Response)
                    .then(res => {
                      //console.log(res.data);
                       return res.data;
                    })
        return response;           
    } catch (e) {
        console.log(e);
    }
  };

  export const getFeedbackApi = async (obj) => {
    try {
        // Get the authentication token
     
    // headers: {"Authorization" : `Bearer ${tokenStr}`}
        const response =  await axios({
                    method: 'POST',
                    url: process.env.REACT_APP_API_END_POINT+'/getFeedback',
                    data:obj 
                    //config: { headers: {'Content-Type': 'multipart/form-data' }}
                    })
                    .then(Response => Response)
                    .then(res => {
                      //console.log(res);
                      //console.log(res.data);
                       return res.data;
                    })
        return response;           
    } catch (e) {
        console.log(e);
    }
  };


  export const getUpdateMeetingApi = async (obj) => {
    try {
        // Get the authentication token
     
    // headers: {"Authorization" : `Bearer ${tokenStr}`}
        const response =  await axios({
                    method: 'POST',
                    url: process.env.REACT_APP_API_END_POINT+'/updateMeeting',
                    data:obj 
                    //config: { headers: {'Content-Type': 'multipart/form-data' }}
                    })
                    .then(Response => Response)
                    .then(res => {
                      //console.log(res);
                      //console.log(res.data);
                       return res.data;
                    })
        return response;           
    } catch (e) {
        console.log(e);
    }
  };
  