import passwordValidator from 'password-validator';
import _ from 'lodash';

export default function validate(newPassword) {
    const ruleExplanations = {
        "min": "Password must be at least 8 characters long",
        "uppercase": "Password must include uppercase characters",
        "lowercase": "Password must include lowercase characters",
        "digits": "Password must include numbers",
        "symbols": "Password must include special symbols"
    };
    var schema = new passwordValidator();
    schema
        .is().min(8)
        .has().uppercase()
        .has().lowercase()
        .has().digits()
        .has().symbols();
    const passwordRulesViolated = schema.validate(newPassword, { list: true });
    const newPasswordErrors = _.values(_.pick(ruleExplanations, passwordRulesViolated));
    return newPasswordErrors;
}