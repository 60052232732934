import { connect } from 'react-redux'

// import { reducer } from 'aws-cognito-redux-saga'

import ChangePasswordComponent from './ChangePassword'
import { forgetPasswordRequest, UpdateLoginerrors } from '../../../actions/auth'

const mapStatetoProps = state => {
  return {
    auth: state.auth
  }
}

const mapDispatchToProps = dispatch => {
  return {
    // init: () => {
    //   dispatch(reducer.init())
    // },
    // changePassword: (username, code, password) => {
    //   console.log(username+"=="+code+"==="+password)
    //   dispatch(reducer.changePassword(username, code, password))
    // }
    forgetPasswordRequest:()=>{
      dispatch(forgetPasswordRequest())
    },
    UpdateLoginerrors: (data) => {
      dispatch(UpdateLoginerrors(data))
    },
  }
}

export default connect(mapStatetoProps, mapDispatchToProps)(
  ChangePasswordComponent
)
