import axios from "axios";
export const getEntityInterestsApi = async (id) => {
    
    try {
        // Get the authentication token
        var randomnumber=Math.floor(Math.random() * 100);
    // headers: {"Authorization" : `Bearer ${tokenStr}`}
        const response =  await axios({
                    method: 'GET',
                    url: process.env.REACT_APP_API_END_POINT+'/getEntityInterests/'+id+"?v="+randomnumber,
                    // data: {"id":obj.id},  
                    // config: { headers: {'Content-Type': 'multipart/form-data' }}
                    headers: { 'Cache-Control' : 'no-cache', 'Pragma': 'no-cache'}
                    })
                    .then(Response => Response)
                    .then(res => {
                     // console.log(res.data);
                       return res.data;
                    })
        return response;           
    } catch (e) {
        console.log(e);
    }
  };

  export const updateEntityInterestsTag = async (updateDetails) => {
    
    try {
        // Get the authentication token
     
    // headers: {"Authorization" : `Bearer ${tokenStr}`}
        const response =  await axios({
                    method: 'POST',
                    url: process.env.REACT_APP_API_END_POINT+'/updateEntityInterestsTag/',
                    data: updateDetails,  
                    config: { headers: { 'Content-Type': 'application/json'}}
                    })
                    .then(Response => Response)
                    .then(res => {
                      //console.log(res.data);
                       return res.data;
                    })
        return response;           
    } catch (e) {
        console.log(e);
    }
  };

 export default getEntityInterestsApi;