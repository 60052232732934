import {all,takeLatest, call, put} from "redux-saga/effects";
import {FETCH_PICKLIST,receievePickListActions} from '../actions/getPickListAction';
import { getPickListApi } from "../api/getPickListApi";

function* fetchPickListSagas(action){
    //console.log("action",action.payLoad);
    try {
        const response = yield call(getPickListApi,{picklistType:action.payLoad});
        //console.log("growthplanDetailsagas"+response);
        yield put(receievePickListActions(response));
    } catch (e) {
        console.log(e);
    }
}

export default function* getPickListSagas() {
    //console.log("inside growthPlan sagas top method");
    yield all([
    takeLatest(FETCH_PICKLIST, fetchPickListSagas)
    ]);
}                                                                               
