import axios from "axios";

import _ from 'lodash';

export const FETCH_GrowthPlanDetails_Lst = 'FETCH_GrowthPlanDetails_Lst';
export const RECEIVE_GrowthPlanDetails_Lst = 'RECEIVE_GrowthPlanDetails_Lst';
export const FETCH_TeamGrowthPlanDetails_Lst = 'FETCH_TeamGrowthPlanDetails_Lst';
export const RECEIVE_TeamGrowthPlanDetails_Lst = 'RECEIVE_TeamGrowthPlanDetails_Lst';
export const SEND_GrowthPlanDetailsaction_Lst = 'SEND_GrowthPlanDetailsaction_Lst';
export const ADD_NewGoalPlanDetails_lst = 'ADD_NewGoalPlanDetails_lst';
export const FETCH_GoalDetails = 'FETCH_GoalDetails';
export const UPDATE_FILTEROPTION = 'UPDATE_FILTEROPTION';
export const getGrowthPlanDetailsAction= (growthPlanAction,entityId,growthPlanId,childPlanId) => {
    return {
      type: FETCH_GrowthPlanDetails_Lst,
      growthPlanAction:growthPlanAction,
      entityId:entityId,
      growthPlanId:growthPlanId,
      childPlanId:childPlanId
    };
  }
  
  export const receiveGrowthPlanDetailsAction = data => {
    return {
      type: RECEIVE_GrowthPlanDetails_Lst,
      payload:data
    };
  }

  export const addNewGrowthPlanDetails = addNewGoalDetails =>{
    //console.log("addNewGoalDetails in action",addNewGoalDetails);
    return {
      type: ADD_NewGoalPlanDetails_lst,
      payload:addNewGoalDetails,
    }
  }
  
  export const receiveNewGoalDetails = data =>{
    return {
      type:FETCH_GoalDetails,
      payload:data
    }
  }

  export const getTeamGrowthPlanDetailsAction= (growthPlanAction,entityId,growthPlanId,statusId,companyId) => {
    return {
      type: FETCH_TeamGrowthPlanDetails_Lst,
      growthPlanAction:growthPlanAction,
      entityId:entityId,
      growthPlanId:growthPlanId,
      statusId:statusId,
      companyId:companyId
    };
  }
  
  export const receiveTeamGrowthPlanDetailsAction = data => {
    return {
      type: RECEIVE_TeamGrowthPlanDetails_Lst,
      payload:data
    };
  }
  export const UpdateFilterOption = data => {
    return {
      type: UPDATE_FILTEROPTION,
      payload:data
    };
  }