import { config } from 'aws-cognito-redux-saga';

export default async function(oldPassword, newPassword) {
  const cognitoUser = config.config.getUser();
  const currentSession = await config.getSession();
  cognitoUser.setSignInUserSession(currentSession);

  return new Promise((resolve, reject) => {
    cognitoUser.changePassword(oldPassword, newPassword, function(err, result) {
        if (err) {
            reject(err)
        } else {
            resolve(result)
        }
    })
  })
}