import React, { useState, useEffect } from 'react'
import LoadingDots from './views/Common/LoadingDots'
import LoginLogo from './assets/img/brand/login-logo.png';
import axios from 'axios';
import moment from 'moment'
const InviteUser = (props) => {
    let { id } = props.match.params || {};
    const [loader, SetLoader] = useState(true);
    const [error, setError] = useState('')
    useEffect(() => {
        const GetInviteDetals = async () => {
            if (Number.isInteger(Number(id))) {
                await axios({
                    method: 'POST',
                    url: process.env.REACT_APP_API_END_POINT + '/getInviteMeetingDetail',
                    data: { "code": id },
                })
                    .then(Response => Response)
                    .then(res => {
                        let { result } = res.data;
                        console.log(result);
                        if (result.length > 0) {
                            // console.log("res", result);
                            // debugger;
                            if (result[0].recomendationId === 0) {
                                redirrect()
                                setError(`No Meeting's available now. Your next schedule Meeting is on ${moment(result[0].scheduledMM).format('DD-MM-YYYY HH:mm:ss')}`)
                            } else {
                                localStorage.setItem('InvitemeetingId', result[0].meetingId);
                                localStorage.setItem('InvitecommunityGrowthPlanId', result[0].growthPlanId);
                                localStorage.setItem('InviteentityMentorId', result[0].entityMentorId);
                                localStorage.setItem("childPlanId",result[0].teamId);
                                window.location.href = "#/communityMeetingInvitePage";
                            }
                            SetLoader(false)
                        } else {
                            setError('Your meeting link is expired')
                            SetLoader(false)
                            // redirrect()
                        }
                    })
            } else {
                window.location.href = '#/login' 
            }
        }
        GetInviteDetals()
        const redirrect = () => {
            setTimeout(() => {
                window.location.href = "https://www.teamonup.com/communitygrowthplan";

            }, 2000)
        }
    }, [])
    return (
        <div >
            {loader && <LoadingDots />}
            {!loader && <div className='d-flex align-items-center justify-content-center ' style={{ height: '100vh' }}>
                <div className='text-center'>
                    <img src={LoginLogo} width='300' />
                    <p>{error}</p>
                </div>
            </div>}
        </div>
    )
}

export default InviteUser;