import axios from "axios";
import GlobalSession from "../views/Common/GlobalSession";
export const getEntityBioApi = async (id) => {
    
    try {
        // Get the authentication token
        var randomnumber=Math.floor(Math.random() * 100);
    // headers: {"Authorization" : `Bearer ${tokenStr}`}
        const response =  await axios({
                    method: 'GET',
                    url: process.env.REACT_APP_API_END_POINT+'/getEntityBio/'+id+"?v="+randomnumber,
                    // data: {"id":obj.id},  
                    //config: { headers: {'Content-Type': 'multipart/form-data' }}
                    })
                    .then(Response => Response)
                    .then(res => {
                      //console.log(res.data);
                       return res.data;
                    })
        return response;           
    } catch (e) {
        console.log(e);
    }
  };
  export const updateEntityBioApi = async (obj) => {
    
    try {
        // Get the authentication token
     
    // headers: {"Authorization" : `Bearer ${tokenStr}`}
        const response =  await axios({
                    method: 'POST',
                    url: process.env.REACT_APP_API_END_POINT+'/updateEntityBio',
                     data: {"entityId":GlobalSession.entityId,"summary":obj.summary},  
                    //config: { headers: {'Content-Type': 'multipart/form-data' }}
                    })
                    .then(Response => Response)
                    .then(res => {
                      //console.log(res.data);
                       return res.data;
                    })
        return response;           
    } catch (e) {
        console.log(e);
    }
  };
 export default getEntityBioApi;