import axios from "axios";

import _ from 'lodash';
import { checkAuth } from './auth';
import { reducer, state } from 'aws-cognito-redux-saga';
export const FETCH_UserBio = 'FETCH_UserBio';
export const RECEIVE_UserBio = 'RECEIVE_UserBio';
export const FETCH_UserInterest = 'FETCH_UserInterest';
export const RECEIVE_UserInterest = 'RECEIVE_UserInterest';
export const REQUEST_UserInterestUpdate = 'REQUEST_UserInterestUpdate';
export const FETCH_UserExperience = 'FETCH_UserExperience';
export const REQUEST_UserExperienceUpdate = 'REQUEST_UserExperienceUpdate';
export const RECEIVE_UserExperience = 'RECEIVE_UserExperience';
export const FETCH_UserGrowthPlan = 'FETCH_UserGrowthPlan';
export const RECEIVE_UserGrowthPlan = 'RECEIVE_UserGrowthPlan';
export const FETCH_UPDATEUSERBIO = 'FETCH_UPDATEUSERBIO';
export const RECEIVE_UPDATEUSERBIO = 'RECEIVE_UPDATEUSERBIO';
export const UPDATE_ALL_USERLIST = 'UPDATE_ALL_USERLIST';

export const getEntityBio = (id) => {
    return {
      type: FETCH_UserBio,
      payload:id
    };
  }

export const updateEntityBio = (userDetails) => {
    return {
      type: RECEIVE_UserBio,
      payload:userDetails
    };
  }

export const getEntityInterests = (id) => {
  return{
    type:FETCH_UserInterest,
    payload:id
  };
}

export const updateEntityInterests = (userInterest) => {
  return{
    type:RECEIVE_UserInterest,
    payload:userInterest
  };
}

export const requestUpdateEntityInterests = (updateDetails) => {
  return{
    type:REQUEST_UserInterestUpdate,
    payload:updateDetails
  };
}

export const getEntityExperience = (id) => {
  return{
    type:FETCH_UserExperience,
    payload:id
  };
}

export const updateEntityExperience = (userExperience) => {
  return{
    type:RECEIVE_UserExperience,
    payload:userExperience
  };
}

export const requestUpdateEntityExperience = (updateDetails) => {
  return{
    type:REQUEST_UserExperienceUpdate,
    payload:updateDetails
  };
}

export const getGrowthPlan = (id) => {
  return{
    type:FETCH_UserGrowthPlan,
    payload:id
  };
}

export const updateEntityGrowthPlan = (data) => {
  return{
    type:RECEIVE_UserGrowthPlan,
    payload:data
  };
} 

export const updateEntityBioAction = summary =>{
  //console.log("summary",summary);
  return{
    type:FETCH_UPDATEUSERBIO,
    payLoad:summary
  }
}

export const receiveUpdateUserBioAction = data =>{
  return {
    type:RECEIVE_UPDATEUSERBIO,
    payLoad:data
  }
}
export const UpdateAllUserList = data =>{
  return {
    type:UPDATE_ALL_USERLIST,
    payLoad:data
  }
}