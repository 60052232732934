
import { FETCH_EntityUsers, RECEIVE_EntityUsers ,SETFIREWORKS} from '../actions/dashboardAction';
import { action as actions } from 'aws-cognito-redux-saga';
import _ from 'lodash';
const initialState = {
     profileobj: {}
    };
export default function(state = initialState, action) {
    //console.log("dashboard action.type======="+action.type);
    
      switch (action.type) {
        case RECEIVE_EntityUsers:
          return {
            ...state,
            profileobj: action.payload
          };
          case SETFIREWORKS:
          return {
            ...state,
            activateFireWorks: action.payload
          };
        default:
          return state;
      }
    }

















// const initialState = {
//    profileobj: {}
//   };
  
//   export default function(state = initialState, action) {
//     switch (action.type) {
//       case "GetProfileDetails":
//         return {
//           ...state,
//           profileobj: action.payload
//         };
//       default:
//         return state;
//     }
//   }