import moment from 'moment-timezone';


export const getTimeZoneBasedDateFormat = (inputDate) => {
  //Date Input
  
  var returndate;var date;
  if(inputDate !==null){
    var DueDatemilliseconds = inputDate.getTime(); 
    let ofsetValue = new Date().getTimezoneOffset()
    if(ofsetValue<0){
      let timeZoneValue = (ofsetValue/ -60)
      let orginalValue = null
      orginalValue = parseFloat(timeZoneValue)*3600000
      date = parseFloat(DueDatemilliseconds)-parseFloat(orginalValue)
    }else{ 
      let timeZoneValue = (ofsetValue/ 60)
      let orginalValue = null
      orginalValue = parseFloat(timeZoneValue)*3600000
      date = parseFloat(DueDatemilliseconds)+parseFloat(orginalValue)
    }
    var DDate = new Date(date);
    var finalval = moment(DDate);
    var dueDate=new Date(finalval);
    returndate=dueDate;
   }
   return returndate;
}
export const removeTimeZoneForDatePicker=(inputDate)=>{
  // console.log("===Start removeTimeZoneForDatePicker=====");
  // console.log(inputDate);
  if(inputDate!=null && inputDate!=undefined){
    // console.log("if=====");
    var inputDateinstr=inputDate+"";
    var returnDate;
    if(inputDateinstr.indexOf("GMT")>-1){
      // console.log("GMT IF=====");
      var date=inputDateinstr.split(" ")[2];
      var month=getMonth(inputDateinstr.split(" ")[1]);
      var year=inputDateinstr.split(" ")[3];
      //var dateval=year+"-"+month+"-"+date+"T00:00:00.000";
      // console.log(dateval);
      //returnDate= new Date(dateval);
      
      returnDate= convertToDate(year+"-"+month+"-"+date);
      // // console.log(inputDateinstr.split("T")[0]);
      
    }else if(inputDateinstr.indexOf("Z")>-1){
      // console.log("TZ IF=====");
      //returnDate=new Date(inputDateinstr.split("T")[0]+"T00:00:00.000");
      returnDate= convertToDate(inputDateinstr.split("T")[0]);
    }else{
      // console.log("inside else part")
      //returnDate=new Date(inputDate+"T00:00:00.000");
      returnDate=convertToDate(inputDate);
    }
  // console.log("returnDate");
  // console.log(returnDate);
  // console.log("===End removeTimeZoneForDatePicker=====");
  return returnDate;
  }else{
    // console.log("===End removeTimeZoneForDatePicker=====");
    return null;
  }
  
}


export const getDateWithoutTime=(inputDate)=>{
  // console.log("====Start getDateWithoutTime====");
  // console.log(inputDate);
  // var obj=new Date(inputDate)+"";
  // var date=obj.split(" ")[2];
  // var month=getMonth(obj.split(" ")[1]);
  // var year=obj.split(" ")[3];
  // var dateval=year+"-"+month+"-"+date;
  if(inputDate!=null && inputDate!=undefined){
    // console.log("if=====");
    var inputDateinstr=inputDate+"";
    var returnDate;
    if(inputDateinstr.indexOf("GMT")>-1){
      // console.log("GMT IF=====");
      var date=inputDateinstr.split(" ")[2];
      var month=getMonth(inputDateinstr.split(" ")[1]);
      var year=inputDateinstr.split(" ")[3];
      var dateval=year+"-"+month+"-"+date;
      returnDate= dateval;
    }else if(inputDateinstr.indexOf("Z")>-1){
      // console.log("TZ IF=====");
      returnDate=inputDateinstr.split("T")[0];
    }else{
      // console.log("inside else part")
      returnDate=inputDate;
    }
  // console.log("returnDate");
  // console.log(returnDate);
  // console.log("===End getDateWithoutTime=====");
  return returnDate;
  }else{
    // console.log("===End getDateWithoutTime=====");
    return null;
  }


  
}

export const getTimeWithoutDate=(inputDate)=>{
   var returnDate=null;
   if(inputDate!=null){
     if(inputDate.indexOf("Z")>-1){
      var inputDateinstr=inputDate+"";
      returnDate=inputDateinstr.split("T")[1].replace(":00.000Z","");
      returnDate=returnDate.replace("Z","");
    }else{
      returnDate=moment(inputDate).format("hh:mm");
    }
  }
return returnDate;
  
}
export const isValidateDate= (inputText)=>{
  console.log(inputText);
  //inputText="09/30/2019";
  //debugger;
  var dateformat = /^((0?[1-9]|1[012])[/](0?[1-9]|[12][0-9]|3[01])[/](19|20)?[0-9]{2})*$/;
  // Match the date format through regular expression
  if(dateformat.test(inputText))
  {
  //Test which seperator is used '/' or '-'
  var opera1 = inputText.split('/');
  var lopera1 = opera1.length;
  // Extract the string into month, date and year
  if (lopera1>1)
  {
  var pdate = inputText.split('/');
  }
  
  var dd = parseInt(pdate[1]);
  var mm  = parseInt(pdate[0]);
  var yy = parseInt(pdate[2]);
  // Create list of days of a month [assume there is no leap year by default]
  var ListofDays = [31,28,31,30,31,30,31,31,30,31,30,31];
  if (mm==1 || mm>2)
  {
  if (dd>ListofDays[mm-1])
  {
  //alert('Invalid date format!');
  return false;
  }
  }
  if (mm==2)
  {
  var lyear = false;
  if ( (!(yy % 4) && yy % 100) || !(yy % 400)) 
  {
  lyear = true;
  }
  if ((lyear==false) && (dd>=29))
  {
  //alert('Invalid date format!');
  return false;
  }
  if ((lyear==true) && (dd>29))
  {
  //alert('Invalid date format!');
  return false;
  }
  }
  }
  else
  {
  //alert("Invalid date format!");
  return false;
  }
  return true;
  }


function getMonth(monthInStr){
  var returnVal;
  switch(monthInStr) {
    case "Jan":
      returnVal="01";break;
    case "Feb":
      returnVal="02";break;
    case "Mar":
        returnVal="03";break;
    case "Apr":
        returnVal="04";break;
    case "May":
        returnVal="05";break;
    case "Jun":
        returnVal="06";break;
    case "Jul":
        returnVal="07";break;
    case "Aug":
        returnVal="08";break;
    case "Sep":
        returnVal="09";break;
    case "Oct":
        returnVal="10";break;
    case "Nov":
        returnVal="11";break;
    case "Dec":
        returnVal="12";break;
      
  }
  return returnVal;
}
function convertToDate(input) {
  var parts = input.split('-');
  // new Date(year, month [, day [, hours[, minutes[, seconds[, ms]]]]])
  return new Date(parts[0], parts[1]-1, parts[2]); // Note: months are 0-based
 }
 export const convertToMMDDYY=(inputDate)=>{
  
  if(inputDate!=null && inputDate!=undefined){
    // console.log("if=====");
    var inputDateinstr=inputDate+"";
    var returnDate;
    if(inputDateinstr.indexOf("GMT")>-1){
      // console.log("GMT IF=====");
      var date=inputDateinstr.split(" ")[2];
      var month=getMonth(inputDateinstr.split(" ")[1]);
      var year=inputDateinstr.split(" ")[3];
      var dateval=month+"/"+date+"/"+year;
      returnDate= dateval;
    }else if(inputDateinstr.indexOf("Z")>-1){
      var str=inputDateinstr.split("T")[0];
      var inputDateinstr=str.split("-");
      var date=inputDateinstr[2];
      var month=inputDateinstr[1];
      var year=inputDateinstr[0];
      var dateval=month+"/"+date+"/"+year;
      returnDate= dateval;
    }else{
      returnDate=inputDate;
    }
  // console.log("returnDate");
  // console.log(returnDate);
  // console.log("===End getDateWithoutTime=====");
  return returnDate;
  }else{
    // console.log("===End getDateWithoutTime=====");
    return null;
  }


  
}