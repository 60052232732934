import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import { Alert, Button, Card, CardBody, CardHeader, Col, Row, Table, FormGroup, Input, Label, Form } from 'reactstrap';

class Profile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      'profile': {
        'displayName': this.props.profile.displayName || '',
        'email': this.props.profile.email || '',
        'id': this.props.profile.id,
      },
      'dirty': false,
    };
    this.updateProfile = this.updateProfile.bind(this);
  }

  componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    if (this.props.profile !== prevProps.profile ||
      this.props.error !== prevProps.error &&
      !this.props.loading) {
      this.setState((prevState) => {
        return {
          'profile': {
            'displayName': this.props.profile.displayName,
            'email': this.props.profile.email,
            'id': this.props.profile.id,
          },
          'success': prevState.dirty && !this.props.error
        };
      })
    }
  }

  updateProfile = event => {
    event.preventDefault();
    this.props.updateProfile(this.state.profile);
  }

  handleChange = async (event) => {
    const { target } = event;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const { name } = target;
    await this.setState((prevState) => ({
      dirty: true,
      profile: Object.assign(prevState.profile, { [ name ]: value })
    }));
  }

  render() {
    const { profile, success, dirty } = this.state;
    const { loading, error } = this.props;

    return (
      <div className="animated fadeIn">
        <Row>
          <Col lg={6}>
            <Form onSubmit={ (e) => this.updateProfile(e) }>
              <Card>
                <CardHeader>
                  <strong><i className="icon-info pr-1"></i>User Profile</strong>
                </CardHeader>
                <CardBody>
                  <FormGroup>
                    <Label htmlFor="displayName">The First and Last Name</Label>
                    <Input name="displayName" type="text" id="displayName" value={profile.displayName} placeholder="Enter your name" 
                      onChange={ (e) => { this.handleChange(e) }}/>
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="email">Email</Label>
                    <Input name="email" type="text" id="email" value={profile.email} placeholder="Email Address" 
                      onChange={ (e) => { this.handleChange(e) }}/>
                  </FormGroup>
                  {success && (<Alert color="success">Updated Successfully</Alert>)}
                  {error && (<Alert color="danger">Failed to Update</Alert>)}
                  <Row>
                    <Col className="d-flex justify-content-start">
                      <Button
                        tag={Link}
                        to="/changepassword"
                        className="px-4">Change Password</Button>
                    </Col>
                    <Col className="d-flex justify-content-end">
                      <Button
                        disabled={!dirty}
                        color="primary"
                        className="px-4">Update</Button>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Form>

          </Col>
        </Row>
      </div>
    )
  }
}

export default Profile;
