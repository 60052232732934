import axios from "axios";
import { ShowErrorAlert } from "../views/Common/GlobalSession";
export const getThoughtPadApi = async (obj) => {
    
    try {
        // Get the authentication token
     
    // headers: {"Authorization" : `Bearer ${tokenStr}`}
        const response =  await axios({
                    method: 'POST',
                    url: process.env.REACT_APP_API_END_POINT+'/getThoughtpads',
                    data: {"entityId":obj.entityId},  
                    //config: { headers: {'Content-Type': 'multipart/form-data' }}
                    })
                    .then(Response => Response)
                    .then(res => {
                      //console.log(res.data);
                       return res.data;
                    })
        return response;           
    } catch (e) {
        console.log(e);
    }
  };
  export const createThoughtPadApi = async (obj) => {
    
    try {
        // Get the authentication token
     
    // headers: {"Authorization" : `Bearer ${tokenStr}`}
        const response =  await axios({
                    method: 'POST',
                    url: process.env.REACT_APP_API_END_POINT+'/insertThoughtpad',
                    data:obj,  
                    //config: { headers: {'Content-Type': 'multipart/form-data' }}
                    })
                    .then(Response => Response)
                    .then(res => {
                      //console.log(res.data);
                       return res.data;
                    })
        return response;           
    } catch (e) {
        console.log(e);
    }
  };
  export const getEntityPersonalApi = async (obj) => {
    if(obj.entityId!==null){
        try {
            var randomnumber=Math.floor(Math.random() * 100);
            // Get the authentication token
        // var entityId=sessionStorage.getItem("USER_ENTITY_ID");
        // headers: {"Authorization" : `Bearer ${tokenStr}`}
            const response =  await axios({
                        method: 'GET',
                        url: process.env.REACT_APP_API_END_POINT+'/getEntityPersonal/'+obj.entityId+"?v="+randomnumber,
                        
                        //config: { headers: {'Content-Type': 'multipart/form-data' }}
                        })
                        .then(Response => Response)
                        .then(res => {
                          //console.log(res.data);
                          if (res.data&&res.data.header&&res.data.header.errorCode === 4||res.data&&res.data.header&&res.data.header.errorCode === '4') { 
                            ShowErrorAlert(res.data.header.errorCode, res.data.header.errorMsg);
                            return false;
                          }
                           return res.data.EntityBio;
                        })
            return response;           
        } catch (e) {
            console.log(e);
        }
    }
  };
  