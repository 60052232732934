import {all, fork, take, takeLatest, call, put, select} from "redux-saga/effects";
import {REQUEST_GROWTH_PLAN_DETAILS,REQUEST_UPDATE_GOAL,UPDATE_GrowthPlan,CREATE_GrowthPlan,Fetch_Action_Tag_Lst, Fetch_Goal_Tag_Lst,
  FETCH_Category_Lst,receiveCategoryAction,receiveGoalTagAction,receiveActionTagAction,receiveCreateGrowthPlanAction,responseUpdateGoalAction,
  responseGrowthPlanDetailsAction,receiveUpdateGrowthPlanAction,REQUEST_UPDATE_ACTION,responseUpdateAction,REQUEST_UPDATE_GOALQUESTION_ACTION,
  responseUpdateGoalQuestionAction,REQUEST_UPDATE_WeekTemplate_ACTION,responseUpdateWeekTemplateAction,REQUEST_UPDATE_CUSTOMISEDTAG_ACTION,
  responseUpdateCustomizedTagAction } from "../actions/growthPlanAction";
import { getCategoryApi,getTagsApi,createGrowthPlanApi,updateGrowthPlanApi,updateGoalApi,growthPlanDetailsApi,updateActionApi, 
  updateGoalQuestionApi,updateGrowthplanWeekTemplateApi,updateCustomizedTagApi } from "../api/growthPlanApi";
import { checkAuth } from '../actions/auth'

function* fetchCategorySagas(action) {
  //console.log("inside the sagas fetchCategorySagas method");
    try {
    //const tokenStr = yield* checkAuth();
    // do api call
    const response = yield call(getCategoryApi);
    yield put(receiveCategoryAction(response));
  } catch (e) {
    console.log(e);
  }
}

function* fetchGoalTagSagas(action) {
    try {
    //const tokenStr = yield* checkAuth();
    // do api call
    const response = yield call(getTagsApi,action.payload);
    
    yield put(receiveGoalTagAction(response));
  } catch (e) {
    console.log(e);
  }
}

function* fetchActionTagSagas(action) {
    try {
    
    //const tokenStr = yield* checkAuth();
    // do api call
    const response = yield call(getTagsApi,action.payload);
    
    yield put(receiveActionTagAction(response));
  } catch (e) {
    console.log(e);
  }
}



function* createGrowthPlanSagas(action) {
    
  try {
    //const tokenStr = yield* checkAuth();
    // do api call
    const response = yield call(createGrowthPlanApi,action.payload);
    yield put(receiveCreateGrowthPlanAction(response));
  } catch (e) {
    console.log(e);
  }
}

function* updateGrowthPlanSagas(action) {
    
  try {
    //const tokenStr = yield* checkAuth();
    // do api call
    const response = yield call(updateGrowthPlanApi,action.payload);
    yield put(receiveUpdateGrowthPlanAction(response));
  } catch (e) {
    console.log(e);
  }
}

function* updateGoalSagas(action) {
    
  try {
    //const tokenStr = yield* checkAuth();
    // do api call
    const response = yield call(updateGoalApi,{goalObj:action.payload});
    yield put(responseUpdateGoalAction(response));
  } catch (e) {
    console.log(e);
  }
}


function* getGrowthPlanDetailSagas(action) {
    
  try {
    //const tokenStr = yield* checkAuth();
    // do api call
    const response = yield call(growthPlanDetailsApi,action.payload);
    yield put(responseGrowthPlanDetailsAction(response));
  } catch (e) {
    console.log(e);
  }
}


function* updateActionSagas(action) {
    
  try {
    //const tokenStr = yield* checkAuth();
    // do api call
    const response = yield call(updateActionApi,action.payload);
    yield put(responseUpdateAction(response));
  } catch (e) {
    console.log(e);
  }
}


function* updateGoalQuestionSagas(action) {
    
  try {
    //const tokenStr = yield* checkAuth();
    // do api call
    const response = yield call(updateGoalQuestionApi,action.payload);
    yield put(responseUpdateGoalQuestionAction(response));
  } catch (e) {
    console.log(e);
  }
}
function* updateGrowthplanWeekTemplateSagas(action) {
    
  try {
    //const tokenStr = yield* checkAuth();
    // do api call
    const response = yield call(updateGrowthplanWeekTemplateApi,action.payload);
    yield put(responseUpdateWeekTemplateAction(response));
  } catch (e) {
    console.log(e);
  }
}

function* updateCustomizedTagSagas(action) {
    
  try {
    //const tokenStr = yield* checkAuth();
    // do api call
    const response = yield call(updateCustomizedTagApi,action.payload);
    yield put(responseUpdateCustomizedTagAction(response));
  } catch (e) {
    console.log(e);
  }
}

/*
  Alternatively you may use takeLatest.

  Does not allow concurrent fetches of user. If "USER_FETCH_REQUESTED" gets
  dispatched while a fetch is already pending, that pending fetch is cancelled
  and only the latest one will be run.
*/
export default function* growthPlanSagas() {
    yield all([
      takeLatest(FETCH_Category_Lst, fetchCategorySagas),
      takeLatest(Fetch_Goal_Tag_Lst, fetchGoalTagSagas),
      takeLatest(Fetch_Action_Tag_Lst, fetchActionTagSagas),
      takeLatest(CREATE_GrowthPlan, createGrowthPlanSagas),
      takeLatest(UPDATE_GrowthPlan, updateGrowthPlanSagas),
      takeLatest(REQUEST_UPDATE_GOAL, updateGoalSagas),
      takeLatest(REQUEST_GROWTH_PLAN_DETAILS,getGrowthPlanDetailSagas),
      takeLatest(REQUEST_UPDATE_ACTION,updateActionSagas),
      takeLatest(REQUEST_UPDATE_GOALQUESTION_ACTION,updateGoalQuestionSagas),
      takeLatest(REQUEST_UPDATE_WeekTemplate_ACTION,updateGrowthplanWeekTemplateSagas),
      takeLatest(REQUEST_UPDATE_CUSTOMISEDTAG_ACTION,updateCustomizedTagSagas),
      
    ]);
  }
