// import { createStore, applyMiddleware, compose } from 'redux';
// import thunk from 'redux-thunk';
// import rootReducer from '../reducers';

// const initialState = {};

// const middleware = [thunk];
// //const devTools = process.env.NODE_ENV === 'development' ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__() : null
// const composeEnhancers = (process.env.NODE_ENV !== 'production' && typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ )? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : compose;
// const store = createStore(
//   rootReducer,
//   initialState,
//   composeEnhancers(
//     applyMiddleware(...middleware)
//   )
// );


// export default store;


























// import {createLogger} from "redux-logger";
import {createStore, applyMiddleware, compose} from "redux";
import createSagaMiddleware from "redux-saga";

import appReducer from "../reducers";
import sagas from '../sagas/sagas'
import { reducer, config } from 'aws-cognito-redux-saga';
import { initUser } from '../sagas/users'


const cognitoConfig = {
  region: 'us-east-1',
  IdentityPoolId: '',
  UserPoolId: process.env.REACT_APP_COGNITO_POOL_ID,
  ClientId: process.env.REACT_APP_COGNITO_CLIENT_ID,
}

// const cognitoConfig = {
//   region: 'us-east-1',
//   IdentityPoolId: '',
//   UserPoolId: 'us-east-1_EEtXcO2PV',
//   ClientId: '79c2ieo8ubsibcqn7msk19ea89',
// }

// const cognitoConfig = {
//   region: 'us-east-1',
//   IdentityPoolId: '',
//   UserPoolId: 'us-east-1_nXq92EAzt',
//   ClientId: '243p3t8qvev8cjho7hlhpog4ch',
// }
const sagaMiddleware = createSagaMiddleware()

const composeEnhancers = (process.env.NODE_ENV !== 'production' && typeof window === 'object' &&  
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ )?
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : compose;
  
  const initialState = {};

  const store = createStore(
                appReducer,initialState,
                composeEnhancers(applyMiddleware(sagaMiddleware))
              );

sagaMiddleware.run(sagas);

 config.config.set(cognitoConfig);
 store.dispatch(initUser());
 

export default store;