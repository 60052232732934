import React, { Component } from "react";
import {Button, Row, Col, Modal, ModalBody, ModalHeader, FormGroup, Label, ModalFooter} from "reactstrap";
import axios from "axios";
import Input from 'react-validation/build/input';
import Form from 'react-validation/build/form';
import GlobalSession from "../Common/GlobalSession";
import { toast } from "react-toastify";

class AddUser extends Component {
state ={
        firstName: "",
        lastName: "",
        email: "",
        companyId: GlobalSession.companyId,
        companyName: GlobalSession.companyName
 }
handleChange =async(event)=>{
    const { target } = event;
    const value =  target.value;
    const { name } = target;
     await this.setState({
            [name]: value,
        });
    // console.log(event)
 }
//  resendEmail = async (email,companyId) => {
//   var self = this;
//   await axios({
//       method: 'post',
//       url: process.env.REACT_APP_API_END_POINT + '/forgetPassword',
//       data: {
//           email,
//           companyId 
//       }
//   }).then(Response => Response)
//       .then(res => {
//           if (res.data && res.data.result && res.data.result.emailstatus === true) {
//               self.updateEntityActivity(email);
//               this.props.toggleSuccess();
//              this.props.toggle();
//           } else {
//               alert("Email Not Sent - err:" + JSON.stringify(res.data));
//           }
//       }).catch(function (error) {
//       });

// }
updateEntityActivity = async (email) => {
  var loggedInUserEmail = localStorage.getItem("loginUserEmail");
  var inputobj = {
      "auditActivityType": "UA",
      "logType": "I",
      "activity": "RESET",
      "entityId": null,
      "growthPlanId": null,
      "goalTagId": null,
      "actionTagId": null,
      "meetingId": null,
      "page": "",
      "auditText": "",
      "auditValue": null,
      "auditMessage": "Admin(" + loggedInUserEmail + ") has reset the password",
      "auditLink": "",
      "email": email
  };
  await axios({
      method: 'POST',
      url: process.env.REACT_APP_API_END_POINT + '/updateEntityActivity',
      data: inputobj
  }).then(Response => Response)
      .then(res => { return res; })
      .catch(function (error) {
      });

}
 updateEntityUser = async(inputObj) =>{
  
    await axios({
        method: 'post',
        url: process.env.REACT_APP_API_END_POINT + '/updateEntityPersonal',
        data: inputObj
      }).then(Response => Response)
        .then(res => {
          if (res.data.header.errorCode === 1) {
            this.props.toggleSuccess();
            this.props.toggle();
            this.setState({firstName: "",lastName: "",email: ""})
          }
          if (res.data.header.errorMsg === 'Success') {
            this.props.toggleSuccess();
            this.props.toggle();
            this.setState({firstName: "",lastName: "",email: ""})
          }
        }).catch(function (error) {
        });
 }
updateUserDetails = () => {
    var inputobj = {
        action: "INSERT",
        firstName: this.state.firstName,
        lastName: this.state.lastName,
        email: this.state.email,
        companyId:this.state.companyId
    }
    var isvalidated = true;
        var errorfield = "";
        if (this.state.firstName == null || this.state.firstName == "" || this.state.firstName == undefined || this.state.firstName.trim().length == 0) {
            errorfield = "First Name"; isvalidated = false;
        } else if (this.state.lastName == null || this.state.lastName == "" || this.state.lastName == undefined || this.state.lastName.trim().length == 0) {
            errorfield = "Last Name"; isvalidated = false;
        } else if (this.state.email == null || this.state.email == "" || this.state.email == undefined || this.state.email.trim().length == 0) {
            errorfield = "Email"; isvalidated = false;
        }

        if (isvalidated) {
            // alert(this.state.companyId);
            this.updateEntityUser(inputobj);
        } else {
            // alert("Enter " + errorfield);
            toast.error(`Enter ${ errorfield}`)
        }
    // console.log(this.state)
 }
 render(){

  return (
    <Modal
      isOpen={this.props.success}
      toggle={() => this.props.toggle()}
      className={"modal-primary"}
      size="xl"
      centered={true}
      backdrop={"static"}
      keyboard={false}
    >
      <ModalHeader >Add User</ModalHeader>
      <ModalBody className="text-center pt-3">
        <Form
          action=""
          autoComplete="off"
          className="form-User mb-3"
          ref="form"
        >
          <Row className="mb-3 d-flex justify-content-center">
            <Col md="5">
              <FormGroup className="d-flex flex-column flex-md-row align-items-center row">
                <div className="col-md-4">
                  <Label
                    htmlFor="firstName"
                    className="font-weight-bold user-label text-truncate"
                  >
                    First Name <sup className="text-danger">*</sup>
                  </Label>
                </div>
                <div className="col-md-8">
                  <Input
                    name="firstName"
                    className="form-control "
                    required
                    type="text"
                    value={this.state.firstName}
                    onChange={this.handleChange}
                    placeholder=""
                  />
                </div>
              </FormGroup>
            </Col>
          </Row>
          <Row className="mb-3 d-flex justify-content-center">
            <Col md="5">
              <FormGroup className="d-flex flex-column flex-md-row align-items-center row">
                <div className="col-md-4">
                  <Label
                    htmlFor="lastName"
                    className="font-weight-bold user-label text-truncate"
                  >
                    Last Name <sup className="text-danger">*</sup>
                  </Label>
                </div>
                <div className="col-md-8">
                  <Input
                    name="lastName"
                    className="form-control"
                    required
                    type="text"
                    value={this.state.lastName}
                    onChange={this.handleChange}
                    placeholder=""
                  />
                </div>
              </FormGroup>
            </Col>
          </Row>
          <Row className="mb-3 d-flex justify-content-center">
            <Col md="5">
              <FormGroup className="d-flex flex-column flex-md-row align-items-center row">
                <div className="col-md-4">
                  <Label
                    htmlFor="email"
                    className="font-weight-bold user-label"
                  >
                    Email <sup className="text-danger">*</sup>
                  </Label>
                </div>
                <div className="col-md-8">
                  <Input
                    name="email"
                    className="form-control"
                    required
                    type="text"
                    id="email"
                    placeholder=""
                    value={this.state.email}
                    onChange={this.handleChange}
                  />
                </div>
              </FormGroup>
            </Col>
          </Row>

          <Row className="mb-3 d-flex justify-content-center">
            <Col md="5">
              <FormGroup className="d-flex flex-column flex-md-row align-items-center row">
                <div className="col-md-4">
                  <Label
                    htmlFor="company"
                    className="font-weight-bold user-label "
                  >
                    Company
                  </Label>
                </div>
                <div className="col-md-8"> {this.state.companyName} </div>
              </FormGroup>
            </Col>
          </Row>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button size="sm" onClick={this.updateUserDetails} color="danger">
          Save
        </Button>
        
        <Button size="sm" onClick={() => {this.props.toggle();this.setState({firstName: "",lastName: "",email: ""})}} color="primary">
          Close
        </Button>
      </ModalFooter>
    </Modal>
    // <div/>
  );}
};
export default AddUser;
