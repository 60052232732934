import axios from "axios";

import _ from 'lodash';
import { checkAuth } from './auth';
import { reducer, state } from 'aws-cognito-redux-saga';


export const FETCH_Category_Lst= 'FETCH_Category_Lst';
export const RECEIVE_Category_Lst = 'RECEIVE_Category_Lst';


export const getCategoryAction= () => {
  return {
    type: FETCH_Category_Lst
  };
}

export const receiveCategoryAction = data => {
  return {
    type: RECEIVE_Category_Lst,
    payload:data
  };
}

export const Fetch_Goal_Tag_Lst= 'Fetch_Goal_Tag_Lst';
export const RECEIVE_Goal_Tag_Lst = 'RECEIVE_Goal_Tag_Lst';
export const Fetch_Action_Tag_Lst= 'Fetch_Action_Tag_Lst';
export const RECEIVE_Action_Tag_Lst = 'RECEIVE_Action_Tag_Lst';

export const getGoalTagAction= (scope) => {
  return {
    type: Fetch_Goal_Tag_Lst,
    payload:scope
  };
}

export const receiveGoalTagAction = (data)=> {
  return {
      type: RECEIVE_Goal_Tag_Lst,
      payload:data
    };
  }

  export const getActionTagAction= (scope) => {
    return {
      type: Fetch_Action_Tag_Lst,
      payload:scope
    };
  }
  
  export const receiveActionTagAction = (data)=> {
    return {
        type: RECEIVE_Action_Tag_Lst,
        payload:data
      };
    }


export const CREATE_GrowthPlan= 'CREATE_GrowthPlan';
export const RECEIVE_GrowthPlan = 'RECEIVE_GrowthPlan';

export const createGrowthPlanAction = (obj) => {
  return {
    type: CREATE_GrowthPlan,
    payload:obj
  };
}

export const receiveCreateGrowthPlanAction = data => {
  return {
    type: RECEIVE_GrowthPlan,
    payload:data
  };
}

export const UPDATE_GrowthPlan= 'UPDATE_GrowthPlan';
export const RECEIVE_UPDATE_GrowthPlan= 'RECEIVE_UPDATE_GrowthPlan';
export const updateGrowthPlanAction = (obj) => {
  return {
    type: UPDATE_GrowthPlan,
    payload:obj
  };
}
export const receiveUpdateGrowthPlanAction = data => {
  return {
    type: RECEIVE_UPDATE_GrowthPlan,
    payload:data
  };
}


export const REQUEST_UPDATE_GOAL= 'REQUEST_UPDATE_GOAL';
export const RESPONSE_UPDATE_GOAL= 'RESPONSE_UPDATE_GOAL';
export const requestUpdateGoalAction = (obj) => {
  return {
    type: REQUEST_UPDATE_GOAL,
    payload:obj
  };
}
export const responseUpdateGoalAction = data => {
  return {
    type: RESPONSE_UPDATE_GOAL,
    payload:data
  };
}

export const REQUEST_GROWTH_PLAN_DETAILS= 'REQUEST_GROWTH_PLAN_DETAILS';
export const RESPONSE_GROWTH_PLAN_DETAILS= 'RESPONSE_GROWTH_PLAN_DETAILS';
export const requestGrowthPlanDetailsAction = (obj) => {
  return {
    type: REQUEST_GROWTH_PLAN_DETAILS,
    payload:obj
  };
}
export const responseGrowthPlanDetailsAction = data => {
  return {
    type: RESPONSE_GROWTH_PLAN_DETAILS,
    payload:data
  };
}



export const REQUEST_UPDATE_ACTION= 'REQUEST_UPDATE_ACTION';
export const RESPONSE_UPDATE_ACTION= 'RESPONSE_UPDATE_ACTION';
export const requestUpdateAction = (obj) => {
  return {
    type: REQUEST_UPDATE_ACTION,
    payload:obj
  };
}
export const responseUpdateAction = data => {
  return {
    type: RESPONSE_UPDATE_ACTION,
    payload:data
  };
}


export const REQUEST_UPDATE_GOALQUESTION_ACTION= 'REQUEST_UPDATE_GOALQUESTION_ACTION';
export const RESPONSE_UPDATE_GOALQUESTION_ACTION= 'RESPONSE_UPDATE_GOALQUESTION_ACTION';
export const requestUpdateGoalQuestionAction = (obj) => {
  return {
    type: REQUEST_UPDATE_GOALQUESTION_ACTION,
    payload:obj
  };
}
export const responseUpdateGoalQuestionAction = data => {
  return {
    type: RESPONSE_UPDATE_GOALQUESTION_ACTION,
    payload:data
  };
}

export const REQUEST_UPDATE_WeekTemplate_ACTION= 'REQUEST_UPDATE_WeekTemplate_ACTION';
export const RESPONSE_UPDATE_WeekTemplate_ACTION= 'RESPONSE_UPDATE_WeekTemplate_ACTION';
export const requestUpdateWeekTemplateAction = (obj) => {
  return {
    type: REQUEST_UPDATE_WeekTemplate_ACTION,
    payload:obj
  };
}
export const responseUpdateWeekTemplateAction = data => {
  return {
    type: RESPONSE_UPDATE_WeekTemplate_ACTION,
    payload:data
  };
}

export const REQUEST_UPDATE_CUSTOMISEDTAG_ACTION= 'REQUEST_UPDATE_CUSTOMISEDTAG_ACTION';
export const RESPONSE_UPDATE_CUSTOMISEDTAG_ACTION= 'RESPONSE_UPDATE_CUSTOMISEDTAG_ACTION';
export const requestUpdateCustomizedTagAction = (obj) => {
  return {
    type: REQUEST_UPDATE_CUSTOMISEDTAG_ACTION,
    payload:obj
  };
}
export const responseUpdateCustomizedTagAction = data => {
  return {
    type: RESPONSE_UPDATE_CUSTOMISEDTAG_ACTION,
    payload:data
  };
}