import React from 'react';
import Loadable from 'react-loadable'
import LoadingDots from './views/Common/LoadingDots'
import DefaultLayout from './containers/DefaultLayout';

function Loading() {
  return <div> <LoadingDots /></div>;
}
const Dashboard = Loadable({
  loader: () => import('./views/Dashboard'),
  loading: Loading,
});

// const Users = Loadable({
//   loader: () => import('./views/Users/UsersContainer'),
//   loading: Loading,
// });

// const User = Loadable({
//   loader: () => import('./views/User/UserContainer'),
//   loading: Loading,
// });

// const Profile = Loadable({
//   loader: () => import('./views/Profile/ProfileContainer'),
//   loading: Loading,
// });



const UserProfile = Loadable({
  loader: () => import('./views/UserProfile'),
  loading: Loading,
});


// const MeetingMenu = Loadable({
//   loader: () => import('./views/Meetings/MeetingMenu'),
//   loading: Loading,
// });

// const MeetingCalenderView = Loadable({
//   loader: () => import('./views/Meetings/MeetingCalenderView'),
//   loading: Loading,
// });


// const GrowthPlanDetails = Loadable({
//   loader: () => import('./views/GrowthPlanDetails'),
//   loading: Loading,
// });
// const CommunityGrowthPlanDetails = Loadable({
//   loader: () => import('./views/CommunityGrowthPlan/CommunityGrowthPlanDetails'),
//   loading: Loading,
// });

// const Notes = Loadable({
//   loader: () => import('./views/Notes'),
//   loading: Loading,
// });
// const communityNotesComponents = Loadable({
//   loader: () => import('./views/Notes/communityNotesComponents'),
//   loading: Loading,
// });

// const OpenTokVideo = Loadable({
//   loader: () => import('./views/Common/Video/OpenTokVideo'),
//   loading: Loading,
// });

// const JoinNow = Loadable({
//   loader: () => import('./views/Meetings/JoinNow'),
//   loading: Loading
// })

// const ChatSession = Loadable({
//   loader: () => import('./views/Meetings/ChatSession'),
//   loading: Loading
// })

// const DashboardMicroMentor = Loadable({
//   loader: () => import('./views/Dashboard/DashboardMicroMentor'),
//   loading: Loading
// })

const GetHelpDetails = Loadable({
  loader: () => import('./views/Dashboard/GetHelpDetails/index'),
  loading: Loading
})

// const ThoughtPad = Loadable({
//   loader: () => import('./views/ThoughtPad/ThoughtPadMenu'),
//   loading: Loading,
// });

// const MicroMentor = Loadable({
//   loader: () => import('./views/MicroMentor/MicroMentorMenu'),
//   loading: Loading,
// });

// const MicroMentorMeeting = Loadable({
//   loader: () => import('./views/MicroMentor/MicroMentorMenu'),
//   loading: Loading,
// });

// const OpenTokInIframe = Loadable({
//   loader: () => import('./views/Common/Video/OpenTokInIframe'),
//   loading: Loading,
// });
// const profileDetails = Loadable({
//   loader: () => import('./views/Profile/profileDetails'),
//   loading: Loading,
// });

// const TestDatepicker = Loadable({
//   loader: () => import('./views/Dashboard/TestDatepicker'),
//   loading: Loading,
// });

const UserManagement = Loadable({
  loader: () => import('./views/UserList/UserLanding'),
  loading: Loading,
});

const AdminLandingPage = Loadable({
  loader: () => import('./views/UserList/AdminLandingPage'),
  loading: Loading,
});

// const meetingList = Loadable({
//   loader: () => import('./views/UserList/MeetingList'),
//   loading: Loading,
// });

// const meetNow = Loadable({
//   loader: () => import('./views/Dashboard/MeetNow'),
//   loading: Loading,
// });
const CommunityMeetingPage = Loadable({
  loader: () => import('./views/CommunityGrowthPlan/CommunityMeetingPage'),
  loading: Loading,
});

// const GPMeetingHistory = Loadable({
//   loader: () => import('./views/Meetings/GrowthPlanMeetingHistory'),
//   loading: Loading,
// });
// const GoalLanding = Loadable({
//   loader: () => import('./views/Admin/GoalManagement'),
//   loading: Loading,
// });
const Reporting = Loadable({
  loader: () => import('./views/Reporting'),
  loading: Loading,
});
const Analytics = Loadable({
  loader: () => import('./views/Analytics/AnalyticsReport'),
  loading: Loading,
});
const AnalyticsAdmin = Loadable({
  loader: () => import('./views/Analytics/AnalyticsAdmin'),
  loading: Loading,
});

const CompanyManagement = Loadable({
  loader: () => import('./views/Admin/companyList'),
  loading: Loading,
});

const TeamplateManagement = Loadable({
  loader: () => import('./views/Admin/templateManagement'),
  loading: Loading,
});
const CronManagemet = Loadable({
  loader: () => import('./views/Admin/CronManagement'),
  loading: Loading,
});
const UserPasswordChange = Loadable({
  loader: () => import('./views/UserProfile/UserPasswordChange'),
  loading: Loading,
});
const Training = Loadable({
  loader: () => import('./views/Admin/Training'),
  loading: Loading,
});
// const NoteUI = Loadable({
//   loader: () => import('./views/CommunityGrowthPlan/NotesSubcommetsUI'),
//   loading: Loading,
// });
// https://github.com/ReactTraining/react-router/tree/master/packages/react-router-config
const routes = [
  // { path: '/login', exact: true, name: 'Home', component: DefaultLayout },
  { path: '/adminLandingPage', exact: true, name: 'Admin Landing Page', component: AdminLandingPage },
  { path: '/userManagement', exact: true, name: 'User Management', component: UserManagement },
  // { path: '/GoalManagement', exact: true, name: 'User Management', component: GoalLanding },
  // { path: '/meetingList', exact: true, name: 'Meeting Management', component: meetingList },
  { path: '/dashboard', exact: true, name: 'Dashboard', component: Dashboard, protected: true },
  // { path: '/users', exact: true, name: 'Users', component: Users, protected: true },
  // { path: '/profile', exact: true, name: 'Profile', component: Profile, protected: true },
  { path: '/userprofile', exact: true, name: 'User Profile', component: UserProfile, protected: true },
  // { path: '/meetings', exact: true, name: 'Meetings', component: MeetingMenu, protected: true },
  // { path: '/meeting/calenderview', exact: true, name: 'Meeting Calendar View', component: MeetingCalenderView, protected: true },
  // { path: '/growth-plan-details', exact: true, name: 'Growth Plan Details', component: GrowthPlanDetails, protected: true },
  // { path: '/community-growth-plan-details', exact: true, name: 'Community Growth Plan Details', component: CommunityGrowthPlanDetails, protected: true },
  { path: '/getHelp', exact: true, name: "GetHelpDetails", component: GetHelpDetails, protected: true },
  // { path: '/notes', exact: true, name: 'Notes', component: Notes, protected: true },
  // { path: '/communityNotesComponents', exact: true, name: 'communityNotesComponents', component: communityNotesComponents, protected: true },
  // { path: '/open-tok-video', exact: true, name: 'OpenTokVideo', component: OpenTokVideo, protected: true },
  // { path: '/joinnow', exact: true, name: 'Join Now', component: JoinNow, protected: true },
  // { path: '/chatsession', exact: true, name: 'Chat Session', component: ChatSession, protected: true },
  // { path: '/users/:id', exact: true, name: 'User Details', component: User, protected: true, protected: true },
  // { path: '/dashboard-micro-mentor', exact: true, name: 'Micro-Mentor', component: DashboardMicroMentor, protected: true },
  // { path: '/thought-pad', exact: true, name: 'Thought Pad', component: ThoughtPad, showSearch: true },
  // { path: '/micromentor', exact: true, name: 'Micro Menter', component: MicroMentor, protected: true },
  // { path: '/micromentermeeting', exact: true, name: 'Micro Menter Meeting', component: MicroMentorMeeting, protected: true },
  // { path: '/videosessioninIframe', exact: true, name: 'Video Session In Iframe', component: OpenTokInIframe, protected: true },
  // { path: '/profileDetails', exact: true, name: 'Profile Details', component: profileDetails, protected: true },
  // { path: '/testDatepicker', exact: true, name: 'Date Picker Test', component: TestDatepicker, protected: true },
  // { path: '/meetNow', exact: true, name: 'Meet Now', component: meetNow },
  // { path: '/communityMeetingPage', exact: true, name: 'Community Meeting Page', component: CommunityMeetingPage },
  // { path: '/gpMeetingHistory', exact: true, name: 'History', component: GPMeetingHistory, protected: true },
  { path: '/reporting', exact: true, name: 'Reporting', component: Reporting, protected: true },
  { path: '/analytics', exact: true, name: 'Analytics', component: Analytics, protected: true },
  { path: '/analyticsAdmin', exact: true, name: 'AnalyticsAdmin', component: AnalyticsAdmin, protected: true },
  { path: '/work-plan', exact: true, name: 'WorkPlan', component: CommunityMeetingPage, protected: true },
  { path: '/companymanagement', exact: true, name: 'CompanyMangement', component: CompanyManagement, protected: true },
  { path: '/templatemanagement', exact: true, name: 'TeamplateManagement', component: TeamplateManagement, protected: true },
  { path: '/cronmanagement', exact: true, name: 'CronManagemet', component: CronManagemet, protected: true },
  { path: '/userchangepassword', exact: true, name: 'User Password Changes', component: UserPasswordChange, protected: true },
  { path: '/training/:type', exact: true, name: 'Training', component: Training, protected: true },
  // { path: '/noteui', exact: true, name: 'NoteUI', component: NoteUI, protected: true }
];

export default routes;
