import React, { Fragment, useEffect, useState } from "react";
import axios from "axios";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getProfileDetails } from "../../actions/dashboardAction";
import { getMeetingListAction } from "../../actions/getMeetingListAction";
import { CNavbarBrand } from "@coreui/react";
import UserImage from "../../views/User/UserImage";
import logo from "../../assets/img/brand/login-logo.png";
import GlobalSession from "../../views/Common/GlobalSession";
import CompanyAndUserSelect from "../../views/CommunityGrowthPlan/CompanyAndUserSelect";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Modal,
  ModalBody,
} from "reactstrap";
import NewGoalPlanPopUpMobile from "../../views/CommunityGrowthPlan/NewGoalPlanPopUpMobile";
const MobileHeader = (props) => {
  const { auth, profile, signOut, ...attributes } = props;
  let { company_logo } = props.profileobj;
  const [toggleOpen, setToggleOpen] = useState(false);
  const [userList, setUserList] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [addPlanPopUp, setAddPlanPopUp] = useState(false);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  useEffect(() => {
    getuserList();
  }, []);
  const getuserList = async () => {
    await axios({
      method: "POST",
      url: process.env.REACT_APP_API_END_POINT + "/getPicklist",
      data: {
        picklistType: "SEARCH_USER",
        entityId: GlobalSession.entityId,
      },
    })
      .then((Response) => Response)
      .then((res) => {
        let data = res.data.picklist.sort((a, b) => {
          if (a.firstname.trim() < b.firstname.trim()) {
            return -1;
          }
          if (a.firstname.trim() > b.firstname.trim()) {
            return 1;
          }
          return 0;
        });
        setUserList(data);
      })
      .catch((e) => console.log(e));
  };
  const updateEntityActivity = async () => {
    var inputobj = {
      auditActivityType: "UA",
      logType: "I",
      activity: "LOGOUT",
      entityId: GlobalSession.entityId,
      growthPlanId: null,
      goalTagId: null,
      actionTagId: null,
      meetingId: null,
      page: "",
      auditText: "",
      auditValue: null,
      auditMessage: "User has Logged Out",
      auditLink: "",
      email: null,
    };
    await axios({
      method: "POST",
      url: process.env.REACT_APP_API_END_POINT + "/updateEntityActivity",
      data: inputobj,
    })
      .then((Response) => Response)
      .then((res) => {
        return res;
      })
      .catch(function (error) {});
  };
  const logOut = async () => {
    await updateEntityActivity();
    signOut();
  };
  const toggle = () => setToggleOpen((prevState) => !prevState);
  const handleSearchPopup = () => {
    setIsOpen((prevState) => !prevState);
  };
  const loginHandler = async (tokenId) => {
    var randomnumber = Math.floor(Math.random() * 100);
    await axios({
      method: "GET",
      url:
        process.env.REACT_APP_API_END_POINT +
        "/getEntityByToken/" +
        tokenId +
        "?v=" +
        randomnumber,
    })
      .then((Response) => Response)
      .then(async (res) => {
        if (sessionStorage.getItem("USER_ENTITY_ID") != null) {
          sessionStorage.removeItem("USER_ENTITY_ID");
        }
        if (localStorage.getItem("USER_ENTITY_ID") != null) {
          localStorage.removeItem("USER_ENTITY_ID");
        }
        if (res.data.header.errorCode > 0) {
          alert(res.data.header.errorMsg);
        } else {
          localStorage.setItem(
            "USER_ENTITY_ID",
            res.data.EntityUser && res.data.EntityUser.entityId
          );
          localStorage.setItem(
            "USER_COMPANY_ID",
            res.data.EntityUser && res.data.EntityUser.companyId
          );
          localStorage.setItem(
            "USER_COMPANY_NAME",
            res.data.EntityUser && res.data.EntityUser.companyName
          );

          localStorage.setItem("tokenId", tokenId);
          sessionStorage.setItem(
            "USER_ENTITY_ID",
            res.data.EntityUser && res.data.EntityUser.entityId
          );

          sessionStorage.setItem(
            "IsPhotoUploadComplete",
            res.data.EntityUser && res.data.EntityUser.isPhotoUploadComplete
              ? res.data.EntityUser.isPhotoUploadComplete
              : ""
          );
          sessionStorage.setItem(
            "IsInterestsComplete",
            res.data.EntityUser && res.data.EntityUser.isInterestsComplete
              ? res.data.EntityUser.isInterestsComplete
              : ""
          );
          sessionStorage.setItem(
            "IsAddExpereinceComplete",
            res.data.EntityUser && res.data.EntityUser.isAddExpereinceComplete
              ? res.data.EntityUser.isAddExpereinceComplete
              : ""
          );
          sessionStorage.setItem(
            "IsCreateGrowthPlanComplete",
            res.data.EntityUser &&
              res.data.EntityUser.isCreateGrowthPlanComplete
              ? res.data.EntityUser.isCreateGrowthPlanComplete
              : ""
          );

          localStorage.setItem(
            "IsPhotoUploadComplete",
            res.data.EntityUser && res.data.EntityUser.isPhotoUploadComplete
              ? res.data.EntityUser.isPhotoUploadComplete
              : ""
          );
          localStorage.setItem(
            "IsInterestsComplete",
            res.data.EntityUser && res.data.EntityUser.isInterestsComplete
              ? res.data.EntityUser.isInterestsComplete
              : ""
          );
          localStorage.setItem(
            "IsAddExpereinceComplete",
            res.data.EntityUser && res.data.EntityUser.isAddExpereinceComplete
              ? res.data.EntityUser.isAddExpereinceComplete
              : ""
          );
          localStorage.setItem(
            "IsCreateGrowthPlanComplete",
            res.data.EntityUser &&
              res.data.EntityUser.isCreateGrowthPlanComplete
              ? res.data.EntityUser.isCreateGrowthPlanComplete
              : ""
          );

          window.IsPhotoUploadComplete =
            res.data.EntityUser && res.data.EntityUser.isPhotoUploadComplete
              ? res.data.EntityUser.isPhotoUploadComplete
              : "0";
          window.IsInterestsComplete =
            res.data.EntityUser && res.data.EntityUser.isInterestsComplete
              ? res.data.EntityUser.isInterestsComplete
              : "0";
          window.IsAddExpereinceComplete =
            res.data.EntityUser && res.data.EntityUser.isAddExpereinceComplete
              ? res.data.EntityUser.isAddExpereinceComplete
              : "0";
          window.IsCreateGrowthPlanComplete =
            res.data.EntityUser &&
            res.data.EntityUser.isCreateGrowthPlanComplete
              ? res.data.EntityUser.isCreateGrowthPlanComplete
              : "0";

          localStorage.removeItem("logingByPass");

          sessionStorage.setItem("SHOW_USERPROFILE", true);
          GlobalSession.entityId =
            res.data.EntityUser && res.data.EntityUser.entityId;
          GlobalSession.companyId =
            res.data.EntityUser && res.data.EntityUser.companyId;
          GlobalSession.companyName =
            res.data.EntityUser && res.data.EntityUser.companyName;
          if (res.data.EntityUser.isAccountSetupComplete === 1) {
            localStorage.setItem("UserType", "ExistingUser");
            sessionStorage.setItem("UserType", "ExistingUser");
            window.UserType = "ExistingUser";
            window.location.href = "#/mobile/dashboard";
            window.location.reload();
          }
        }
      });
  };
  const handleHome = () => {
    if (localStorage.getItem("logingByPass") !== null) {
      loginHandler(JSON.parse(localStorage.getItem("user_info")).tokenId);
    } else {
      navigate("/mobile/dashboard");
    }
  };

  return (
    <Fragment>
      <div className="w-100 d-flex justify-content-between align-items-center">
        <CNavbarBrand as={Link} href="#/mobile/dashboard">
          <img
            src={company_logo ? company_logo : logo}
            alt="OnUP Logo"
            style={{
              width: 260,
              height: "auto",
              objectFit: "contain",
              maxHeight: "100%",
            }}
          />
        </CNavbarBrand>
        <div className="d-flex" style={{ columnGap: "8px" }}>
          {/* <img
          src={logout}
          className="mw-100 mh-100"
          alt="logout"
          width="48px"
          height="48px"
          onClick={() => logOut()}
        /> */}
          <Dropdown
            isOpen={toggleOpen}
            toggle={toggle}
            className="profile-dropdown"
          >
            <DropdownToggle style={{ border: "none", padding: "0" }}>
              <UserImage />
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem onClick={() => handleHome()}>
                <i class="fa fa-home" aria-hidden="true"></i> Home
              </DropdownItem>
              {(profile.securityToken === 1 ||
                profile.securityToken === 2 ||
                localStorage.getItem("logingByPass") !== null) && (
                <DropdownItem onClick={() => handleSearchPopup()}>
                  <i class="fa fa-search" aria-hidden="true"></i> search User or
                  Company
                </DropdownItem>
              )}
              {pathname === "/mobile/dashboard" && (
                <DropdownItem onClick={() => setAddPlanPopUp(true)}>
                  <i class="fa fa-plus" aria-hidden="true"></i> Add Plan
                </DropdownItem>
              )}
              <DropdownItem onClick={() => logOut()}>
                <i className="fa fa-sign-out"></i> Logout
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </div>
      </div>
      <Modal
        isOpen={isOpen}
        size={"sm"}
        centered={true}
        toggle={handleSearchPopup}
      >
        <ModalBody>
          <CompanyAndUserSelect
            profile={profile}
            userList={userList}
            fullWidth={true}
            mobile={true}
          />
        </ModalBody>
      </Modal>
      {addPlanPopUp && (
        <NewGoalPlanPopUpMobile
          isOpen={addPlanPopUp}
          onClose={() => setAddPlanPopUp(false)}
        />
      )}
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    profileobj: state.profile.data,
    meetingObj: state.getMeetingList.getMeetingListDetailsObj,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ getProfileDetails, getMeetingListAction }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(MobileHeader);
// export default MobileHeader
