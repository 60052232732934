import React, { Component } from 'react';
// import { Link } from 'react-router-dom'
import {
  Alert, Button, Card, CardBody, CardHeader, Col,
  Row, Table, FormGroup, Input, Label, Form
} from 'reactstrap';
import axios from 'axios'
// import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
// import moment from 'moment-timezone';
import GlobalSession, { ShowErrorAlert } from "../Common/GlobalSession";
// import { Redirect, withRouter } from "react-router-dom";
import { removeTimeZoneForDatePicker, getDateWithoutTime } from '../Common/CustomDatePicker/TimeZoneBasedDateFormat';
// import LoginLogo from '../../assets/img/brand/login-logo.png';

const Checkbox = props => (
  <input type="checkbox" {...props} />
)
class Profiles extends Component {
  constructor(props) {
    super(props);
    this.state = {
      'profiles': {
        'action': '',
        'entityId': GlobalSession.entityId,
        'actorId': '',
        'firstName': '',
        'lastName': '',
        'alias': '',
        'email': '',
        'dob': '',
        'phone': '',
        'imageUri': '',
        'statusId': '',
        'title': '',
        'companyId': '',
        'departmentId': '',
        'city': '',
        'countryId': '',
        'timezoneId': '',
        'genderId': '',
        'isTermsSigned': 0
      },
      dateOfBirth: null,
      responseDataDEPARTMENT: [],
      responseDataCOMPANY: [],
      responseDataGENDER: [],
      responseDataTIMEZONE: [],
      responseDataCOUNTRY: [],
      responseDataSTATE: [],
      acceptLicense: false,
      validationMsg: ""
      // 'profiles': {
      //   'firstName': this.props.profile.firstName || '',
      //   'lastName': this.props.profile.lastName || '',
      //   'email': this.props.profile.email || '',
      //   'id': this.props.profile.id,
      // },
      // 'dirty': false,
    };
    //this.updateProfiles = this.updateProfiles.bind(this);
  }

  // componentDidUpdate(prevProps) {
  //   // Typical usage (don't forget to compare props):
  //   if (this.props.profile !== prevProps.profile ||
  //     this.props.error !== prevProps.error &&
  //     !this.props.loading) {
  //     this.setState((prevState) => {
  //       return {
  //         'profiles': {
  //           'firstName': this.props.profile.firstName,
  //           'lastName': this.props.profile.lastName || '',
  //           'email': this.props.profile.email,
  //           'id': this.props.profile.id,
  //           'phone': this.props.profile.phone,
  //           'title': this.props.profile.title,
  //           'company': this.props.profile.company,
  //           'department': this.props.profile.department,
  //           'city': this.props.profile.city,
  //           'country': this.props.profile.country,
  //           'time': this.props.profile.time,
  //           'birth': this.props.profile.birth,
  //           'gender': this.props.profile.gender
  //         },
  //         'success': prevState.dirty && !this.props.error
  //       };
  //     })
  //   }
  // }

  // updateProfiles = event => {
  //   event.preventDefault();
  //   this.props.updateProfiles(this.state.profiles);
  // }

  // handleChange = async (event) => {
  //   const { target } = event;
  //   const value = target.type === 'checkbox' ? target.checked : target.value;
  //   const { name } = target;
  //   await this.setState((prevState) => ({
  //     dirty: true,
  //     profiles: Object.assign(prevState.profiles, { [name]: value })
  //   }));
  // }

  componentDidMount() {
    this.getDepartment();
    this.getCompany();
    this.getGENDER();
    this.getCOUNTRY();
    this.getState();
    this.getTIMEZONE();
    this.getPersonalDetails();
  }
  getPersonalDetails = () => {
    var randomnumber = Math.floor(Math.random() * 100);
    axios({
      method: 'get',
      url: process.env.REACT_APP_API_END_POINT + '/getEntityPersonal/' + GlobalSession.entityId + "?v=" + randomnumber,
    }).then(Response => Response)
      .then(res => {
        if (res.data&&res.data.header&&res.data.header.errorCode === 4||res.data&&res.data.header&&res.data.header.errorCode === '4') { 
          ShowErrorAlert(res.data.header.errorCode, res.data.header.errorMsg);
          return false;
        }
        var dob = null;
        if (res.data.EntityBio[0].dob != null && res.data.EntityBio[0].dob != undefined && res.data.EntityBio[0].dob != "" && res.data.EntityBio[0].dob != "0000-00-00") {
          dob = res.data.EntityBio[0].dob;
        }
        this.setState({
          profiles: res.data.EntityBio[0],
          dateOfBirth: dob
        })
      }).catch(function (error) {
      });
  }

  getPickList = async (type) => {
    axios({
      method: 'post',
      url: process.env.REACT_APP_API_END_POINT + '/getPicklist',
      data: {
        "picklistType": type
      }
    }).then(Response => Response)
      .then(res => {
        if (type == "DEPARTMENT") {
          this.setState({
            responseDataDEPARTMENT: res.data.picklist
          })
        } else if (type == "COMPANY") {
          this.setState({
            responseDataCOMPANY: res.data.picklist
          })
        } else if (type == "GENDER") {
          this.setState({
            responseDataGENDER: res.data.picklist
          })
        } else if (type == "COUNTRY") {
          this.setState({
            responseDataCOUNTRY: res.data.picklist
          })
        } else if (type == "TIMEZONE") {
          this.setState({
            responseDataTIMEZONE: res.data.picklist
          })
        } else if (type = "STATE") {
          this.setState({
            responseDataSTATE: res.data.picklist
          })
        }

      }).catch(function (error) {
      });
  }


  getDepartment = async () => {
    await this.getPickList("DEPARTMENT");
  }
  getCompany = async () => {
    await this.getPickList("COMPANY");
  }
  getGENDER = async () => {
    await this.getPickList("GENDER");
  }
  getCOUNTRY = async () => {
    await this.getPickList("COUNTRY");
  }
  getTIMEZONE = async () => {
    await this.getPickList("TIMEZONE");
  }
  getState = async () => {
    await this.getPickList("STATE");
  }
  updateProfiles = () => {
    if (this.props.profile.isAccountSetupComplete == 0) {
      // if(!this.state.acceptLicense){
      //     this.setState({validationMsg:"click to accept license terms"});
      //   return false;
      // }
      this.state.profiles.isTermsSigned = 1;
    }
    var self = this;
    this.state.profiles.action = this.state.profiles.action = 'UPDATE'
    this.state.profiles.dob = this.state.profiles.dob = this.state.dateOfBirth
    this.state.profiles.timezoneId = this.state.profiles.timeZoneId = this.state.profiles.timezoneId
    var actorId = 1;
    if (this.state.profiles.isMentor == "Yes") {
      actorId = 2;
    }
    this.state.profiles.actorId = actorId

    delete this.state.profiles.lastUpdated;
    delete this.state.profiles.isMentor;
    delete this.state.profiles.status;
    delete this.state.profiles.timezone;
    delete this.state.profiles.country;
    delete this.state.profiles.department;
    delete this.state.profiles.company;
    delete this.state.profiles.imageUri;
    delete this.state.profiles.state;
    // this.state.profiles.dob = getDateWithoutTime(this.state.profiles.dob);
     this.setState({profiles:{...this.state.profiles, dob:getDateWithoutTime(this.state.profiles.dob)}})//moment(this.state.profiles.dob, "YYYY-MM-DD[T]HH:mm:ss.SSSZZ");
    //this.state.profiles.dob = this.state.profiles.dob.add(5.5, 'hour');
    axios({
      method: 'post',
      url: process.env.REACT_APP_API_END_POINT + '/updateEntityPersonal',
      data: this.state.profiles
    }).then(Response => Response)
      .then(res => {
        if (res.data.header.errorMsg === 'Success') {
          self.props.init();
          self.props.toggleSuccess('dashboardLoad');
          //this.props.history.push('/dashboard'); 
        }
      }).catch(function (error) {
      });
  }
  handleChange(event) {
    this.tempInput = this.state.profiles;
    const { name, value } = event.target;
    this.tempInput[name] = value;
    this.setState({ input: this.tempInput });
    if (name === 'birth') {
      this.setState({
        dateOfBirth: value
      })
    }
  }
  handleChng = dateOfBirth => this.setState({ dateOfBirth })
  acceptLicense = () => {
    // alert("hello");
    var acceptLicense = this.state.acceptLicense;

    if (acceptLicense == false) {
      this.setState({ acceptLicense: !acceptLicense, validationMsg: "" })
    } else {
      this.setState({ acceptLicense: !acceptLicense })
    }

  }
  render() {
    //    const CustomInput = (props) => {
    //      return (<input onClick={props.onClick} value={props.value} type="text" className="form-control" readOnly={true}/>)
    //    return (<input onClick={props.onClick} value={props.value} type="text" className="form-control"/>)
    // }
    console.log(this.props.profile);
    let today = new Date();
    const { profiles, success, dirty } = this.state;
    const { loading, error } = this.props;
    console.log("inside profiles");
    console.log(profiles);
    return (
      <Card>
        <CardBody>
          <Form>
            <h3 className="m-0 mb-4">About You</h3>

            <Row className="mb-3">
              <Col md="6">
                <FormGroup className="d-flex flex-column flex-md-row align-items-center profile-details">
                  <Label htmlFor="firstName" className="font-weight-bold">First <br /> Name</Label>
                  <Input name="firstName" type="text" id="firstName" value={profiles.firstName} placeholder="" onChange={(e) => { this.handleChange(e) }} />
                </FormGroup>
              </Col>
              <Col md="6">
                <FormGroup className="d-flex flex-column flex-md-row align-items-center profile-details">
                  <Label htmlFor="lastName" className="font-weight-bold">Last <br /> Name</Label>
                  <Input name="lastName" type="text" id="lastName" value={profiles.lastName} placeholder="" onChange={(e) => { this.handleChange(e) }} />
                </FormGroup>
              </Col>
              <Col md="6">
                <FormGroup className="d-flex flex-column flex-md-row align-items-center profile-details">
                  <Label htmlFor="email" className="font-weight-bold">Email</Label>
                  <Label className="email-label">{localStorage.getItem("loginUserEmail")}</Label>
                  {/* <Input name="email" type="text" id="email" value={profiles.email} placeholder="" readonly /> */}
                  {/* <input type="text"  value={profiles.email} readonly></input> */}
                </FormGroup>
              </Col>

              <Col md="6">
                <FormGroup className="d-flex flex-column flex-md-row align-items-center profile-details">
                  <Label htmlFor="phone" className="font-weight-bold">Phone</Label>
                  <Input name="phone" type="text" id="phone" value={profiles.phone} placeholder="" onChange={(e) => { this.handleChange(e) }} />
                </FormGroup>
              </Col>
              <Col md="6">
                <FormGroup className="d-flex flex-column flex-md-row align-items-center profile-details">
                  <Label htmlFor="title" className="font-weight-bold">Title</Label>
                  <Input name="title" type="text" id="title" value={profiles.title} placeholder="" onChange={(e) => { this.handleChange(e) }} />
                </FormGroup>
              </Col>
              <Col md="6">
                <FormGroup className="d-flex flex-column flex-md-row align-items-center profile-details">
                  <Label htmlFor="landline" className="font-weight-bold">Landline</Label>
                  <Input name="landline" type="text" id="landline" value={profiles.landline} placeholder="" onChange={(e) => { this.handleChange(e) }} />
                </FormGroup>
              </Col>
              <Col md="6">
                <FormGroup className="d-flex flex-column flex-md-row align-items-center profile-details">
                  <Label htmlFor="company" className="font-weight-bold">Company</Label>
                  <Label className="email-label">{profiles.company}</Label>
                  {/* <select type="select" name="companyId" id='companyId' className="form-control" value={profiles.companyId} onChange={(e) => { this.handleChange(e) }}>
                    <option value="0">Please select company</option>
                    {(this.state.responseDataCOMPANY !== undefined && this.state.responseDataCOMPANY.length > 0 ?
                      this.state.responseDataCOMPANY.map(function (responseDataCOMPANY) {
                        return <option key={responseDataCOMPANY.id}
                          value={responseDataCOMPANY.id}>{responseDataCOMPANY.name}</option>;
                      })
                      : "")}
                  </select> */}
                </FormGroup>
              </Col>
              <Col md="6">
                <FormGroup className="d-flex flex-column flex-md-row align-items-center profile-details">
                  <Label htmlFor="department" className="font-weight-bold">Department</Label>
                  {/* <Label className="email-label d-block w-100 text-center">{profiles.department}</Label> */}
                  <select type="select" name="departmentId" id='departmentId' className="form-control" value={profiles.departmentId} onChange={(e) => { this.handleChange(e) }}>
                    <option value="0">Please select department</option>
                    {(this.state.responseDataDEPARTMENT !== undefined && this.state.responseDataDEPARTMENT.length > 0 ?
                      this.state.responseDataDEPARTMENT.map(function (responseDataDEPARTMENT) {
                        return <option key={responseDataDEPARTMENT.id}
                          value={responseDataDEPARTMENT.id}>{responseDataDEPARTMENT.name}</option>;
                      })
                      : "")}
                  </select>
                </FormGroup>
              </Col>
            </Row>
            <h3 className="m-0 mb-4">Your Location</h3>
            <Row className="mb-3">
              <Col md="6">
                <FormGroup className="d-flex flex-column flex-md-row align-items-center profile-details">
                  <Label htmlFor="country" className="font-weight-bold">Country</Label>
                  {/* <Input name="country" type="text" id="country" value={profiles.country} placeholder="" onChange={(e) => { this.handleChange(e) }} /> */}
                  <select type="select" name="countryId" id='countryId' className="form-control" value={profiles.countryId} onChange={(e) => { this.handleChange(e) }}>
                    <option value="0">Please select country</option>
                    {(this.state.responseDataCOUNTRY !== undefined && this.state.responseDataCOUNTRY.length > 0 ?
                      this.state.responseDataCOUNTRY.map(function (responseDataCOUNTRY) {
                        return <option key={responseDataCOUNTRY.id}
                          value={responseDataCOUNTRY.id}>{responseDataCOUNTRY.name}</option>;
                      })
                      : "")}
                  </select>
                </FormGroup>
              </Col>
              <Col md="6">
                <FormGroup className="d-flex flex-column flex-md-row align-items-center profile-details">
                  <Label htmlFor="state" className="font-weight-bold">State</Label>
                  <select type="select" name="stateId" id='stateId' className="form-control" value={profiles.stateId} onChange={(e) => { this.handleChange(e) }}>
                    <option value="0">Please select State</option>
                    {/* <option value="nj">NJ</option>
                    <option value="tn">TN</option> */}
                    {(this.state.responseDataSTATE !== undefined && this.state.responseDataSTATE.length > 0 ?
                      this.state.responseDataSTATE.map(function (responseDataSTATE) {
                        return <option key={responseDataSTATE.id}
                          value={responseDataSTATE.id}>{responseDataSTATE.name}</option>;
                      })
                      : "")}
                  </select>
                </FormGroup>
              </Col>
              <Col md="6">
                <FormGroup className="d-flex flex-column flex-md-row align-items-center profile-details">
                  <Label htmlFor="city" className="font-weight-bold">City</Label>
                  <Input name="city" type="text" id="city" value={profiles.city} placeholder="" onChange={(e) => { this.handleChange(e) }} />
                </FormGroup>
              </Col>
              <Col md="6">
                <FormGroup className="d-flex flex-column flex-md-row align-items-center profile-details">
                  <Label htmlFor="time" className="font-weight-bold">Time Zone</Label>
                  {/* <Input name="time" type="text" id="time" value={profiles.time} placeholder="" onChange={(e) => { this.handleChange(e) }} /> */}
                  <select type="select" name="timezoneId" id='timezoneId' className="form-control" value={profiles.timezoneId} onChange={(e) => { this.handleChange(e) }}>
                    <option value="0">Please select timezone</option>
                    {(this.state.responseDataTIMEZONE !== undefined && this.state.responseDataTIMEZONE.length > 0 ?
                      this.state.responseDataTIMEZONE.map(function (responseDataTIMEZONE) {
                        return <option key={responseDataTIMEZONE.id}
                          value={responseDataTIMEZONE.id}>{responseDataTIMEZONE.name}</option>;
                      })
                      : "")}
                  </select>
                </FormGroup>
              </Col>
            </Row>

            {/* <h3 className="m-0 mb-4">Your Demographics (optional)</h3> */}
            <Row className="mb-3">
              {/*<Col md="6">
                <FormGroup className="d-flex flex-column flex-md-row align-items-center profile-details">
                  <Label htmlFor="birth" className="font-weight-bold">
                    Birthdate
                  <small className="birthdate">
                      No One will see <br /> your birth year
                  </small>
                  </Label>
                  <DatePicker className="form-control"
                    closeOnSelect={true} 
                    dropdownMode="select"
                    selected={this.state.dateOfBirth!=null && this.state.dateOfBirth!="null" ?removeTimeZoneForDatePicker(this.state.dateOfBirth):null}
                    onChange={this.handleChng}
                    showMonthDropdown
                    showYearDropdown
                    timeIntervals={1}
                    timeCaption="time"
                    timeFormat="HH:mm"
                    dateFormat="MM/dd/yyyy"
                    popperPlacement="top-start"
                    maxDate={today}
                    popperModifiers={{
                      flip: {
                        enabled: false
                      },
                      preventOverflow: {
                        enabled: true,
                        escapeWithReference: false
                      }
                    }}
                  />
                </FormGroup>
              </Col>*/}
              <Col md="6">
                {/* <FormGroup className="d-flex flex-column flex-md-row align-items-center profile-details">
                  <Label htmlFor="gender" className="font-weight-bold">Gender</Label> */}
                {/* <Input name="gender" type="text" id="gender" value={profiles.gender} placeholder="" onChange={(e) => { this.handleChange(e) }} /> */}
                {/* <select type="select" name="genderId" id='genderId' className="form-control" value={profiles.genderId} onChange={(e) => { this.handleChange(e) }}>
                    <option value="0">Please select gender</option>
                    {(this.state.responseDataGENDER !== undefined && this.state.responseDataGENDER.length > 0 ?
                      this.state.responseDataGENDER.map(function (responseDataGENDER) {
                        return <option key={responseDataGENDER.id}
                          value={responseDataGENDER.id}>{responseDataGENDER.name}</option>;
                      })
                      : "")}
                  </select> */}
                {/* </FormGroup> */}
              </Col>
            </Row>
            {this.props.profile.isAccountSetupComplete == 1 ? '' :
              <div className="d-flex flex-row">
                {/* <Checkbox
                        checked={this.state.acceptLicense}
                        onChange={this.acceptLicense}
                      /> */}
                <p className="m-0">
                  By clicking, you agree to the OnUP <a href="https://www.teamonup.com/terms-of-use" target="_blank">Terms of Service</a> and <a href="https://www.teamonup.com/privacy-policy" target="_blank">Privacy Policy</a>
                </p>
              </div>
            }

            <div className="float-end">
              {this.props.profile.isAccountSetupComplete == 0 ?
                <Button onClick={this.updateProfiles} className="btn btn-primary text-nowrap ml-3">Agree and Signup</Button> :
                <React.Fragment><span class="cursor pe-2" onClick={this.props.toggleSuccess}>Cancel</span><Button onClick={this.updateProfiles} className="btn btn-primary text-nowrap ml-3">Continue</Button></React.Fragment>}

              {/* {this.props.profile.isAccountSetupComplete==0 ? 
    <React.Fragment><div className="text-right" style={{color:"red"}}>{this.state.validationMsg}</div>
    <Button onClick={this.updateProfiles} className="btn btn-primary text-nowrap ml-3">Agree and Signup</Button></React.Fragment> :
    <React.Fragment><span class="cursor" onClick={this.props.toggleSuccess}>Cancel</span><Button onClick={this.updateProfiles} className="btn btn-primary text-nowrap ml-3">Continue</Button></React.Fragment> 
              // <Button color="primary" size="sm" className="mr-2" onClick={this.props.toggleSuccess}>Cancel</Button>
              } */}

            </div>
          </Form>
        </CardBody>
      </Card>
    )
  }
}

export default Profiles;
