import {all,takeLatest, call, put} from "redux-saga/effects";
import {receiveMeetingListAction,FETCH_MEETING_LIST,REQUEST_UPDATE_FEEDBACK_ACTION,responseUpdateFeedbackAction,
    REQUEST_GET_FEEDBACK_ACTION,responseGetFeedbackAction
} from "../actions/getMeetingListAction"
import { getMeetingsApi,updateFeedbackApi,getFeedbackApi } from "../api/getMeetingsApi";

function* fetchMeetingSagas(action){
    try {
        const response = yield call(getMeetingsApi,action.payLoad);
        //console.log("growthplanDetailsagas");
        //console.log(response);
        yield put(receiveMeetingListAction(response));
    } catch (e) {
        console.log(e);
    }
}
function* updateFeedBackSagas(action) {
    
    try {
      //const tokenStr = yield* checkAuth();
      // do api call
      const response = yield call(updateFeedbackApi,action.payLoad);
      yield put(responseUpdateFeedbackAction(response));
    } catch (e) {
      console.log(e);
    }
  }

  function* getFeedBackSagas(action) {
    
    try {
      //const tokenStr = yield* checkAuth();
      // do api call
      const response = yield call(getFeedbackApi,action.payLoad);
      yield put(responseGetFeedbackAction(response));
    } catch (e) {
      console.log(e);
    }
  }
export default function* getMeetingSagas() {
    yield all([
    takeLatest(FETCH_MEETING_LIST, fetchMeetingSagas),
    takeLatest(REQUEST_UPDATE_FEEDBACK_ACTION, updateFeedBackSagas),
    takeLatest(REQUEST_GET_FEEDBACK_ACTION, getFeedBackSagas)
    ]);
}   