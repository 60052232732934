import _ from 'lodash';


export const EMAILDATA = 'EMAILDATA';

export const emailData = data => {
    return {
      type: EMAILDATA,
      payload:data
    };
  }