import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import axios from "axios";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

// import { AppNavbarBrand, AppSidebarToggler } from "@coreui/react";
import { CNavbarBrand } from "@coreui/react";
import logo from "../../assets/img/brand/login-logo.png";
import sygnet from "../../assets/img/brand/logo.png";
import userImg from "../../assets/img/avatars/default-user.png";
import UserImage from "../../views/User/UserImage";
import { getProfileDetails } from "../../actions/dashboardAction";
import { getMeetingListAction } from "../../actions/getMeetingListAction";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import GlobalSession from "../../views/Common/GlobalSession";
// import _ from 'lodash';
import CompanyAndUserSelect from "../../views/CommunityGrowthPlan/CompanyAndUserSelect";
import AddUser from "../../views/UserList/AddUser";
import { toast } from "react-toastify";
// import UpcomingSessions from '../../views/Sidebar/UpcomingSessions';
// import moment from 'moment'
// const propTypes = {
//   children: PropTypes.node,
// };
// const defaultProps = {};
class DefaultHeader extends Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.mettingToggle = this.mettingToggle.bind(this);
    this.state = {
      dropdownOpen: false,
      meetingOpen: false,
      name: "",
      userList: [],
      addUserModal: false,
    };
  }
  componentDidMount() {
    this.getuserList();
    // let inputobj = { "action": "PENDING", "entityId": GlobalSession.entityId }
    // this.props.getMeetingListAction(inputobj);
  }
  componentWillReceiveProps(nextProps) {
    this.setState({
      meetingObj: nextProps.meetingObj,
    });
  }

  getuserList = async () => {
    await axios({
      method: "POST",
      url: process.env.REACT_APP_API_END_POINT + "/getPicklist",
      data: {
        picklistType: "SEARCH_USER",
        // entityId: sessionStorage.getItem("USER_ENTITY_ID"),
        entityId: GlobalSession.entityId,
      },
    })
      .then((Response) => Response)
      .then((res) => {
        let data = res.data.picklist
          .filter(
            (obj) =>
              !(
                obj.id === Number(GlobalSession.entityId) &&
                obj.companyId === Number(GlobalSession.companyId)
              )
          )
          .sort((a, b) => {
            if (a.firstname.trim() < b.firstname.trim()) {
              return -1;
            }
            if (a.firstname.trim() > b.firstname.trim()) {
              return 1;
            }
            return 0;
          });

        // console.log(data);
        this.setState({ userList: data });
      })
      .catch((e) => console.log(e));
  };

  toggle() {
    this.setState((prevState) => ({
      dropdownOpen: !prevState.dropdownOpen,
    }));
  }
  mettingToggle() {
    let { meetingOpen } = this.state;
    if (!meetingOpen) {
      this.componentDidMount();
    }
    this.setState((prevState) => ({
      meetingOpen: !prevState.meetingOpen,
    }));
  }
  updateEntityActivity = async () => {
    var inputobj = {
      auditActivityType: "UA",
      logType: "I",
      activity: "LOGOUT",
      entityId: GlobalSession.entityId,
      growthPlanId: null,
      goalTagId: null,
      actionTagId: null,
      meetingId: null,
      page: "",
      auditText: "",
      auditValue: null,
      auditMessage: "User has Logged Out",
      auditLink: "",
      email: null,
    };
    await axios({
      method: "POST",
      url: process.env.REACT_APP_API_END_POINT + "/updateEntityActivity",
      data: inputobj,
    })
      .then((Response) => Response)
      .then((res) => {
        return res;
      })
      .catch(function (error) {});
  };

  logOut = async () => {
    await this.updateEntityActivity();
    this.props.signOut();
  };

  render() {
    var firstName = "";
    var lastName = "";
    if (
      this.props.profileobj != null &&
      this.props.profileobj != "" &&
      this.props.profileobj.firstName != null
    ) {
      firstName = this.props.profileobj.firstName;
      lastName = " " + this.props.profileobj.lastName;
    }
    var isAdmin = false;
    if (
      this.props.profileobj != null &&
      this.props.profileobj.securityToken == 1
    ) {
      isAdmin = true;
    }
    var fullName = firstName + lastName;
    // eslint-disable-next-line
    const {
      auth,
      profile,
      signOut,
      children,
      headerName,
      showSearch,
      ...attributes
    } = this.props;
    let { company_logo } = this.props.profileobj;
    // let self = this;
    return (
      <div className="w-100 d-flex justify-content-between">
        {/* <AppSidebarToggler className="d-lg-none" display="md" mobile /> */}
        <CNavbarBrand
          as={Link}
          href="#/dashboard"
          className="w-50"
          // style={{ marginLeft: "14px" }}
          // tag={Link}
          // to="/dashboard"
          // className="w-50"
          // style={{ marginLeft: "70px" }}
          // full={{
          //   src: company_logo ? company_logo : logo,
          //   paddingLeft: "50px",
          //   width: 300,
          //   height: "auto",
          //   alt: "OnUP Logo",
          // }}
          // minimized={{
          //   src: sygnet,
          //   paddingLeft: "50px",
          //   width: 78,
          //   height: "auto",
          //   alt: "OnUP Logo",
          // }}
        >
          <img
            src={company_logo ? company_logo : logo}
            alt="OnUP Logo"
            style={{
              width: 300,
              height: "auto",
              objectFit: "contain",
              maxHeight: "100%",
            }}
          />
        </CNavbarBrand>
        <div className="ml-auto"></div>
        <div className="ml-4">
          {/* <UserStatus className="text-right float-right"/>    */}
        </div>

        <div
          className="d-flex align-items-center justify-content-end"
          style={{ columnGap: "1rem" }}
        >
          <CompanyAndUserSelect
            profile={profile}
            userList={this.state.userList}
          ></CompanyAndUserSelect>
          {/* <a
                  target="_blank"
                  rel="noreferrer"
                  href={item.twitter}
                  style={{
                    border: "none",
                    backgroundColor: "none",
                    padding: 0,
                    dipslay: "inline-block",
                  }}
                >
                </a> */}
          <div className="d-flex align-items-center">
            <Dropdown
              isOpen={this.state.dropdownOpen}
              toggle={this.toggle}
              className="profile-dropdown"
            >
              <DropdownToggle
                tag="a"
                className="d-block"
                style={{ color: "inherit" }}
              >
                <div className="d-flex align-items-center">
                  <span className="me-2">{fullName}</span>
                </div>
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem tag="a" href="#/userprofile">
                  <i className="fa fa-user"></i> My Profile
                </DropdownItem>
                <DropdownItem tag="a" href="#/userchangepassword">
                  <i className="fa fa-lock"></i>Change Password
                </DropdownItem>
                {profile !== undefined &&
                (profile.securityToken === 1 || profile.securityToken === 2) ? (
                  <DropdownItem tag="a" href="#/adminLandingPage">
                    <i className="fa fa-users"></i>Admin
                  </DropdownItem>
                ) : (
                  ""
                )}
                {profile !== undefined &&
                (profile.securityToken === 1 || profile.securityToken === 2) ? (
                  <DropdownItem
                    onClick={() => {
                      this.setState({ addUserModal: true });
                      this.toggle();
                    }}
                  >
                    <i className="fa fa-user-plus"></i>Add User
                  </DropdownItem>
                ) : (
                  ""
                )}
                <DropdownItem tag="a" href="#/reporting">
                  <i className="fa fa-bar-chart"></i> Reporting
                </DropdownItem>
                <DropdownItem onClick={this.logOut}>
                  <i className="fa fa-sign-out"></i> Logout
                </DropdownItem>
                {/* <DropdownItem tag="a" href="#" onClick={this.logOut}><i className="fa fa-sign-out"></i> Logout</DropdownItem> */}
              </DropdownMenu>
            </Dropdown>
            <UserImage width="48" />
          </div>
        </div>
        <AddUser
          success={this.state.addUserModal}
          toggle={() => this.setState({ addUserModal: false })}
          toggleSuccess={() => toast.success("User Added Successfully")}
        />
      </div>
    );
  }
}

// DefaultHeader.propTypes = propTypes;
// DefaultHeader.defaultProps = defaultProps;
const mapStateToProps = (state) => {
  return {
    profileobj: state.profile.data,
    meetingObj: state.getMeetingList.getMeetingListDetailsObj,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ getProfileDetails, getMeetingListAction }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(DefaultHeader);

// export default DefaultHeader;
