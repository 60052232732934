import {all, fork, take, takeLatest, call, put, select} from "redux-saga/effects";
import {getGrowthPlanDetailsApi} from '../api/growthPlanDetailsApi';
import {receiveGrowthPlanDetailsAction,receiveTeamGrowthPlanDetailsAction,FETCH_GrowthPlanDetails_Lst,ADD_NewGoalPlanDetails_lst,receiveNewGoalDetails,FETCH_TeamGrowthPlanDetails_Lst} from '../actions/growthPlanDetailsAction';
import {getUpdateGrowthPlanApi} from '../api/getUpdateGrowthPlanApi';
import {FETCH_UpdateGrowthPlan_lst, receiveUpdateGrowthPlanSummaary} from '../actions/updateGrowthPlanActions';
import {addNewGoalsDetailsApi} from '../api/addNewGoalsDetailsApi';

function* fetchGrowthPlanDetails(action){
    try {
    //const tokenStr = yield* checkAuth();
    // do api call
    const response = yield call(getGrowthPlanDetailsApi,{growthPlanAction:action.growthPlanAction,entityId:action.entityId,growthPlanId:action.growthPlanId,childPlanId:action.childPlanId});
    //console.log("growthplanDetailsagas"+response);
    yield put(receiveGrowthPlanDetailsAction(response));
  } catch (e) {
    console.log(e);
  }
}
function* fetchTeamGrowthPlanDetails(action){
  try {
  //const tokenStr = yield* checkAuth();
  // do api call
  const response = yield call(getGrowthPlanDetailsApi,{growthPlanAction:action.growthPlanAction,entityId:action.entityId,growthPlanId:action.growthPlanId,statusId:action.statusId,companyId:action.companyId});
  //console.log("growthplanDetailsagas"+response);
  yield put(receiveTeamGrowthPlanDetailsAction(response));
} catch (e) {
  console.log(e);
}
}
function* fetchUpdateGrowthPlanSagas(action) {
    //console.log("inside the sagas newGrowthPlanSagas method");
      try {
      
      //const tokenStr = yield* checkAuth();
      // do api call
      const response = yield call(getUpdateGrowthPlanApi,{inputParams:action.payLoad});
      yield put(receiveUpdateGrowthPlanSummaary(response));
    } catch (e) {
      console.log(e);
    }
  }

function* addNewGoalDetailsApi(action){
  //console.log("addNewGoalDetails in sagas",action.payload);
  try {
      
    //const tokenStr = yield* checkAuth();
    // do api call
    const response = yield call(addNewGoalsDetailsApi,{addNewGoalsDetails:action.payload});
     yield put(receiveNewGoalDetails(response));
  } catch (e) {
    console.log(e);
  }

}
export default function* growthPlanDetailsSagas() {
    //console.log("inside growth plan details sagas top method");
    yield all([
      takeLatest(FETCH_GrowthPlanDetails_Lst, fetchGrowthPlanDetails),
      takeLatest(FETCH_TeamGrowthPlanDetails_Lst, fetchTeamGrowthPlanDetails),
      takeLatest(FETCH_UpdateGrowthPlan_lst,fetchUpdateGrowthPlanSagas),
      takeLatest(ADD_NewGoalPlanDetails_lst,addNewGoalDetailsApi),
    ]);
  }