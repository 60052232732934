import React, { Component } from "react";
import {
  HashRouter,
  Route,
  Routes,
  // BrowserRouter,
  // Navigate,
} from "react-router-dom";
import "./App.css";
// CoreUI Icons Set
import "@coreui/coreui/dist/css/coreui.min.css";
// import '@coreui/icons/css/coreui-icons.min.css';
// Import Flag Icons Set
// import 'flag-icon-css/css/flag-icon.min.css';
// Import Font Awesome Icons Set
import "font-awesome/css/font-awesome.min.css";
// Import Simple Line Icons Set
import "simple-line-icons/css/simple-line-icons.css";
// Import Tostify
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import 'bootstrap/dist/css/bootstrap.min.css';

// Containers
import { DefaultLayout } from "./containers";
// Pages
import {
  Login,
  Page404,
  Page500,
  Register,
  ResetPassword,
  ChangePassword,
  ForceChangePassword,
  ForgotPassword,
} from "./views/Pages";

// Import Main styles for this application
import "./scss/style.css";
// import routes from "./routes";
// import PrivateRoute from "./containers/PrivateRoute";
// import routes from '../../routes';
import InviteUser from "./InviteUser";
import Loadable from "react-loadable";
import LoadingDots from "./views/Common/LoadingDots";
function Loading() {
  return (
    <div>
      {" "}
      <LoadingDots />
    </div>
  );
}

const UserManagement = Loadable({
  loader: () => import("./views/UserList/UserLanding"),
  loading: Loading,
});

const AdminLandingPage = Loadable({
  loader: () => import("./views/UserList/AdminLandingPage"),
  loading: Loading,
});
const UserProfile = Loadable({
  loader: () => import("./views/UserProfile"),
  loading: Loading,
});
const GetHelpDetails = Loadable({
  loader: () => import("./views/Dashboard/GetHelpDetails/index"),
  loading: Loading,
});

const CommunityMeetingPage = Loadable({
  loader: () => import("./views/CommunityGrowthPlan/CommunityMeetingPage"),
  loading: Loading,
});
const CommunityMeetingInvitePage = Loadable({
  loader: () =>
    import("./views/CommunityGrowthPlan/CommunityMeetingInvitePage"),
  loading: Loading,
});
const communityNotesComponents = Loadable({
  loader: () => import("./views/Notes/communityNotesComponents"),
  loading: Loading,
});
const communityPlanNotes = Loadable({
  loader: () => import("./views/CommunityGrowthPlan/Editor"),
  loading: Loading,
});
const Dashboard = Loadable({
  loader: () => import("./views/Dashboard"),
  loading: Loading,
});
const Reporting = Loadable({
  loader: () => import("./views/Reporting"),
  loading: Loading,
});
const Analytics = Loadable({
  loader: () => import("./views/Analytics/AnalyticsReport"),
  loading: Loading,
});
const AnalyticsAdmin = Loadable({
  loader: () => import("./views/Analytics/AnalyticsAdmin"),
  loading: Loading,
});

const CompanyManagement = Loadable({
  loader: () => import("./views/Admin/companyList"),
  loading: Loading,
});

const TemplateManagement = Loadable({
  loader: () => import("./views/Admin/templateManagement"),
  loading: Loading,
});
const CronManagemet = Loadable({
  loader: () => import("./views/Admin/CronManagement"),
  loading: Loading,
});
const UserPasswordChange = Loadable({
  loader: () => import("./views/UserProfile/UserPasswordChange"),
  loading: Loading,
});
const Training = Loadable({
  loader: () => import("./views/Admin/Training"),
  loading: Loading,
});
const TrainingNew = Loadable({
  loader: () => import("./views/Admin/TrainingNew"),
  loading: Loading,
});
const MobileDash = Loadable({
  loader: () => import("./views/Dashboard/DashboardMobile"),
  loading: Loading,
});
const MobilePlan = Loadable({
  loader: () => import("./views/CommunityGrowthPlan/CommunityMeetingMobilePage"),
  loading: Loading,
});
class App extends Component {
  componentWillMount() {
    var currentVersion = localStorage.getItem("appVersion");
    fetch("/meta.json", {
      cache: "no-store",
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (meta) {
        var newVersion = meta.version;
        if (newVersion == currentVersion) {
          localStorage.setItem("appVersion", newVersion);
        } else {
          if ("caches" in window) {
            // Service worker cache should be cleared with caches.delete()
            caches.keys().then(function (names) {
              // eslint-disable-next-line no-restricted-syntax
              for (var _i = 0, names_1 = names; _i < names_1.length; _i++) {
                var name_1 = names_1[_i];
                caches.delete(name_1);
              }
            });
          }
          localStorage.setItem("appVersion", newVersion);
          window.location.reload(true);
        }
        // var currentVersion = appVersion;
        // var isUpdated = newVersion === currentVersion;
        // if (!isUpdated && !auto) {
        //     console.log('An update is available!');
        //     setLatestVersion(newVersion);
        //     setLoading(false);
        //     setIsLatestVersion(false);
        // }
        // else if (!isUpdated && auto) {
        //     emptyCacheStorage(newVersion);
        // }
        // else {
        //     setIsLatestVersion(true);
        //     setLoading(false);
        // }
      });
  }
  render() {
    return (
      <>
        {/* //  <ClearCache >
      //    {({ isLatestVersion, emptyCacheStorage }) => */}
        <ToastContainer
          position="top-right"
          autoClose={2000}
          hideProgressBar={false}
          theme="light"
        />
        <HashRouter basename="/">
          <Routes>
            <Route exact path="/login" element={<Login />} />
            <Route
              exact
              path="/login/:userId/:teamId"
              // name="Login Page"
              element={<Login />}
            />
            <Route
              exact
              path="/resetpassword"
              name="Reset Password"
              element={<ResetPassword />}
            />
            <Route
              exact
              path="/forgotpassword"
              name="Forgot Password"
              element={<ForgotPassword />}
            />
            <Route
              exact
              path="/changepassword"
              name="Change Password"
              element={<ChangePassword />}
            />
            <Route
              exact
              path="/forcechangepassword"
              name="Change Password"
              element={<ForceChangePassword />}
            />
            <Route
              exact
              path="/register"
              name="Register Page"
              element={<Register />}
            />
            <Route
              exact
              path="/communityMeetingInvitePage"
              name="Community Meeting Page"
              Component={CommunityMeetingInvitePage}
              // element={<CommunityMeetingPage />}
            />
            <Route
              exact
              path="/communityInviteNotesComponents"
              name="community Invite Notes Components"
              Component={communityNotesComponents}
              // element={communityNotesComponents}
            />
            <Route
              exact
              path="/inviteuser/:id"
              name="InviteUser Page"
              element={<InviteUser />}
            />
            <Route exact path="/404" name="Page 404" element={<Page404 />} />
            <Route exact path="/500" name="Page 500" element={<Page500 />} />
            <Route
              exact
              path="/Notes"
              name="Community Plan Notes"
              Component={communityPlanNotes}
              // element={communityPlanNotes}
            />
            <Route path="/" name="Home" element={<DefaultLayout />} />
            <Route element={<DefaultLayout />}>
              <Route
                path="/dashboard"
                exact={true}
                name="Dashboard"
                Component={Dashboard}
              />
              <Route
                path="/adminLandingPage"
                exact={true}
                name="Admin Landing Page"
                Component={AdminLandingPage}
              />
              <Route
                path="/userManagement"
                exact={true}
                name="User Management"
                Component={UserManagement}
              />
              <Route
                path="/userprofile"
                exact={true}
                name="User Profile"
                Component={UserProfile}
              />
              <Route
                path="/getHelp"
                exact={true}
                name="GetHelpDetails"
                Component={GetHelpDetails}
              />
              <Route
                path="/reporting"
                exact={true}
                name="Reporting"
                Component={Reporting}
              />
              <Route
                path="/analytics"
                exact={true}
                name="Analytics"
                Component={Analytics}
              />
              <Route
                path="/analyticsAdmin"
                exact={true}
                name="AnalyticsAdmin"
                Component={AnalyticsAdmin}
              />
              <Route
                path="/work-plan"
                exact={true}
                name="WorkPlan"
                Component={CommunityMeetingPage}
              />
              <Route
                path="/companymanagement"
                exact={true}
                name="CompanyMangement"
                Component={CompanyManagement}
              />
              <Route
                path="/templatemanagement"
                exact={true}
                name="TemplateManagement"
                Component={TemplateManagement}
              />
              <Route
                path="/cronmanagement"
                exact={true}
                name="CronManagemet"
                Component={CronManagemet}
              />
              <Route
                path="/userchangepassword"
                exact={true}
                name="User Password Changes"
                Component={UserPasswordChange}
              />
              <Route
                path="/training/:type"
                exact={true}
                name="Training"
                Component={TrainingNew}
              />
              {/* <Route
                path="/trainingNew/:type"
                exact={true}
                name="Training"
                Component={TrainingNew}
              /> */}
              <Route
                path="/mobile/dashboard"
                exact={true}
                name="Mobile Dashboard"
                Component={MobileDash}
              />
              <Route
                path="/mobile/work-plan"
                exact={true}
                name="Mobile Plan"
                Component={MobilePlan}
              />

              {/* {routes.map((route, idx) => {
                // && !route.protected
                console.log(route,"r")
                if (route.component && !route.protected) {
                  return (
                    <Route
                      key={idx}
                      path={route.path}
                      exact={route.exact}
                      name={route.name}
                      render={(props) => <route.component {...props} />}
                    />
                  );
                } else if (route.component && route.protected) {
                  return (
                    <PrivateRoute
                      component={route.component}
                      key={idx}
                      path={route.path}
                      exact={route.exact}
                      name={route.name}
                    />
                  );
                } 
                // else {
                //   //alert("test");
                //   return null;
                // }
              })} */}

              {/* {currentRoute==undefined ||currentRoute.name=="Home" || (GlobalSession.entityId==null || GlobalSession.entityId=="null" )  ?<Navigate to="/login" />:<Outlet />} */}
            </Route>
          </Routes>
        </HashRouter>
        {/* }
       </ClearCache> */}
      </>
    );
  }
}

export default App;
