import React, { Component } from "react";
import PropTypes from "prop-types";
import { Field, reduxForm } from "redux-form";

// import { TextField } from "redux-form-material-ui";

// import { RaisedButton } from 'material-ui'
import { Button, TextField } from "@mui/material";

// import { state } from 'aws-cognito-redux-saga'

// import validator from 'validator'
import LoginLogo from "../../../assets/img/brand/login-logo.png";
// import { white } from "material-ui/styles/colors";
import axios from "axios";

const required = (value) => (value ? undefined : "Required");
const passwordMatch = (value, values) =>
  values.password !== values.passwordMatch && "Passwords must match";
const minLength = (value) => (value.length >= 8 ? undefined : "Min Length 8");
// const email = value =>
//   validator.isEmail(value) ? undefined : 'Not Valid Email'

const style = {
  paper: { padding: "16px" },
  // layout: {
  //   display: 'flex',
  //   flexDirection: 'column',
  //   justifyContent: 'center',
  //   alignItems: 'center',
  //   height: 'calc(100vh - 56px)'
  // },
  // title: {
  //   fontSize: '32px',
  //   textAlign: 'center'
  // },
  // form: {
  //   display: 'flex',
  //   flexDirection: 'column',
  //   justifyContent: 'center',
  //   alignItems: 'center'
  // },
  // button: {
  //   margin: '16px',
  //   width: '80%'
  // },
  // field: {
  //   margin: '8px 32px'
  // },
  error: {
    width: "80%",
    color: "rgb(200,0,0)",
    margin: "8px",
    // height: '32px'
  },
};

class ChangePassword extends Component {
  static propTypes = {
    history: PropTypes.object,
    handleSubmit: PropTypes.func.isRequired,
    changePassword: PropTypes.func.isRequired,
    auth: PropTypes.object,
    init: PropTypes.func,
  };

  componentWillMount() {
    // this.props.init();
    this.updateUserCode();
  }

  changePassword = async (values) => {
    var sessionobj = this.getEmailFromURLParams();
    var emailId = sessionobj.email;
    // this.props.changePassword(
    //   emailId.toLowerCase(),
    //   values.code,
    //   values.password
    // )
    try {
      await axios({
        url: process.env.REACT_APP_API_END_POINT + "/forgetPasswordChange",
        method: "POST",
        data: {
          email: emailId.toLowerCase(),
          code: values.code,
          password: values.password,
        },
      })
        .then((res) => {
          if (res.data && res.data.header && res.data.header.errorCode === 0) {
            if (res.data.result.status === 0) {
              this.props.UpdateLoginerrors(res.data.result.errorMsg);
            } else {
              console.log(res, this.props);
              this.props.forgetPasswordRequest();
            }
          }
        })
        .catch((err) => err);
    } catch (e) {
      console.log("error", e);
    }
  };

  signIn = () => {
    this.props.history.push("/signin");
  };
  updateUserCode = () => {
    var sessionObj = this.getEmailFromURLParams();
    var verificationCode =
      sessionObj.verificationCode != null ? sessionObj.verificationCode : "";
    this.props.initialize({ code: verificationCode });
  };
  getEmailFromURLParams = () => {
    var returnObj = null;
    var email = sessionStorage.getItem("ForgotPasswordEmailId");
    var verificationCode = "";
    if (email == null || email == "" || email.trim().length == 0) {
      var url_string = window.location.href;
      var urlparams = {};
      var params = url_string.replace(
        /[?&]+([^=&]+)=([^&]*)/gi,
        function (m, key, value) {
          urlparams[key] = value;
        }
      );
      email = urlparams["email"];
      verificationCode = urlparams["verificationCode"];
      returnObj = {
        email: email,
        verificationCode: verificationCode,
      };
    } else {
      returnObj = {
        email: email,
        verificationCode: verificationCode,
      };
    }
    return returnObj;
  };
  renderChangePassword() {
    const { handleSubmit, auth } = this.props;
    var sessionObj = this.getEmailFromURLParams();
    var email = sessionObj.email != null ? sessionObj.email : "";
    return (
      <div className="d-flex flex-column align-items-center justify-content-center login-section">
        <div className="login-container login-form-fields">
          <form style={style.form} onSubmit={handleSubmit(this.changePassword)}>
            <div className="login-logo">
              <img src={LoginLogo} className="img-fluid" />
            </div>
            <div className="alert-success mb-2 p-2">
              An email has been sent to {email} with a verification code
            </div>
            <Field
              name="code"
              validate={[required]}
              component={TextField}
              type="string"
              placeholder="Verification Code"
              LabelText="Code"
              autoComplete="new-password"
              className="form-control restfrpwd"
            />

            <Field
              name="password"
              validate={[required, minLength]}
              component={TextField}
              type="password"
              placeholder="Password"
              LabelText="Password"
              autoComplete="new-password"
              className="form-control restfrpwd"
            />

            <Field
              name="passwordMatch"
              validate={[required, passwordMatch, minLength]}
              component={TextField}
              type="password"
              placeholder="Confirm Password"
              LabelText="Password"
              autoComplete="new-password"
              className="form-control restfrpwd"
            />

            <div className="reseterror" style={style.error}>
              {auth.error.message}
            </div>

            <Button
              variant="contained"
              className="btn-block btn btn-primary resetsubmit"
              type="submit"
            >
              Change Password
            </Button>
          </form>
        </div>
      </div>
    );
  }

  renderSignIn() {
    const { handleSubmit } = this.props;
    return (
      <div className="d-flex flex-column align-items-center justify-content-center login-section">
        {/* <Paper style={style.paper} zDepth={5}> */}
        <form>
          <h5 style={{ color: "white" }}>
            Success! Your Password has been changed!
          </h5>
          <div style={{ textAlign: "center" }}>
            <Button
              variant="contained"
              className="btn btn-sm btn-primary resetsubmit"
              onClick={handleSubmit(this.signIn)}
            >
              Sign In
            </Button>
          </div>
        </form>
        {/* </Paper> */}
      </div>
    );
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.auth.hasChangedPassword == true) {
      // this.updateCognitoStatusInDB();
      this.updateEntityActivity();
    }
  }
  updateEntityActivity = async () => {
    var sessionObj = this.getEmailFromURLParams();
    var email = sessionObj.email != null ? sessionObj.email : "";
    var inputobj = {
      auditActivityType: "UA",
      logType: "I",
      activity: "CHANGE PASSWORD",
      entityId: null,
      growthPlanId: null,
      goalTagId: null,
      actionTagId: null,
      meetingId: null,
      page: "",
      auditText: "",
      auditValue: null,
      auditMessage: "User has Changed password via forgot password",
      auditLink: "",
      email: email,
    };
    await axios({
      method: "POST",
      url: process.env.REACT_APP_API_END_POINT + "/updateEntityActivity",
      data: inputobj,
    })
      .then((Response) => Response)
      .then((res) => {
        return res;
      })
      .catch(function (error) {});
  };
  updateCognitoStatusInDB = async () => {
    var sessionObj = this.getEmailFromURLParams();
    var email = sessionObj.email != null ? sessionObj.email : "";
    await axios({
      method: "post",
      url: process.env.REACT_APP_API_END_POINT + "/updateCognitoStatuByEmail",
      data: {
        email: email,
      },
    })
      .then((Response) => Response)
      .then((res) => {})
      .catch(function (error) {});
  };
  render() {
    const { auth } = this.props;
    return auth.hasChangedPassword === true
      ? this.renderSignIn()
      : this.renderChangePassword();
  }
}

// Decorate the form component
export default reduxForm({
  form: "changePassword",
})(ChangePassword);
