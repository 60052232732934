import React from 'react'
import PropTypes from 'prop-types'
// import Route from 'react-router-dom/Route'
import { Route } from 'react-router-dom'
// import Redirect from 'react-router-dom/Redirect'
import { Navigate } from 'react-router-dom'
import { state } from 'aws-cognito-redux-saga'

class PrivateRoute extends React.Component {
  static propTypes = {
    auth: PropTypes.object
  }

  render() {
    let { auth, path } = this.props

    return (
      <Route
        render={() => {
          return auth.isSignedIn !== state.AUTH_FAIL ? (
            <this.props.component {...this.props} />
          ) : (
            <Navigate to="/login" replace state={{ from: path }} />
            // <Redirect to={{
            //   pathname: "/login",
            //   state: { path }
            // }}/>
          )
        }}
      />
    )
  }
}

export default PrivateRoute
