import React, { Component } from 'react';
import {connect} from "react-redux";
// import { Redirect, Route, Switch, withRouter } from 'react-router-dom';
import { Navigate, Route, Routes, Outlet } from 'react-router-dom';
import { Container } from 'reactstrap';
import PrivateRoute from '../PrivateRoute'
import { reducer,state } from 'aws-cognito-redux-saga';
import axios from 'axios';
import { withRouter } from './withRouter';

import {
  // AppAside,
  // AppHeader,
  CHeader
} from '@coreui/react';

// routes config
import routes from '../../routes';
//import DefaultAside from './DefaultAside';
import DefaultHeader from './DefaultHeader';
import MobileHeader from './MobileHeader';
import GlobalSession from "../../views/Common/GlobalSession";
import { SignOutUser } from '../../actions/auth';

class DefaultLayout extends Component {
  constructor(props){
    super(props)
    this.state={
      //isAccountSetUpComplete:true,
      redirectToLogin:false,
      isMobile: false,
    }
  }
  
  // state={
  //   //isAccountSetUpComplete:true,
  //   redirectToLogin:false
  // }
  UNSAFE_componentWillMount(){
    var userBrowserSession=sessionStorage.getItem("UserBrowserSession");
    let loggedAt = localStorage.getItem("loginAt")
    let loggedHrs = (new Date() - new Date(loggedAt)) / (1000 * 60 * 60);
    const { auth, profile, signOut, children, headerName, showSearch, router:{location, setNavigateTo}, ...attributes } = this.props;
    // console.log("logged time",new Date(loggedAt),"current time",new Date(),"diff",new Date() -new Date(loggedAt),loggedHrs)
    if(loggedHrs > 24){
      signOut();
      // localStorage.clear();
      // localStorage.removeItem('appVersion');
      // sessionStorage.clear();
      // window.location="/#/login";
      // window.location.reload();
       //signOut();
      //this.setState({redirectToLogin:true})
      // return;
    }
    // if(userBrowserSession===null || userBrowserSession==="null" || userBrowserSession===undefined || userBrowserSession=="undefined"){
    //   localStorage.clear();
    //   localStorage.removeItem('appVersion');
    //   sessionStorage.clear();
    //   window.location="/#/login";
    //   window.location.reload();
    //    //signOut();
    //   //this.setState({redirectToLogin:true})
      
    // }else{
    //  // this.redirecttoAccountSetUp();
    // //  if(this.props.profile!=undefined && this.props.profile!=null){
    // //   var isAccountSetupComplete=this.props.profile.isAccountSetupComplete;
    // //   if(isAccountSetupComplete === 0){
    // //     this.setState({redirectToLogin:true});
    // //   }else{
    // //     this.setState({redirectToLogin:false});
    // //   }
    // //  }
    // }
    let userAgent = navigator.userAgent,
    checkMobile =/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent)
    // checkMobile =/Android|webOS|iPhone|iPad|Macintosh|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent
    // console.log(userAgent,checkMobile);
    this.setState({isMobile:checkMobile})
    if(location.pathname === '/'){
      checkMobile ? setNavigateTo("/mobile/dashboard"): setNavigateTo("/dashboard")
    }
    // window.location="/#/dashboard"
  }
  UNSAFE_componentWillReceiveProps(nextprops){
    if(this.props.profile!=nextprops.profile){
      var isAccountSetupComplete=nextprops.profile.isAccountSetupComplete;
      if(isAccountSetupComplete === 0){
        this.setState({redirectToLogin:true});
      }else{
        this.setState({redirectToLogin:false});
      }
    }
  }
  
  

  render() {

    const {auth, signOut, profile, router:{location} } = this.props;
    // if(auth.isSignedIn === state.AUTH_SUCCESS && !this.state.isAccountSetUpComplete){
    //   return <Redirect to='/login' />;
    // }
    if(this.state.redirectToLogin===true||this.state.redirectToLogin==="true"){
      return <Navigate to='/login' />;
    }

    // console.log(this.state.redirectToLogin,auth.isSignedIn,GlobalSession.entityId)
    const currentRoute = routes.find((o) => o.path === location && location.pathname );
    // if((GlobalSession.entityId==null || GlobalSession.entityId=="null" ) && auth.isSignedIn != state.AUTH_SUCCESS){
    //   alert("inside the globalsession");
    //   //this.props.signOut();
    // }
    //alert(currentRoute);
    //alert(currentRoute.name+"======"+window.location.href+"=="+auth.isSignedIn+"===="+GlobalSession.entityId);
    // console.log(this.state.isMobile)
    return (
      <div className="app">
        <CHeader position={'sticky'} style={{height:'85px'}} className='p-0'>
          <Container fluid>
            {this.state.isMobile?<MobileHeader signOut={signOut} profile={profile}/>:<DefaultHeader showSearch = {currentRoute && currentRoute.showSearch} headerName = {currentRoute && currentRoute.name} auth={auth} profile={profile} signOut={signOut}/>}
          </Container>
        </CHeader>
        
        <div className="app-body">
          <main className="main">
            <Container fluid>
              {/* <Routes>
              
                {routes.map((route, idx) => {
                  
                  if (route.component && !route.protected) {
                    return (<Route key={idx} path={route.path} exact={route.exact} name={route.name} render={props => (
                      <route.component {...props}/>
                    )} />);
                  }
                  else if (route.component && route.protected && auth.isSignedIn === state.AUTH_SUCCESS){
                         return (<PrivateRoute component={route.component} key={idx} path={route.path} exact={route.exact} name={route.name}/>);
                  }
                  else {
                    //alert("test");
                    return (null);
                  }
                })}
                
                {currentRoute==undefined ||currentRoute.name=="Home" || (GlobalSession.entityId==null || GlobalSession.entityId=="null" )  ? <Navigate to="/login"/>: <Outlet />}
                
                
              </Routes> */}
              {(GlobalSession.entityId==null || GlobalSession.entityId==="null" ) && auth.isSignedIn !== state.AUTH_SUCCESS  ?<Navigate to="/login" />:<Outlet />}
            </Container>
          </main>
          {/* <AppAside fixed hidden>
            <DefaultAside />
          </AppAside> */}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    profile: store.profile.data,
    auth: store.auth
  };
};

const mapDispatchToProps = (dispatch,store) => {
  return {
    signOut: async() => {
      //reducer.isSignedIn()
      // dispatch(reducer.signOut());
        localStorage.clear();
        localStorage.removeItem('appVersion');
        sessionStorage.clear();
        window.location.href = '#'
      dispatch(SignOutUser());
      GlobalSession.entityId = null;
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(DefaultLayout));
