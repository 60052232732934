import axios from "axios";


export const getCategoryApi = async (onSuccess) => {
    try {
        // Get the authentication token
        var randomnumber=Math.floor(Math.random() * 100);
    // headers: {"Authorization" : `Bearer ${tokenStr}`}
        const response =  await axios({
                    method: 'get',
                    url: process.env.REACT_APP_API_END_POINT+'/getCategories'+"?v="+randomnumber
                    })
                    .then(Response => Response)
                    .then(res => {
                      //console.log("inside getCategoryAPI");
                      //console.log(res.data);
                       return res.data;
                    })
        return typeof onSuccess === "function" ? onSuccess(response) : response;
    } catch (e) {
        
        console.log(e);
    }
  };

  export const getTagsApi = async (obj, onSuccess) => {
    try {
                const response =  await axios({
                    method: 'POST',
                    url: process.env.REACT_APP_API_END_POINT+'/getTags',
                    data:obj,  
                    //config: { headers: {'Content-Type': 'multipart/form-data' }}
                    })
                    .then(Response => Response)
                    .then(res => {
                      //console.log(res.data);
                       return res.data;
                    })
        return typeof onSuccess === "function" ? onSuccess(response) : response;
    } catch (e) {
        console.log(e);
    }
  };
  
  export const createGrowthPlanApi = async (obj) => {
    
    try {
        //console.log("input obj");
        //console.log(obj);
        // Get the authentication token
     
    // headers: {"Authorization" : `Bearer ${tokenStr}`}
        const response =  await axios({
                    method: 'POST',
                    url: process.env.REACT_APP_API_END_POINT+'/newGrowthPlan',
                    data:obj,  
                    //config: { headers: {'Content-Type': 'multipart/form-data' }}
                    })
                    .then(Response => Response)
                    .then(res => {
                      //console.log(res.data);
                       return res.data;
                    })
        return response;           
    } catch (e) {
        console.log(e);
    }
  };

  export const updateGrowthPlanApi = async (obj, onSuccess) => {
    
    try {
        //console.log("input obj");
        //console.log(obj);
        // Get the authentication token
     
    // headers: {"Authorization" : `Bearer ${tokenStr}`}
        const response =  await axios({
                    method: 'POST',
                    url: process.env.REACT_APP_API_END_POINT+'/updateGrowthplanSummary',
                    data:obj,  
                    //config: { headers: {'Content-Type': 'multipart/form-data' }}
                    })
                    .then(Response => Response)
                    .then(res => {
                      //console.log(res.data);
                       return res.data;
                    })
        return typeof onSuccess === "function" ? onSuccess(response) : response;
    } catch (e) {
        console.log(e);
    }
  };



    export const updateGoalApi = async (obj, onSuccess) => {
    
    try {
        //console.log("update Goal Input Obj");
        //console.log(obj.goalObj);
        // Get the authentication token
     
    // headers: {"Authorization" : `Bearer ${tokenStr}`}
        const response =  await axios({
                    method: 'POST',
                    url: process.env.REACT_APP_API_END_POINT+'/updateGoal',
                    data:obj.goalObj,  
                    //config: { headers: {'Content-Type': 'multipart/form-data' }}
                    })
                    .then(Response => Response)
                    .then(res => {
                      //console.log(res.data);
                       return res.data;
                    })
        return typeof onSuccess === "function" ? onSuccess(response) : response;
    } catch (e) {
        console.log(e);
    }
  };


  export const growthPlanDetailsApi = async (obj) => {
  if(localStorage.getItem('logingByPass')){
    obj.statusId=-1
  }
    try {
        //console.log("growthPlanDetails input obj");
        //console.log(obj);
        // Get the authentication token
     
    // headers: {"Authorization" : `Bearer ${tokenStr}`}
        const response =  await axios({
                    method: 'POST',
                    url: process.env.REACT_APP_API_END_POINT+'/growth-plan-details',
                    data:obj,  
                    //config: { headers: {'Content-Type': 'multipart/form-data' }}
                    })
                    .then(Response => Response)
                    .then(res => {
                      //console.log(res.data);
                      var returnval=res.data;
                      if(obj.growthPlanId!=undefined && obj.growthPlanId!=null && obj.growthPlanId>0 && res.data.growthPlan.length>0){
                         var growthPlanobj=res.data.growthPlan[0];
                        returnval.growthPlan=growthPlanobj;
                      }
                       return returnval;
                    })
        return response;           
    } catch (e) {
        console.log(e);
    }
  };

  export const updateActionApi = async (obj, onSuccess) => {
    try {
        //console.log("updateActionApi input obj");
        //console.log(obj);
        // Get the authentication token
     
    // headers: {"Authorization" : `Bearer ${tokenStr}`}
        const response =  await axios({
                    method: 'POST',
                    url: process.env.REACT_APP_API_END_POINT+'/updateAction',
                    data:obj,  
                    //config: { headers: {'Content-Type': 'multipart/form-data' }}
                    })
                    .then(Response => Response)
                    .then(res => {
                      //console.log(res.data);
                       return res.data;
                    })
      return typeof onSuccess === "function" ? onSuccess(response) : response;
    } catch (e) {
        console.log(e);
    }
  };

  export const updateGoalQuestionApi = async (obj, onSuccess) => {
    
    try {
        //console.log("updateGoalQuestionApi input obj");
        //console.log(obj);
        // Get the authentication token
     
    // headers: {"Authorization" : `Bearer ${tokenStr}`}
        const response =  await axios({
                    method: 'POST',
                    url: process.env.REACT_APP_API_END_POINT+'/updateGoalQuestion',
                    data:obj,  
                    //config: { headers: {'Content-Type': 'multipart/form-data' }}
                    })
                    .then(Response => Response)
                    .then(res => {
                      //console.log(res.data);
                       return res.data;
                    })
      return typeof onSuccess === "function" ? onSuccess(response) : response;
    } catch (e) {
        console.log(e);
    }
  };

  
  export const updateGrowthplanWeekTemplateApi = async (obj) => {
    
    try {
        //console.log("updateGrowthplanWeekTemplate input obj");
        //console.log(obj);
        // Get the authentication token
     
    // headers: {"Authorization" : `Bearer ${tokenStr}`}
        const response =  await axios({
                    method: 'POST',
                    url: process.env.REACT_APP_API_END_POINT+'/updateTimebankTemplate',
                    data:obj,  
                    //config: { headers: {'Content-Type': 'multipart/form-data' }}
                    })
                    .then(Response => Response)
                    .then(res => {
                      //console.log(res.data);
                       return res.data;
                    })
        return response;           
    } catch (e) {
        console.log(e);
    }
  };

  export const updateCustomizedTagApi = async (obj, onSuccess) => {
    
    try {
        //console.log("updateCustomizedTagApi input obj");
        //console.log(obj);
        // Get the authentication token
     
    // headers: {"Authorization" : `Bearer ${tokenStr}`}
        const response =  await axios({
                    method: 'POST',
                    url: process.env.REACT_APP_API_END_POINT+'/updateCustomizedTag',
                    data:obj,  
                    //config: { headers: {'Content-Type': 'multipart/form-data' }}
                    })
                    .then(Response => Response)
                    .then(res => {
                      //console.log(res.data);
                       return res.data;
                    })
        return typeof onSuccess === "function" ? onSuccess(response) : response;        
    } catch (e) {
        console.log(e);
    }
  };