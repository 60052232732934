import axios from "axios";
import API_END_POINT from "../config/index";
import {all, fork, take, takeLatest, call, put, select} from "redux-saga/effects";
import _ from 'lodash';
import {getEntityBioApi,updateEntityBioApi} from '../api/userBioApi'
import getEntityInterestsApi, { updateEntityInterestsTag } from '../api/getEntityInterestsApi'
import getEntityExperienceApi, { updateEntityExperienceApi } from '../api/getEntityExperienceApi'
import { checkAuth,getAuthUUID } from '../actions/auth'
import { reducer, state } from 'aws-cognito-redux-saga';
import changeCognitoPassword from '../actions/changePassword';
import {FETCH_UserBio,RECEIVE_UserBio,updateEntityBio,
    FETCH_UserInterest,updateEntityInterests, 
    FETCH_UserExperience,updateEntityExperience,
    FETCH_UserGrowthPlan,updateEntityGrowthPlan,FETCH_UPDATEUSERBIO,receiveUpdateUserBioAction,
    REQUEST_UserInterestUpdate,
    REQUEST_UserExperienceUpdate,
    getEntityInterests,
    getEntityExperience
  } from "../actions/userProfileActions"
import { ImportExport } from "aws-sdk";

import GlobalSession from "../views/Common/GlobalSession";


let defaultState = {
  info: {},
  error: {},
  isSignedIn: state.AUTH_UNKNOWN,
  isConfirmed: state.AUTH_UNKNOWN,
  hasSignedUp: state.AUTH_UNKNOWN,
  hasSentCode: state.AUTH_UNKNOWN,
  hasChangedPassword: state.AUTH_UNKNOWN,
  passwordResetRequired: state.AUTH_UNKNOWN
}

// Sagas

// Get All Profiles

export function* fetchUserBioSagas(action) {
    
  try {
    //const tokenStr = yield* checkAuth();
    // do api call

    // const response = yield call(axios.get, 
    //   `${API_END_POINT}/getEntityBio/${action.payload}`,
    //   );
    const response = yield call(getEntityBioApi,action.payload);
    yield put(updateEntityBio(response));
  } catch (e) {
    console.log(e);
  }
}

export function* fetchUserInterestSagas(action) {
    
  try {
    //const tokenStr = yield* checkAuth();
    // do api call

    // const response = yield call(axios.get, 
    //   `${API_END_POINT}/getEntityBio/${action.payload}`,
    //   );
    const response = yield call(getEntityInterestsApi,action.payload);
    yield put(updateEntityInterests(response));
  } catch (e) {
    console.log(e);
  }
}

export function* fetchUserExperienceSagas(action) {
  try{
    const response =yield call(getEntityExperienceApi,action.payload);
    yield put(updateEntityExperience(response));
  }catch(e) {
    console.log(e);
  }
}

export function* fetchUserGrowthPlanSagas(action) {
  try{
    //const response =yield call(,action.payload);
    //yield put(updateEntityGrowthPlan(response));
  }catch(e) {
    console.log(e);
  }

} 

export function* fetchUsers() {
  
  try {
    // Get the authentication token
    const tokenStr = yield* checkAuth();

    // Call the api to get Profiles
    const response = yield call(axios.get, 
      `${API_END_POINT}/Profiles`,
      {
        headers: {"Authorization" : `Bearer ${tokenStr}`}
      });

    // dispatch an action with the api response
    // this is consumed by the reducer
    yield put({type: RECEIVE_USERS, payload: response.data });
  } catch (e) {
    console.log(e);
  }
}

// Get Profile by ID
export function* fetchUser({payload: { id }}) {
  try {
    // Get the authentication token
    const tokenStr = yield* checkAuth();

    // Call the api to get Profile by id
    const response = yield call(axios.get, 
      `${API_END_POINT}/Profiles/${id}`,
      {
        params: {
          filter: JSON.stringify({
            include: 'roles',
          }),
        },
        headers: {"Authorization" : `Bearer ${tokenStr}`}
      });

    // dispatch an action with the api response
    // this is consumed by the reducer
    yield put({type: RECEIVE_USER, payload: response.data });
  } catch (e) {
    console.log(e);
  }
}

// Get logged in user's profile
export function* fetchMyProfile() {
  try {
    // var username=localStorage.getItem('loginUserEmail');
    // Get the authentication token
    // const tokenStr =yield* checkAuth();
    const tokenStr=Math.random()
    var randomnumber=Math.floor(Math.random() * 100);
    var uuId_str=yield* getAuthUUID();
    // Call the api to get Profile by id
    if(localStorage.getItem('logingByPass')){
      uuId_str=localStorage.getItem('tokenId');
    }
    if(uuId_str===null||uuId_str===''){
      return false;
    }
    const response = yield call(axios.get, 
      // `${API_END_POINT}/getEntityByEmail/`+username+"?v="+randomnumber,
      `${API_END_POINT}/getEntityByToken/`+uuId_str+"?v="+randomnumber,
      {
        headers: {"Authorization" : `Bearer ${localStorage.getItem('logingByPass')?localStorage.getItem('tokenId'):tokenStr}`}
      });
      //GlobalSession.entityId=response.data.EntityUser.entityId;
     
      // localStorage.setItem("USER_ENTITY_ID", response.data.EntityUser.entityId);
      // sessionStorage.setItem("USER_ENTITY_ID", response.data.EntityUser.entityId);
    // dispatch an action with the api response
    // this is consumed by the reducer
    yield put({type: RECEIVE_MY_PROFILE, payload: response.data.EntityUser });
  } catch (e) {
    console.log('getEntityByToken',e);
  }
}

function* getUserInit() {
  yield put(reducer.getUser());
  //console.log(reducer.getUser());
  const { payload } = yield take('AUTH_SET_STATE');
  //alert("testttt userInit"+payload.isSignedIn);
  if (payload.isSignedIn === "AUTH_SUCCESS") {
    yield* fetchMyProfile();
  }
  
}

function* signInAndGetInfo({payload: { username, password, code }}) {
  //alert("testttt");
    yield put(reducer.signIn(username, password, code));
  const { payload } = yield take('AUTH_SET_STATE');
  if (payload.isSignedIn === "AUTH_SUCCESS") {
    yield* fetchMyProfile();
  }
}

// Get logged in user's profile
export function* updateMyProfile({payload: { profile }}) {
  try {
    //alert("inside UpdateMyProfile");
    // Get the authentication token
    const tokenStr = yield* checkAuth();

    // Call the api to get Profile by id
    const response = yield call(axios.patch, 
      `${API_END_POINT}/Profiles/${profile.id}`,
      profile,
      {
        headers: {"Authorization" : `Bearer ${tokenStr}`}
      });

    // dispatch an action with the api response
    // this is consumed by the reducer
    yield put({type: RECEIVE_MY_PROFILE, payload: response.data});
  } catch (e) {
    yield put({type: RECEIVE_MY_PROFILE_ERROR});
    console.log(e);
  }
}

// Get Profile by ID
export function* updateUser({payload: { user }}) {
  try {
    // Get the authentication token
    const tokenStr = yield* checkAuth();

    // Call the api to get Profile by id
    const response = yield call(axios.post,
      `${API_END_POINT}/Profiles/${user.id}/adminUpdate`,
      user,
      {
        params: {
          filter: JSON.stringify({
            include: 'roles',
          }),
        },
        headers: {"Authorization" : `Bearer ${tokenStr}`}
      });

    // dispatch an action with the api response
    // this is consumed by the reducer
    yield put({type: RECEIVE_USER, payload: response.data });
  } catch (e) {
    console.log(e);
  }
}

function* fetchUpdateUserBioSagas(action){
  try {
    //const tokenStr = yield* checkAuth();
    // do api call
    const response = yield call(updateEntityBioApi,{summary:action.payLoad});
    yield put(receiveUpdateUserBioAction(response));
  } catch (e) {
    console.log(e);
  }
}

function* requestUpdateUserInterests(action){
  try {
    //const tokenStr = yield* checkAuth();
    // do api call
    console.log('do api call')
    const response = yield call(updateEntityInterestsTag,action.payload);
    yield put(getEntityInterests(action.payload['entityId']));
  } catch (e) {
    console.log(e);
  }
}

function* requestUpdateUserExperience(action){
  try {
    //const tokenStr = yield* checkAuth();
    // do api call
    console.log('do api call')
    const response = yield call(updateEntityExperienceApi,action.payload);
    console.log(response);
    yield put(getEntityExperience(action.payload['entityId']));
  } catch (e) {
    console.log(e);
  }
}



function* changeMyPassword(action) {
  try {
    const { oldPassword, newPassword } = action.payload
    yield call(changeCognitoPassword, oldPassword, newPassword)
    yield put({
      type: 'AUTH_SET_STATE',
      payload: {
        ...defaultState,
        hasChangedPassword: 'AUTH_SUCCESS'
      }
    })
  } catch (e) {
    yield put({
      type: 'AUTH_SET_STATE',
      payload: {
        ...defaultState,
        error: e,
        isSignedIn: 'AUTH_FAIL'
      }
    })
  }
}

// Action Types
export const INIT_USER = 'INIT_USER';
export const FETCH_USERS = 'FETCH_USERS';
export const RECEIVE_USERS = 'RECEIVE_USERS';
export const FETCH_USER = 'FETCH_USER';
export const RECEIVE_USER = 'RECEIVE_USER';
export const FETCH_MY_PROFILE = 'FETCH_MY_PROFILE';
export const RECEIVE_MY_PROFILE = 'RECEIVE_MY_PROFILE';
export const RECEIVE_MY_PROFILE_ERROR = 'RECEIVE_MY_PROFILE_ERROR';
export const UPDATE_PROFILE = 'UPDATE_PROFILE';
export const SIGN_IN_AND_GET_INFO = 'SIGN_IN_AND_GET_INFO';
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';

// Action Creators
export const initUser = () => {
  return {
    type: INIT_USER,
  };
}
export const getUsers = () => {
  console.log("inside getUsers function");
  return {
    type: FETCH_USERS,
  };
}
export const getUser = (id) => {
  return {
    type: FETCH_USER,
    payload: {
      id
    }
  };
}
export const getMyProfile = () => {
  console.log('step1=>>>>>>>>>>>')
  return {
    type: FETCH_MY_PROFILE
  };
}

export const updateProfile = (profile) => {
  return {
    type: UPDATE_PROFILE,
    payload: {
      profile
    }
  };
}

export const signIn = (username, password, code) => {
  return {
    type: SIGN_IN_AND_GET_INFO,
    payload: {
      username, password, code
    }
  };
}

export const changePassword = (oldPassword, newPassword) => {
  return {
    type: CHANGE_PASSWORD,
    payload: {
      oldPassword, newPassword
    }
  };
}

export default function* top() {
  yield all([
      // takeLatest(INIT_USER, getUserInit),
      takeLatest(INIT_USER, fetchMyProfile),
      takeLatest(FETCH_USERS, fetchUsers),
      takeLatest(FETCH_USER, fetchUser),
      takeLatest(FETCH_MY_PROFILE, fetchMyProfile),
      takeLatest(UPDATE_PROFILE, updateMyProfile),
      takeLatest(SIGN_IN_AND_GET_INFO, signInAndGetInfo),
      takeLatest(CHANGE_PASSWORD, changeMyPassword),
      takeLatest(FETCH_UserBio, fetchUserBioSagas),
      takeLatest(FETCH_UserInterest,fetchUserInterestSagas),
      takeLatest(FETCH_UserExperience,fetchUserExperienceSagas),
      takeLatest(FETCH_UserGrowthPlan,fetchUserGrowthPlanSagas),
      takeLatest(FETCH_UPDATEUSERBIO,fetchUpdateUserBioSagas),
      takeLatest(REQUEST_UserInterestUpdate,requestUpdateUserInterests),
      takeLatest(REQUEST_UserExperienceUpdate,requestUpdateUserExperience)
    ]);
}