import React, { useState } from "react";
import { Modal, ModalBody, ModalHeader, ModalFooter, Button } from "reactstrap";
import axios from "axios";
import moment from "moment";
import GlobalSession, { ShowErrorAlert } from "../Common/GlobalSession";
import { useNavigate } from "react-router-dom";

const NewGoalPlanPopUpMobile = (props) => {
  const [name, setName] = useState("");
  const [error, SetError] = useState(false);
  const dueDate = new Date(moment().add(30, "days"));
  const navigate = useNavigate();

  const reDirrect = (data) => {
    localStorage.setItem("communityGrowthPlanId", data.growthPlanId);
    localStorage.setItem("OwnerEntityId", GlobalSession.entityId);
    localStorage.setItem("childPlanId", data.growthPlanId);
    sessionStorage.setItem("growthPlanId", data.growthPlanId);
    preferences(data.growthPlanId);
    navigate("/mobile/work-plan");
    props.onClose()
  };
  const preferences = async (growthPlanId) => {
    await axios({
      method: "POST",
      url: process.env.REACT_APP_API_END_POINT + "/preferences",
      data: {
        action: "UPDATE",
        entityId: GlobalSession.entityId,
        gpId: growthPlanId,
        access: 1,
        tabnumber: 0,
      },
    })
      .then((res) => {})
      .catch((e) => {
        console.log("error", e);
      });
  };

  const updateCGPflag = async (dataList, planObj) => {
    await axios({
      method: "POST",
      url: process.env.REACT_APP_API_END_POINT + "/updateCGPflag",
      data: dataList,
    })
      .then((Response) => Response)
      .then(async (res) => {
        if (res.data.header.errorMsg === "Success") {
          if (
            res &&
            res.data &&
            res.data.header &&
            res.data.header.errorCode === 0
          ) {
            reDirrect(planObj);
          }
        }
      });
  };

  const onSubmit = async () => {
    if (name.length === 0) {
      SetError(true);
      return false;
    }
    var createobj = {
      action: "UPDATE",
      entityId: GlobalSession.entityId,
      companyId: GlobalSession.companyId,
      growthPlan: {
        growthPlanId: null,
        milestoneDate: moment(dueDate).format("YYYY-MM-DD"),
        name: name,
        comments: "",
        wizzardStage: 1,
        percentSetupComplete: null,
        growthPlanTimeline: null,
        daysSinceUpdated: null,
        sessionTimeBank: 2,
        active: 1,
        goalList: null,
      },
    };
    await axios({
      method: "POST",
      url: process.env.REACT_APP_API_END_POINT + "/newGrowthPlan",
      data: createobj,
    })
      .then((Response) => Response)
      .then((res) => {
        if (
          (res.data && res.data.header && res.data.header.errorCode === 4) ||
          (res.data && res.data.header && res.data.header.errorCode === "4")
        ) {
          ShowErrorAlert(res.data.header.errorCode, res.data.header.errorMsg);
          return false;
        }
        if (res && res.data.header && res.data.header.errorCode === 0) {
          updateCGPflag(
            {
              growthPlanId: res.data.growthPlan.growthPlanId,
              cgpStatus: 2,
              startDate: null,
              entityId: GlobalSession.entityId,
              teamId: res.data.growthPlan.growthPlanId,
            },
            res.data.growthPlan
          );
        }
      });
  };
  return (
    <Modal
      isOpen={props.isOpen}
      size="sm"
      centered={true}
    >
      <ModalHeader
        className={`card-text-bg-6f4b93 text-white`}
        // cssModule={{ "modal-title": "w-100 text-center text-white" }}
        toggle={() => props.onClose()}
      >
        Add a Goal Plan
      </ModalHeader>
      <ModalBody>
        <div>
          <label
            style={{ fontSize: "14px", fontWeight: "500", color: "#868686" }}
          >
            Plan Name:
          </label>
          <textarea
            className="form-control"
            name="name"
            placeholder={
              "Give your plan a good name, (Be brief and and hit create)."
            }
            value={name}
            onChange={(e) => {
              setName(e.target.value);
              SetError(false);
            }}
            autoComplete="off"
          />
          {error && (
            <span className="error" style={{ fontSize: "13px" }}>
              Please enter a goal name
            </span>
          )}
        </div>
      </ModalBody>
      <ModalFooter>
        <Button outline onClick={() => props.onClose()}>
          Cancel
        </Button>
        <Button
          color="primary"
          style={{ padding: "6px 12px" }}
          onClick={() => onSubmit()}
        >
          Create
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default NewGoalPlanPopUpMobile;
