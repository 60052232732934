import {all, fork, take, takeLatest, call, put, select} from "redux-saga/effects";
import { getThoughtPadApi,createThoughtPadApi,getEntityPersonalApi } from "../api/sideBarApi";
import {FETCH_ThoughtPad,RECEIVE_ThoughtPad,receiveThoughtPadAction,FETCH_ENTITY_PERSONAL,receiveEntityPersonalAction} from "../actions/sideBarAction"
import {CREATE_ThoughtPad,RECEIVE_CreateThoughtPad,receiveCreateThoughtPadAction} from "../actions/sideBarAction"

function* fetchThoughtPadSagas(action) {
    
    try {
      //const tokenStr = yield* checkAuth();
      // do api call
      const response = yield call(getThoughtPadApi,{entityId:action.payload});
      yield put(receiveThoughtPadAction(response));
    } catch (e) {
      console.log(e);
    }
  }

  function* createThoughtPadSagas(action) {
    
    try {
      //const tokenStr = yield* checkAuth();
      // do api call
      const response = yield call(createThoughtPadApi,action.payload);
      yield put(receiveCreateThoughtPadAction(response));
    } catch (e) {
      console.log(e);
    }
  }

  function* getEntityPersonalSagas(action) {
    try {
      //const tokenStr = yield* checkAuth();
      // do api call
      const response = yield call(getEntityPersonalApi,action.payload);
      yield put(receiveEntityPersonalAction(response));
    } catch (e) {
      console.log(e);
    }
  }

  export default function* sideBarSagas() {
    yield all([
      takeLatest(FETCH_ThoughtPad, fetchThoughtPadSagas),
      takeLatest(CREATE_ThoughtPad, createThoughtPadSagas),
      takeLatest(FETCH_ENTITY_PERSONAL, getEntityPersonalSagas),
        
    ]);
  }