import { EMAILDATA } from '../actions/emailAction';
import { action as actions } from 'aws-cognito-redux-saga';
import _ from 'lodash';
const initialState = {
    profileobj: {}
};
export default function (state = initialState, action) {


    switch (action.type) {

        case EMAILDATA:
            return {
                ...state,
                emailaddress: action.payload
            };
        default:
            return state;
    }
}