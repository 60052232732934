import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';

export function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    let [navigateTo, setNavigateTo] = useState("")
    let location = useLocation();
    let navigate = useNavigate();
    let params = useParams();

    useEffect(() => {
        if(navigateTo){
            navigate(navigateTo)
        }
    },[navigateTo])
    return (
      <Component
        {...props}
        router={{ location, setNavigateTo, params }}
      />
    );
  }

  return ComponentWithRouterProp;
}