import _ from 'lodash';
import { getToken } from "./selectors";
import { reducer } from 'aws-cognito-redux-saga';
import {take, put, select} from "redux-saga/effects";

export function* checkAuth() {
    yield put(reducer.getUser());
    const { payload } = yield take('AUTH_SET_STATE');
    const tokenStr = _.get(payload, 'info.accessToken.jwtToken');
    // Still couldn't find a token. Maybe the user isn't logged in
    if (!tokenStr) throw new Error(`Auth isSignedIn?: ${payload.isSignedIn}`);
    return tokenStr;
  }
  export function* getAuthUUID(){
    // yield put(reducer.getUser());
    // const { payload } = yield take('AUTH_SET_STATE');
    // const uuid_str = _.get(payload, 'info.accessToken.payload.username',_.get(JSON.parse(localStorage.getItem('user_info')), 'tokenId', ''));
    const uuid_str=_.get(JSON.parse(localStorage.getItem('user_info')), 'tokenId', '')
    // const companyId=_.get(payload, 'info.idToken.payload.custom:companyId');
    // console.log("inside uuidstr");
    
    // console.log(payload);
    //alert(payload.info.idToken.payload["given_name"]);
    //alert(payload.info.idToken.payload["custom:companyId"]);
    return uuid_str;
  }

  export const UpdateLoginDetails = (data) => {
    return {
      type: "UPDATE_USER",
      payload:data
    };
  }
  export const UpdateLoginerrors = (data) => {
    return {
      type: "UPDATE_ERROR",
      payload:data
    };
  }
  export const SignOutUser = () => {
    return {
      type: "SING_OUT_USER"
    };
  }
  export const forgetPasswordRequest = () => {
    return {
      type: "FORGETPASSWORREQUEST"
    };
  }