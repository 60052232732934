import React from 'react';
import { SignOutUser } from '../../actions/auth';
import { toast } from 'react-toastify';
var alertCount = 0;

//const entityId = sessionStorage.getItem("USER_ENTITY_ID");
var entityId=localStorage.getItem("USER_ENTITY_ID");
var companyId=localStorage.getItem("USER_COMPANY_ID");
var companyName=localStorage.getItem("USER_COMPANY_NAME");
var GlobalSession = {
    "entityId":entityId,
    "companyId":companyId,
    "companyName":companyName
}
export const ShowErrorAlert=(errorCode,errorMsg)=>{
    if(alertCount == 0){
    // alert(errorMsg);
    toast.error(errorMsg)
    if(errorCode==4){
        localStorage.clear();
        localStorage.removeItem('appVersion');
        sessionStorage.clear();
        SignOutUser()
        GlobalSession.entityId = null;
        window.location="/#/login";
     }
     alertCount += 1;
    }else {
        alertCount = 0
    } 

}

export default GlobalSession;