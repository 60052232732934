import axios from "axios";
import { ShowErrorAlert } from "../views/Common/GlobalSession";
import {toast} from 'react-toastify'


export const getGrowthPlanDetailsApi = async (obj) => {
  //console.log("inside getGrowthPlanDetailsApi api call");
  //console.log(obj.entityId);
  if(localStorage.getItem('logingByPass')){
    obj.statusId=-1
  }
  try {
    // Get the authentication token

    // headers: {"Authorization" : `Bearer ${tokenStr}`}
    const response = await axios({
      method: 'POST',
      url: process.env.REACT_APP_API_END_POINT + '/growth-plan-details',
      data: {
        "action": obj.growthPlanAction,
        "entityId": obj.entityId,
        "growthPlanId": obj.growthPlanId,
        "statusId": obj.statusId,
        childPlanId:obj.childPlanId||null,
        companyId: obj.companyId
      },
    })
      .then(Response => Response)
      .then(res => {
        if (res.data.header.errorCode > 0) { 
          ShowErrorAlert(res.data.header.errorCode, res.data.header.errorMsg);
        }
        //console.log("growthplandetailsresponse",res.data);
        return res.data;
      })
    return response;
  } catch (e) {
    // alert("Error in getting growth plan details API.this is due to network failure, please login again");
    toast.error("Error in getting growth plan details API.this is due to network failure, please login again")
    console.log(e);
  }
};


export const deleteUpdateQuestionsApi = async (obj, onSuccess) => {
  //console.log("inside updateGoalQuestion api call",obj);
  try {
    // Get the authentication token

    // headers: {"Authorization" : `Bearer ${tokenStr}`}
    const response = await axios({
      method: 'POST',
      url: process.env.REACT_APP_API_END_POINT + '/updateGoalQuestion',
      data: obj,
    })
      .then(Response => Response)
      .then(res => {
        //console.log("growthplandetailsresponse",res.data);
        return res.data;
      })
    return typeof onSuccess === "function" ? onSuccess(response) : response;
  } catch (e) {
    //alert("error in get growthplandetails api");
    console.log(e);
  }
};
