
import { RECEIVE_USERS, RECEIVE_USER } from '../sagas/users';
import { action as actions } from 'aws-cognito-redux-saga';
import { RECEIVE_UserBio, RECEIVE_UserInterest, RECEIVE_UserExperience, RECEIVE_UserGrowthPlan, UPDATE_ALL_USERLIST } from '../actions/userProfileActions';

import _ from 'lodash';
const initialState = {
  userbiodata: {},
  AllUserList:[]
};

// https://redux.js.org/troubleshooting#never-mutate-reducer-arguments
// Make sure not to modify state
export default function users(state = initialState, action) {

  switch (action.type) {
    case actions.AUTH_SIGN_OUT:
      return initialState;
    case RECEIVE_USERS:
      return _.keyBy(action.payload, 'id');
    case RECEIVE_USER:
      return Object.assign({},
        state, { [action.payload.id]: action.payload });
    case RECEIVE_UserBio:
      return {
        ...state,
        userbiodata: action.payload
      };
    case RECEIVE_UserInterest:
      return {
        ...state,
        userinterestdata: action.payload
      };
    case RECEIVE_UserExperience:
      return {
        ...state,
        userexperiencedata: action.payload
      }
    case RECEIVE_UserGrowthPlan:
      return {
        ...state,
        usergrowthplan: action.payload
      }
    case UPDATE_ALL_USERLIST:
      return {
        ...state,
        AllUserList: action.payLoad
      }
    default:
      return state;
  }
};