import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import "./index.css";
import App from "./App";
// import * as serviceWorkerRegistration from "./registerServiceWorker";
// import reportWebVitals from "./reportWebVitals";

import store from "./config/store";
import HttpsRedirect from "react-https-redirect";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import "../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
const theme = createTheme({
  palette: {
    accent1Color: "#fff8e1",
  },
  components: {
    MuiTabs: {
      styleOverrides: {
        root: {
          backgroundColor: "#1565c0",
        },
      },
    },
  },
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <ThemeProvider theme={theme}>
    <Provider store={store}>
      <HttpsRedirect>
        <App />
      </HttpsRedirect>
    </Provider>
  </ThemeProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// serviceWorkerRegistration.register();
// reportWebVitals();
