import {FETCH_UpdateGrowthPlan_lst,RECEIVE_UpdateGrowthPlan_lst} from '../actions/updateGrowthPlanActions'
import { action as actions } from 'aws-cognito-redux-saga';
import _ from 'lodash';

const initialState={
    updateGrowthPlanSummaryObj:{}
};
 const updateGrowthPlanReducers=(state = initialState, action)=>{
    //console.log("updateGrowthPlan action.type======="+action.type);
    //console.log("updateGrowthPlan action.payload======="+action.payload);
    switch(action.type){
        case RECEIVE_UpdateGrowthPlan_lst:
            //console.log("inside Receive updateGrowthPlan reducers");
            return{
                ...state,
                updateGrowthPlanSummaryObj:action.payload
            };
        default:{
            return state;
        }
    }
}

export default updateGrowthPlanReducers;


