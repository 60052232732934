import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { state } from 'aws-cognito-redux-saga';
import { Alert, Button, Card, CardBody, CardGroup, Col, Container, Form, Input, InputGroup, InputGroupAddon, InputGroupText, Row } from 'reactstrap';
import PropTypes from 'prop-types';
import _ from 'lodash';
import passwordValidation from '../../../utils/passwordValidation';
import { connect } from 'react-redux';


import LoginLogo from '../../../assets/img/brand/login-logo.png';

function validate(email, code, newPassword, newPasswordConfirm) {
  const newPasswordErrors = passwordValidation(newPassword);
  return {
   // email: email.length === 0 ? 'Must provide email address' : undefined,
    code: code.length === 0 ? 'Must provide verification code' : undefined,
    newPassword: !_.isEmpty(newPasswordErrors) ? newPasswordErrors : undefined,
    newPasswordConfirm: newPasswordConfirm.length === 0 || newPasswordConfirm !== newPassword ? 'Passwords must match' : undefined,
  };
}

class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      'email': '',
      'code': '',
      'newPassword': '',
      'newPasswordConfirm': '',
      codepage:true,
      passwordpage:false,
      touched: {
        email: false,
        code: false,
        newPassword: false,
        newPasswordConfirm: false,
      }
    };
    this.changePassword = this.changePassword.bind(this)
  }

  static propTypes = {
    changePassword: PropTypes.func.isRequired,
    auth: PropTypes.object,
    init: PropTypes.func
  }

  componentWillMount() {
    this.props.init();

    let url = this.props.location.search;
    
    let params = new URLSearchParams(this.props.location.search);
    
    var email="";
    var code=0;
    if(params!=undefined && params!=null && params.get("email")!=undefined && params.get("email")!=null){
      email=params.email;
    }
    if(params!=undefined && params!=null && params.get("code")!=undefined && params.get("code")!=null){
      code=parseInt(params.code);
    }
    this.setState({
      email:email,
      code:code
    })
  }

  handleChange = async (event) => {
    const { target } = event;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const { name } = target;
    await this.setState({
      [name]: value,
    });
  }

  handleBlur = (field) => (evt) => {
    this.setState({
      touched: { ...this.state.touched, [field]: true },
    });
  }
  submitButton = () => {
    this.setState({codepage:false,passwordpage:true});
  }
  cancelButton = () => {
    this.props.history.push("/login");
  }
  onKeyDown = (event) => {
    // 'keypress' event misbehaves on mobile so we track 'Enter' key via 'keydown' event
    if (event.key === 'Enter') {
      event.preventDefault();
      event.stopPropagation();
      this.submitButton();
    }
  }

  // https://goshakkk.name/instant-form-fields-validation-react/
  canBeSubmitted() {
    const errors = validate(this.props.email, this.state.code, this.state.newPassword, this.state.newPassword);
    const isDisabled = Object.keys(errors).some(x => errors[x]);
    return !isDisabled;
  }

  changePassword = values => {
    if (!this.canBeSubmitted()) {
      return;
    }

    this.props.changePassword(
      this.props.email,
      this.state.code,
      this.state.newPassword,
    )
  }
  render() {
    const { auth } = this.props
    return auth.hasChangedPassword === state.AUTH_SUCCESS
      ? this.renderSignIn()
      : this.renderChangePassword()
  }

  renderSignIn() {
    const { handleSubmit } = this.props
    return (
      <div className="app flex-row align-items-center">
        <Container>
          <Row className="justify-content-center">
            <Col md="8">
              <CardGroup>
                <Card className="p-4">
                  <CardBody>
                    <h1>Password Changed</h1>
                    <p className="text-muted">Your password has been successfully changed.</p>
                    <Row>
                      <Col xs="6">
                        <Button
                          color="primary"
                          className="px-4"
                          tag={Link} to="/login">
                          Login
                      </Button>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </CardGroup>
            </Col>
          </Row>
        </Container>
      </div>
    )
  }

  renderChangePassword() {
   
    
    const { auth } = this.props;
    const errors = validate(this.props.email, this.state.code, this.state.newPassword, this.state.newPasswordConfirm);
    const isDisabled = Object.keys(errors).some(x => errors[x]);
    const shouldMarkError = (field) => {
      const hasError = errors[field];
      const shouldShow = this.state.touched[field];

      return hasError ? shouldShow : false;
    };
    return (
      <div className="d-flex flex-column align-items-center justify-content-center login-section">
        <div class="login-container login-form-fields">
          <Form>
            <div className="login-logo">
              <img src={LoginLogo} className="img-fluid" />
            </div>
            <p className="text-white text-center my-3 reset-text">
                {this.props.email}
            </p>

           
            
            {/* <InputGroup className="mb-2">
              <Input name="email" type="text" placeholder="Email Address" autoComplete="email"
                className={shouldMarkError('email') ? 'is-invalid' : ''}
                onBlur={this.handleBlur('email')}
                onChange={(e) => {
                  this.handleChange(e)
                }} />
            </InputGroup>
            {shouldMarkError('email') && errors.email && (<Alert color="warning">{errors.email}</Alert>)} */}
            {this.state.codepage ? 
            <div>
              <Alert color="success">An email has been sent to {this.props.email} with a verification code</Alert>
            <InputGroup className="mb-2">
              <Input name="code" type="text" placeholder="Verification Code"
                className={shouldMarkError('code') ? 'is-invalid' : ''}
                onBlur={this.handleBlur('code')}
                onChange={(e) => {
                  this.handleChange(e)
                }}
                onKeyDown={this.onKeyDown}
                />
            </InputGroup>
             {/* {shouldMarkError('code') && errors.code && (<Alert color="warning">{errors.code}</Alert>)}  */}
             <Button color="primary" className="btn-block" /* disabled={isDisabled} */
             onClick={(e) => { this.submitButton() }}>
            Submit
           </Button>
           <Button color="primary" className="btn-block" /* disabled={isDisabled} */
             onClick={(e) => { this.cancelButton() }}>
            Cancel
           </Button>
           </div>
          
            : null }

          
         
            {this.state.passwordpage ? 
            <div>
            <InputGroup className="mb-2">
              <Input
                className={shouldMarkError('newPassword') ? 'is-invalid' : ''}
                name="newPassword" type="password" placeholder="New Password"
                onBlur={this.handleBlur('newPassword')}
                onChange={(e) => {
                  this.handleChange(e)
                }} />
            </InputGroup>
            {shouldMarkError('newPassword') && errors.newPassword && (
              <Alert color="warning">
                {errors.newPassword.map(function (errorValue) {
                  return <p>{errorValue}</p>;
                })}
              </Alert>)}

            <InputGroup className="mb-2">
              <Input
                className={shouldMarkError('newPasswordConfirm') ? 'is-invalid' : ''}
                name="newPasswordConfirm" type="password" placeholder="Confirm New Password"
                onBlur={this.handleBlur('newPasswordConfirm')}
                onChange={(e) => {
                  this.handleChange(e)
                }} />
            </InputGroup>
            {shouldMarkError('newPasswordConfirm') && errors.newPasswordConfirm && (
              <Alert color="warning">{errors.newPasswordConfirm}</Alert>
            )}

            {auth.error && auth.error.message && (<Alert color="warning">{auth.error.message}</Alert>)}

            <Button color="primary" className="btn-block" disabled={isDisabled}
              onClick={(e) => { this.changePassword(e) }}>
              Change Password and Sign In
            </Button>
            </div>
            : null }

          </Form>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return { email : state.email.emailaddress,
         
  };
};

export default connect(mapStateToProps , null)(ForgotPassword);
