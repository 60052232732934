import axios from "axios";

import _ from 'lodash';

export const FETCH_MEETING_LIST= 'FETCH_MEETING_LIST';
export const RECEIVE_MEETING_LIST = 'RECEIVE_MEETING_LIST';


export const getMeetingListAction= (obj) => {
  //console.log("inside getCategoryAction");
  return {
    type: FETCH_MEETING_LIST,
    payLoad:obj
  };
}

export const receiveMeetingListAction = meetingListResponse => {
  //console.log("inside receiveCategoryAction");
  return {
    type: RECEIVE_MEETING_LIST,
    payLoad:meetingListResponse
  };
}

export const REQUEST_UPDATE_FEEDBACK_ACTION= 'REQUEST_UPDATE_FEEDBACK_ACTION';
export const RESPONSE_UPDATE_FEEDBACK_ACTION= 'RESPONSE_UPDATE_FEEDBACK_ACTION';
export const requestUpdateFeedbackAction = (obj) => {
  return {
    type: REQUEST_UPDATE_FEEDBACK_ACTION,
    payLoad:obj
  };
}
export const responseUpdateFeedbackAction = data => {
  return {
    type: RESPONSE_UPDATE_FEEDBACK_ACTION,
    payLoad:data
  };
}

export const REQUEST_GET_FEEDBACK_ACTION= 'REQUEST_GET_FEEDBACK_ACTION';
export const RESPONSE_GET_FEEDBACK_ACTION= 'RESPONSE_GET_FEEDBACK_ACTION';
export const requestGetFeedbackAction = (obj) => {
  return {
    type: REQUEST_GET_FEEDBACK_ACTION,
    payLoad:obj
  };
}
export const responseGetFeedbackAction = data => {
  return {
    type: RESPONSE_GET_FEEDBACK_ACTION,
    payLoad:data
  };
}