import axios from "axios";

import _ from 'lodash';
import { checkAuth } from './auth';
import { reducer, state } from 'aws-cognito-redux-saga';

export const FETCH_EntityUsers = 'FETCH_EntityUsers';
export const RECEIVE_EntityUsers = 'RECEIVE_EntityUsers';
export const SETFIREWORKS = 'SETFIREWORKS';

export const getProfileDetails = (entityId) => {
  return {
    type: FETCH_EntityUsers,
    payload:entityId
  };
}

export const receiveProfileDetails = data => {
  return {
    type: RECEIVE_EntityUsers,
    payload:data
  };
}

export const setFireWorks = data => {
  return {
    type: SETFIREWORKS,
    payload:data
  };
}
