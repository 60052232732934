import axios from "axios";
import _ from 'lodash';
import { reducer, state } from 'aws-cognito-redux-saga';



export const FETCH_UpdateGrowthPlan_lst = "FETCH_UpdateGrowthPlan_lst";
export const RECEIVE_UpdateGrowthPlan_lst = "RECEIVE_UpdateGrowthPlan_lst";

export const getUpdateGrowthPlanSummary = (inputParams) => {
  //console.log("inside the getUpdateGrowthPlansummary method");
  return {
      type : FETCH_UpdateGrowthPlan_lst,
      payload:inputParams
  }
} 

export const receiveUpdateGrowthPlanSummaary = data =>{
    //console.log ("inside the receiveUpdataGrowthPlanSummary method");
    return {
        type:RECEIVE_UpdateGrowthPlan_lst,
        payload:data
    }
}