import React, { Component } from 'react';
import { connect } from "react-redux";
import PropTypes from 'prop-types'
import { getMyProfile, updateProfile } from "../../sagas/users";

import Profile from './ProfileComponent'
import Profiles from './ProfilesComponent';

class ProfileContainer extends Component {
    static propTypes = {
        init: PropTypes.func,
    };

    // state = {
    //     profile: null,
    // };

    componentWillMount() {
        this.props.init();
    }
    
    toggleSuccess=()=>{
        this.props.toggleSuccess();
    }
    // componentWillReceiveProps(nextProps) {
    //   // wait until we have all the data that we need
    //   if (this.props !== nextProps && 
    //     nextProps.profile && nextProps.updateProfile) {
    //       this.setState({ 
    //         profile: nextProps.profile,
    //       });
    //   }
    // }

    render() {
        // if (!this.state.profile) return null;
        //profile={this.state.profile}
//         return <Profile {...this.props}/>;
        return <Profiles {...this.props}/>;
    }
}

const mapStateToProps = (store, ownProps) => {
    return {
        profile: store.profile.data,
        loading: store.profile.loading,
        error: store.profile.error
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        init: () => {
            dispatch(getMyProfile());
        },
        updateProfile: (profile) => {
            dispatch(updateProfile(profile));
        },
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(ProfileContainer);