import { GETROUTES, REMOVEROUTES, REMOVEROUTESBYINDEX, SAVEROUTES,UPDATEBREADCRUMbNAME } from '../actions/GoalbreadcrumbsAction';
import _ from 'lodash';
const routes = localStorage.getItem('routes')
const initialState = {
    routes: routes ? JSON.parse(routes) : []
};
export default function (state = initialState, action) {


    switch (action.type) {

        case GETROUTES:
            return {
                ...state,
                routes: action.payload
            };
            break;
        case SAVEROUTES:
            let { payload } = action;
            let tempArray = state.routes, index = tempArray.findIndex(route => route.teamId === payload.teamId)
            if (index === -1) {
                tempArray.push(payload)
            } else {
                tempArray.splice(index, 1)
            }
            localStorage.setItem('routes', JSON.stringify(tempArray))
            return {
                ...state,
                routes: tempArray
            };
        case REMOVEROUTES:
            localStorage.removeItem('routes')
            return {
                ...state,
                routes: []
            };
        case REMOVEROUTESBYINDEX:
             if(action&&action.payload&&action.payload.index){
                state.routes.splice(action.payload.index, 50)
             }
            localStorage.setItem('routes', JSON.stringify(state.routes))
            return {
                ...state,
                routes: state.routes
            };
            case UPDATEBREADCRUMbNAME:
                let tempArray1 = [...state.routes], index1 = tempArray1.findIndex(route => route.teamId === action.payload.teamId);
                if (index1 !== -1) {
                    tempArray1.splice(index1, 1,action.payload);
                }
                localStorage.setItem('routes', JSON.stringify(tempArray1))
                return {
                    ...state,
                    routes: tempArray1
                };
        default:
            return state;
    }
}