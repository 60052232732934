import { RECEIVE_GrowthPlanDetails_Lst, RECEIVE_TeamGrowthPlanDetails_Lst, SEND_GrowthPlanDetailsaction_Lst, FETCH_GoalDetails, UPDATE_FILTEROPTION } from '../actions/growthPlanDetailsAction'
// import { action as actions } from 'aws-cognito-redux-saga';
import _ from 'lodash';

const initialState = {
  growthDetailsObj: {},
  newGoalDetails: {},
  filterOptions: sessionStorage.getItem('filterOption') !== null ? JSON.parse(sessionStorage.getItem('filterOption')) : {}
}

const growthPlanDetailsReducer = (state = initialState, action) => {
  //console.log("growthPlan details action.type======="+action.type);
  switch (action.type) {

    case RECEIVE_GrowthPlanDetails_Lst:
      return {
        ...state,
        growthDetailsObj: action.payload
      };
    case FETCH_GoalDetails:
      // console.log("FETCH_GoalDetails reducer",action.payload);
      return {
        ...state,
        newGoalDetails: action.payload
      };
    case RECEIVE_TeamGrowthPlanDetails_Lst:
      return {
        ...state,
        teamGrowthDetailsObj: action.payload
      };
    case UPDATE_FILTEROPTION:
      return {
        ...state,
        filterOptions: action.payload
      };
    default:
      return state;
  }
}

export default growthPlanDetailsReducer;
