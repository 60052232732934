import users from "./users";
import profile from "./profile";

import {combineReducers} from "redux";
import { reducer as authReducer } from 'aws-cognito-redux-saga';
import { reducer as formReducer } from 'redux-form'
import dashboardReducer from "./dashboardReducer";
import growthPlanReducer from "./growthPlanReducer";
import sideBarReducer from "./sideBarReducer";
import growthPlanDetailreducer from './growthPlanDetailReducer'
import updateGrowthPlanReducers from './updateGrowthPlanReducers'
import getPickListReducer from './getPickListReducer'
import getMeetingListReducer from './getMeetingListReducers'
import emailReducer from "./emailReducer";
import GoalbreadcrumbReducers from "./GoalbreadcrumbReducers";
import auth from "./auth";


 
const appReducer = combineReducers({
  // auth: authReducer.reducer,
  auth:auth,
  form: formReducer,
  profile,
  users,
  email:emailReducer,
  dashboard:dashboardReducer,
  growthPlanDetails:growthPlanDetailreducer,
  growthPlan:growthPlanReducer,
  sidebar:sideBarReducer,
  updateGrowthPlan:updateGrowthPlanReducers,
  getPickList:getPickListReducer,
  getMeetingList:getMeetingListReducer,
  breadCrumbs:GoalbreadcrumbReducers
});

export default appReducer;